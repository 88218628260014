import { MapPinIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid';

export function OfficeCard({
  office,
  handleEditOffice,
  handleDeleteOfficeModal,
}: any) {
  return (
    <div className="flex flex-col rounded-xl border px-4 py-3 shadow-sm">
      <div className="flex flex-col pb-3">
        <p className="font-medium">{office.name}</p>
        <div className="flex flex-row items-center justify-between ">
          <div className="flex flex-row gap-2">
            <MapPinIcon className="w-5" />
            Location:
          </div>
          <div className="text-end">
            <p>
              {office.address.line1}, {office.address.town}
            </p>
            <p>{office.address.country}</p>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between ">
          <div className="flex flex-row gap-2">
            <PhoneIcon className="w-5" />
            Phone:
          </div>
          <p>{office.phone_number}</p>
        </div>
      </div>
      <div className="flex flex-row justify-end gap-3 border-t pt-3">
        <button
          onClick={() => handleDeleteOfficeModal(office)}
          className="flex flex-row items-center gap-2 rounded-lg border px-3 py-1 text-[#DC2626] shadow"
        >
          <TrashIcon color="red" className="w-4" />
          Delete
        </button>
        <button
          onClick={() => handleEditOffice(office)}
          className="flex flex-row items-center gap-2 rounded-lg border px-3 py-1 shadow"
        >
          <PencilSquareIcon className="w-4" />
          Edit
        </button>
      </div>
    </div>
  );
}
