import { FooterSection } from '../../FooterSection';
import { Stepper } from '../../Stepper';
import { WelcomeSection } from '../../WelcomeSection';
import { Steps } from '../../../../../ui/layouts/OnboardingLayout';
import { AbsenceMgmt } from './forms/AbsenceMgmt';
import { TimesheetSelected } from './forms/TimesheetSelected';
import { RotaMgmt } from './forms/RotaMgmt';
import { PayrollIntegration } from './forms/PayrollIntegration';
import { ComplianceMgmt } from './forms/ComplianceMgmt';

type Props = {
  setCurrentStep: (data: Steps) => void;
  currentStep: Steps;
  handleNext: () => void;
  handlePrevious: () => void;
  features: string;
  handleSkipForNow: () => void;
};

export function FifthStep({
  setCurrentStep,
  currentStep,
  handleNext,
  handlePrevious,
  features,
  handleSkipForNow,
}: Props) {
  const renderOption = () => {
    switch (features) {
      // case 'approval-workflow': {
      //   return <div>1</div>;
      // }
      // case 'absence-management': {
      //   return (
      //     <AbsenceMgmt
      //       handleNext={handleNext}
      //       handleSkipForNow={handleSkipForNow}
      //     />
      //   );
      // }
      // case 'rota-management': {
      //   return (
      //     <RotaMgmt
      //       handleNext={handleNext}
      //       handleSkipForNow={handleSkipForNow}
      //     />
      //   );
      // }
      // case 'timesheets': {
      //   return (
      //     <TimesheetSelected
      //       handleNext={handleNext}
      //       handleSkipForNow={handleSkipForNow}
      //     />
      //   );
      // }
      // case 'reporting': {
      //   return <div>6</div>;
      // }
      // case 'payroll-integration': {
      //   return (
      //     <PayrollIntegration
      //       handleNext={handleNext}
      //       handleSkipForNow={handleSkipForNow}
      //     />
      //   );
      // }
      // case 'compliance-management': {
      //   return (
      //     <ComplianceMgmt
      //       handleNext={handleNext}
      //       handleSkipForNow={handleSkipForNow}
      //     />
      //   );
      // }
      default:
        return (
          <AbsenceMgmt
            handleNext={handleNext}
            handleSkipForNow={handleSkipForNow}
          />
        );
    }
  };

  return (
    <>
      <div className="bg-center bg-no-repeat md:w-[40%] md:bg-form-image5" />
      <div className="m-2 flex w-screen flex-col  rounded-lg bg-white px-10 pt-8 md:w-[60%]">
        <WelcomeSection handlePrev={handlePrevious} />
        <Stepper currentStep={currentStep} />
        <div>{renderOption()}</div>
      </div>
    </>
  );
}
