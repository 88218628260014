import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Input } from '../../../ui/form/Input';
import { Select } from '../../../ui/form/Select';
import { Textarea } from '../../../ui/form/Textarea';
import { ChevronRightIcon, UserIcon } from '@heroicons/react/24/outline';
import { Menu, Popover, Position } from 'evergreen-ui';
import { ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { HandlePermisions } from './HandlePermisions';
import { AssignPeople } from './AssignPeople';
import { useGetOffices } from '../hooks';
import { usePeople } from '../../People/hooks';
import departmentsManagement from '../../../network/departmentsManagement';
import { useToast } from '../../../utils/useToast';

export default function AddDepartmentModal({
  open,
  setOpen,
  refetchDepartments,
}: any) {
  const [showPermisions, setShowPermisions] = useState(false);
  const [showAssignRole, setShowAssignRole] = useState(false);

  const { data: officeOptions = [] } = useGetOffices();
  const { data: peopleOptions = [] } = usePeople();
  const { toast } = useToast();

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      manager: '',
      location: '',
      permissions: [''],
      people: [],
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup.string().required(),
      description: yup.string().required(),
      manager: yup.string().required(),
      location: yup.string().required(),
    }),
    onSubmit: async (data) => {
      try {
        await departmentsManagement.creteDepartment(data);
        refetchDepartments();
        toast({
          variant: 'success',
          title: 'Department successfully created.',
        });
      } catch (error) {
        toast({
          variant: 'error',
          title: 'Something went wrong. Please try again later.',
        });
      }
      setOpen(false);
      formik.resetForm();
    },
  });

  function findError(
    fieldName: keyof typeof formik.initialValues,
  ): string | undefined {
    const error =
      formik.touched[fieldName] && formik.errors[fieldName]
        ? formik.errors[fieldName]
        : undefined;

    if (Array.isArray(error)) {
      return error.join(', ');
    }

    return error as string | undefined;
  }

  const handlSubmit = () => {
    formik.handleSubmit();
  };

  const handlePermissionChange = (id: string, enabled: boolean) => {
    // formik.setFieldValue(
    //   'permissions',
    //   formik.values.permissions.map((perm) =>
    //     perm.id === id ? { ...perm, enabled } : perm,
    //   ),
    // );
  };

  const handleSelectPeople = (id: string, checked: boolean) => {
    const updatedPeople = checked
      ? [...formik.values.people, id]
      : formik.values.people.filter((personId) => personId !== id);
    formik.setFieldValue('people', updatedPeople);
  };

  const displayOption = peopleOptions?.data?.filter(
    (person: any) => person.id === formik.values.manager,
  );

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center ">
          <DialogPanel
            transition
            className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in "
          >
            {!showPermisions && !showAssignRole ? (
              <>
                <div className="px-6 pt-4 ">
                  <div className="flex flex-col">
                    <DialogTitle
                      as="h6"
                      className="text-base font-semibold text-gray-900"
                    >
                      Add department
                    </DialogTitle>
                    <p className="text-gray-500">
                      Create a new department to organize your team and
                      streamline management. Define roles and assign a
                      department head to lead the way.
                    </p>
                  </div>
                </div>
                <form
                  onSubmit={formik.handleSubmit}
                  className="flex flex-col gap-2 px-6 pb-4 pt-3"
                >
                  <div>
                    <label
                      htmlFor="name"
                      className="mb-1 block text-base font-medium"
                    >
                      Name
                    </label>
                    <Input
                      type="text"
                      placeholder="Enter department name"
                      id="name"
                      name="name"
                      error={findError('name')}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="description"
                      className="mb-1 block text-base font-medium"
                    >
                      Description
                    </label>
                    <Textarea
                      placeholder="Enter department description"
                      id="description"
                      name="description"
                      error={findError('description')}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="manager"
                      className="mb-1 block text-base font-medium"
                    >
                      Department Manager
                    </label>
                    <Popover
                      minWidth="240px"
                      position={Position.BOTTOM}
                      content={
                        <Menu>
                          <Menu.Group>
                            <div className="flex w-full flex-col">
                              {peopleOptions?.data?.map(
                                (item: {
                                  id: string;
                                  full_name: string;
                                  safe_profile_picture_url: string;
                                }) => (
                                  <Menu.Item
                                    onClick={() =>
                                      formik.setFieldValue('manager', item.id)
                                    }
                                    key={item.id}
                                  >
                                    <div className="flex flex-row items-center gap-3">
                                      <img
                                        className="h-6 w-6 rounded-full "
                                        src={
                                          item?.safe_profile_picture_url as string
                                        }
                                        alt="User icon"
                                      />
                                      {item.full_name}
                                    </div>
                                  </Menu.Item>
                                ),
                              )}
                            </div>
                          </Menu.Group>
                        </Menu>
                      }
                    >
                      <div className="flex w-full justify-between rounded-lg  border-[1px] border-gray-300 px-2 px-3 py-2 shadow-sm">
                        {formik.values.manager ? (
                          <div className="flex flex-row items-center gap-3">
                            <img
                              className="h-6 w-6 rounded-full "
                              src={
                                displayOption[0]
                                  ?.safe_profile_picture_url as string
                              }
                              alt="User icon"
                            />
                            {displayOption[0].full_name}
                          </div>
                        ) : (
                          <div className="flex flex-row items-center gap-2">
                            {' '}
                            <UserIcon className="w-4" /> Select
                          </div>
                        )}
                        <ChevronUpDownIcon className="w-5" color="gray" />
                      </div>
                    </Popover>
                  </div>
                  <div>
                    <label
                      htmlFor="location"
                      className="mb-1 block text-base font-medium"
                    >
                      Location
                    </label>
                    <Select
                      id="location"
                      name="location"
                      value={formik.values.location}
                      onChange={formik.handleChange}
                      error={findError('location')}
                    >
                      <option>Select</option>
                      {officeOptions?.map(
                        (item: {
                          id: string;
                          address: {
                            line1: string;
                            town: string;
                            country: string;
                          };
                        }) => (
                          <option key={item.id} value={item.id}>
                            {item.address.line1} {item.address.town}{' '}
                            {item.address.country}
                          </option>
                        ),
                      )}
                    </Select>
                  </div>
                </form>
                <div className="mb-5 flex flex-col gap-3 px-6">
                  <button
                    onClick={() => setShowPermisions(true)}
                    className="flex w-full flex-row items-center justify-between rounded-lg border
                            px-3 py-1"
                  >
                    Permisions
                    <ChevronRightIcon className="w-4" />
                  </button>
                  <button
                    onClick={() => setShowAssignRole(true)}
                    className="flex w-full flex-row items-center justify-between rounded-lg border
                            px-3 py-1"
                  >
                    Assign people
                    <ChevronRightIcon className="w-4" />
                  </button>
                </div>
                <div className="flex flex-row justify-end gap-3 px-6 pb-5">
                  <button
                    type="button"
                    data-autofocus
                    onClick={() => {
                      formik.resetForm();
                      setOpen(false);
                    }}
                    className="inline-flex justify-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={handlSubmit}
                    className="inline-flex justify-center rounded-md bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-sm  hover:bg-red-500"
                  >
                    Add department
                  </button>
                </div>{' '}
              </>
            ) : !showAssignRole ? (
              <HandlePermisions
                setShowPermisions={setShowPermisions}
                handlePermissionChange={handlePermissionChange}
                permissions={formik.values.permissions}
              />
            ) : (
              <AssignPeople
                setShowAssignRole={setShowAssignRole}
                handleSelectPeople={handleSelectPeople}
                selectedPeople={formik.values.people}
                peopleOptions={peopleOptions.data || []}
              />
            )}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
