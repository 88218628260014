import axios from 'axios';
import { ICreateOffce } from '../types/officesTypes';

const API_HOST = process.env.REACT_APP_API_HOST;

async function getOffices() {
  const { data } = await axios.get(`${API_HOST}/v1/office`);
  return data;
}

async function creteOffice(body: ICreateOffce) {
  const response = await axios.post(`${API_HOST}/v1/office`, { value: body });
  return response;
}

async function deleteOffice(id: string) {
  const response = await axios.delete(`${API_HOST}/v1/office/${id}`);
  return response;
}

async function updateOffice({ id, body }: { id: string; body: ICreateOffce }) {
  const response = await axios.patch(`${API_HOST}/v1/office/${id}`, {
    value: body,
  });
  return response;
}

export default {
  getOffices,
  creteOffice,
  deleteOffice,
  updateOffice,
};
