import { useEffect, useState } from 'react';
import { Select } from '../../../../../../ui/form/Select';
import { UserTable } from '../components/UserTable';
import onboardingProcess from '../../../../../../network/onboardingProcess';
import { OnboardingStep } from '../../../../../../ui/layouts/OnboardingLayout';

export function FirstSubStep() {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const getUsers = async () => {
      const { data: userData } = await onboardingProcess.getOnboardingData(
        OnboardingStep.ImportUsers,
      );
      setUsers(userData?.data.users);
    };
    getUsers();
  }, []);

  return (
    <div className="flex flex-col">
      <h6 className="text-md font-semibold">Feature Access</h6>
      <p className="text-gray-500">
        Select the employees who will have access to the selected functions and
        they will be selected for the invitation automatically
      </p>
      <div className="mt-3">
        <UserTable users={users} />
      </div>
    </div>
  );
}
