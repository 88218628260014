import { TextInput } from '@tremor/react';
import { RiSearchLine } from '@remixicon/react';
import { UserIcon } from '@heroicons/react/24/solid';
import { Checkbox } from '../../../ui/component/checkbox';

export function AssignEmployee({
  setIsShownAssignEmployyes,
  peopleOptions,
  handleSelectPeople,
  selectedPeople,
}: any) {
  return (
    <div className="px-6 py-5">
      <div className="flex flex-col gap-2">
        <h6 className="font-semibold">Assign people</h6>
        <p className="text-gray-500">
          Select team members to bring your department to life
        </p>
        <TextInput
          // value={searchDocument}
          // onChange={(event) => setSearchDocument(event.target.value)}
          icon={RiSearchLine}
          placeholder="Search member by name or email address"
          className="w-full"
        />
        <div className="flex flex-col gap-1">
          {peopleOptions?.map(
            (person: {
              id: string;
              full_name: string;
              safe_profile_picture_url: string;
            }) => (
              <div
                key={person.id}
                className="flex flex-row gap-3 border-b py-2"
              >
                <div className="flex items-center">
                  <Checkbox
                    className="rounded-md border-[1px]"
                    color="rose"
                    checked={selectedPeople.includes(person.id)}
                    onChange={(checked) =>
                      handleSelectPeople(person.id, checked)
                    }
                  />
                </div>
                <div className="flex flex-row items-center gap-2">
                  <img
                    className="h-8 w-8 rounded-full "
                    src={person?.safe_profile_picture_url as string}
                    alt="User icon"
                  />
                  <p>{person.full_name}</p>
                </div>
              </div>
            ),
          )}
        </div>
        <div className="mt-2 flex flex-row justify-end gap-4">
          <button
            onClick={() => setIsShownAssignEmployyes(false)}
            className="px-3 py-1 font-medium"
          >
            Back
          </button>
          <button
            onClick={() => setIsShownAssignEmployyes(false)}
            className="rounded-lg bg-[#DC2626] px-3 py-1 text-white"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}
