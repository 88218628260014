import { PencilSquareIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import onboardingProcess from '../../../../../../network/onboardingProcess';
import { OnboardingStep } from '../../../../../../ui/layouts/OnboardingLayout';
import { toast } from 'react-toastify';

export function ThirdSubStep() {
  const [data, setData] = useState<{
    employees: number;
    assigned_roles: {
      hr_admin: number;
      employee: number;
    };
  }>({
    employees: 0,
    assigned_roles: {
      hr_admin: 0,
      employee: 0,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const { data } = await onboardingProcess.getOnboardingData(
        OnboardingStep.SendInvitationsSummary,
      );
      setData(data?.data);
    };
    getData();
  }, []);

  const handleSendInvitations = async () => {
    try {
      setIsLoading(true);
      await onboardingProcess.onboardingProcess(
        OnboardingStep.SendInvitations,
        {}
      );
      toast.success('Invitations sent successfully!');
    } catch (error) {
      console.error('Error sending invitations:', error);
      toast.error('Failed to send invitations. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col border-b pb-3">
        <h6 className="mb-2 text-base font-semibold">Employees invited</h6>
        <p className="w-fit rounded-lg bg-[#DBEAFE] px-2 py-1 text-[#1D4ED8]">
          {data?.employees}
        </p>
      </div>
      <div className="flex flex-col py-3 ">
        <h6 className="mb-2 text-base font-semibold">Assigned roles</h6>
        <div className="flex flex-row gap-2">
          <p className="rounded-lg bg-[#DBEAFE] px-2 py-1 text-[#1D4ED8]">
            Employee: {data?.assigned_roles.employee}
          </p>
          <p className="rounded-lg bg-[#DBEAFE] px-2 py-1 text-[#1D4ED8]">
            HR Admin: {data?.assigned_roles.hr_admin}
          </p>
        </div>
      </div>

      <div className="flex justify-end">
        <button 
          className="flex items-center gap-2 rounded-lg border-[1px] px-3 py-1 font-normal shadow disabled:opacity-50"
          onClick={handleSendInvitations}
          disabled={isLoading}
        >
          <PencilSquareIcon className="w-5" />
          {isLoading ? 'Sending...' : 'Send Invitations'}
        </button>
      </div>
    </div>
  );
}
