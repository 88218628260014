import { Dialog, Transition } from '@headlessui/react';
import {
  ClipboardDocumentListIcon,
  ClockIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { CalendarDaysIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { Fragment } from 'react';

export default function TimesheetsDetails({
  isOpen,
  setOpen,
  currentTimesheet,
  handleApprove,
  handleDecline
}: any) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-30" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full justify-end  text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4"
            >
              <div className="flex w-full max-w-2xl transform flex-col justify-between overflow-hidden bg-white px-7 py-4 text-left align-middle shadow-xl transition-all">
                <div>
                  <div className="border-b">
                      <p className="font-medium">Timesheets details</p>
                    <div className="flex flex-col gap-3 py-3">
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-row items-center gap-2">
                          <UserIcon className="w-5" />
                          <p className="font-medium">Employee</p>
                        </div>
                        <div className="w-[70%] flex flex-row items-center gap-1" >
                          <UserIcon className='w-6'/>
                          <div className='flex flex-col'>
                            <p>{currentTimesheet?.employee?.name}</p>
                            <p className='text-sm text-gray-500'>{currentTimesheet?.employee?.role}</p>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row justify-between">
                        <div>
                          <div className="flex flex-row gap-2">
                            <CalendarDaysIcon className="w-5" />
                            <p className="font-medium">Date:</p>
                          </div>
                        </div>
                        <div className="w-[70%]">
                          <p>{currentTimesheet?.date}</p>
                        </div>
                      </div>
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-row items-center gap-2">
                          <ClockIcon className="w-5" />
                          <p className="font-medium">Work time:</p>
                        </div>
                        <div className="w-[70%]">
                          <p>{currentTimesheet?.work_time?.from}</p> - <p>{currentTimesheet?.work_time?.to}</p>
                        </div>
                      </div>
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-row items-center gap-2">
                          <ClockIcon className="w-5" />
                          <p className="font-medium">Break time:</p>
                        </div>
                        <div className="w-[70%]">
                          <p>{currentTimesheet?.breaktime}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <p className="font-medium">Notes</p>
                    <p >
                      {currentTimesheet?.notes}
                    </p>
                  </div>

                </div>
                <div className="flex flex-row justify-end gap-3">
                  <button
                    onClick={() => {
                      setOpen(false)
                      handleDecline()
                    }}
                    className="bg-[#DC2626] rounded-lg px-4 py-2 flex flex-row gap-2 items-center text-white"
                  >
                    <XCircleIcon className='w-5' color='white'/>
                    Reject
                  </button>
                  <button 
                    onClick={() => {
                      handleApprove(currentTimesheet?.id)
                      setOpen(false);
                    }}
                    className="rounded-lg bg-[#15803D] px-4 py-2 flex flex-row gap-2 items-center text-white">
                    <CheckCircleIcon className='w-5' color='white'/>
                    Save Changes
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
