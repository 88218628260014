import { CALENDAR_MODE__MONTH, CALENDAR_MODE__WEEK } from '../../../constants';
import { CalendarBodyProps } from '../../../types/RotaTypes';
import { DayCell } from './DayCell';
import { WeekDayCell } from './WeekDayCell';

export function CalendarBody({
  today,
  daysMap,
  rotas,
  mode,
}: CalendarBodyProps) {
  return (
    <>
      <div className="flex flex-auto flex-col shadow ring-1 ring-black ring-opacity-5">
        <div className="flex flex-auto bg-gray-200 text-xs leading-6 text-gray-700">
          {mode === CALENDAR_MODE__MONTH ? (
            <>
              <div className="grid w-full grid-cols-7 gap-px sm:grid-rows-6">
                {daysMap.map((day, index) => (
                  <DayCell key={index} today={today} day={day} rotas={rotas} />
                ))}
              </div>{' '}
            </>
          ) : (
            <>
              <WeekDayCell days={daysMap} rotas={rotas} today={today}/>
            </>
          )}
        </div>
      </div>
    </>
  );
}
