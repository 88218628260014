import { Steps } from '../../../../ui/layouts/OnboardingLayout';

export function Stepper({ currentStep }: { currentStep: Steps }) {
  const stepNames = Object.keys(Steps).filter((key) => isNaN(Number(key)));

  return (
    <div className="mt-8 flex items-center justify-center gap-3">
      {stepNames.map((_, index) => (
        <div
          key={index}
          className={`h-2 flex-1 rounded-full transition-all duration-300 ${
            index === currentStep
              ? 'bg-[#15803D]'
              : index < currentStep
              ? 'bg-[#15803D]'
              : 'bg-[#DCFCE7]'
          }`}
        ></div>
      ))}
    </div>
  );
}
