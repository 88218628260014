import axios from 'axios';
import { OnboardingData } from '../types/onboardingTypes';
import { OnboardingStep } from '../ui/layouts/OnboardingLayout';

const API_HOST = process.env.REACT_APP_API_HOST;

interface OnboardingProcessData extends Partial<OnboardingData> {
  import_method?: string;
  job_title?: string;
}

async function onboardingProcess(key: string, body: Partial<OnboardingData>) {
  const response = await axios.put(`${API_HOST}/v1/onboarding/${key}`, body);
  return response;
}

async function getOnboardingData(key: string) {
  const data = await axios.get(`${API_HOST}/v1/onboarding/${key}`);
  return data;
}

async function uploadCsvFile(key: string, body: FormData) {
  const response = await axios.put(`${API_HOST}/v1/onboarding/${key}`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
}

async function getOnboardingFeaturesData() {
  const data = await axios.get(`${API_HOST}/v1/onboarding/features`);
  return data;
}

async function getOnboardingApprovalWorkflows() {
  const response = await axios.get(
    `${API_HOST}/v1/onboarding/approval-workflows`,
  );
  return response;
}

async function sendOnboaringFeedback(body: {
  rating: number;
  feedback: string;
}) {
  const response = await axios.post(`${API_HOST}/v1/onboarding/feedback`, body);
  return response;
}

async function sendOnboardingWaitList(body: {
  rating: number;
  comment: string;
}) {
  const response = await axios.post(`${API_HOST}/v1/onboarding/waitlist`, body);
  return response;
}

async function getOnboardingIndustries() {
  const { data } = await axios.get(`${API_HOST}/v1/onboarding/industry`);
  return data;
}

async function getOnboardingCountries() {
  const { data } = await axios.get(`${API_HOST}/v1/onboarding/country`);
  return data;
}

async function getOnboardingRoles() {
  const { data } = await axios.get(`${API_HOST}/v1/onboarding/roles`);
  return data;
}

const deleteUser = async (userId: string) => {
  return await axios.delete(`${API_HOST}/v1/onboarding/user/${userId}`);
};

export default {
  onboardingProcess: (step: OnboardingStep, data: OnboardingProcessData) =>
    axios.put(`${API_HOST}/v1/onboarding/${step}`, data),
  getOnboardingData,
  uploadCsvFile,
  getOnboardingFeaturesData,
  getOnboardingApprovalWorkflows,
  sendOnboaringFeedback,
  sendOnboardingWaitList,
  getOnboardingIndustries,
  getOnboardingCountries,
  getOnboardingRoles,
  deleteUser,
};
