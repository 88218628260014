import { BigFolderIcon } from '../../../assets/DocumentManagementIcon/Folder';

type ISystem = {
  id: number;
  name: string;
  owner: string | number;
};

interface IFolder {
  system: ISystem;
  files: number;
  size: string;
}

export default function FolderElement({
  folder,
  onClick,
}: {
  folder: IFolder;
  onClick: any;
}) {
  return (
    <div
      className="mb-2 min-w-[210px] max-w-[250px]
      cursor-pointer rounded-xl border-[1px] border-gray-200 px-4
      py-4 shadow hover:bg-[#FAFAFA]"
      onClick={() => onClick(folder.system.id)}
    >
      <BigFolderIcon />
      <div className="mt-3 flex flex-col">
        <h4 className="font-semibold">{folder.system.name}</h4>
        <p className="mt-1 flex items-center text-sm text-gray-400">
          {folder.files} Files{' '}
          <span className="mx-2 inline-block h-[6px] w-[6px] rounded-full bg-gray-400"></span>{' '}
          {folder.size}{' '}
        </p>
      </div>
    </div>
  );
}
