import { AiGenerateIcon } from '../../assets/DocumentManagementIcon/AIGenerate';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@tremor/react';
import { Departments } from './components/Departments';
import { Offices } from './components/Offices';

export function OrganizationRoute() {
  return (
    <>
      <div className="flex items-center justify-between border-b-2 border-[#E4E4E7] pb-4">
        <h1 className="text-3xl font-bold  text-tremor-content-strong">
          Organization Management
        </h1>

        {/* <button
          className="flex items-center gap-2 rounded-lg bg-gradient-to-r 
          from-[#F77B23] to-[#F69620] px-5 py-2 font-normal text-white"
        >
          <AiGenerateIcon />
          Redstone AI
        </button> */}
      </div>
      <div className="my-5">
        <TabGroup>
          <TabList variant="solid" color="rose">
            <Tab className="px-3 py-1">Departments</Tab>
            <Tab className="px-3 py-1">Offices</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Departments />
            </TabPanel>
            <TabPanel>
              <Offices />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </>
  );
}
