import { Switch } from '../../../ui/component/switch';

export function HandlePermisions({
  setShowPermisions,
  handlePermissionChange,
  permissions,
}: any) {
  return (
    <div className="px-6 py-5">
      <div className="flex flex-col gap-2">
        <h6 className="font-semibold">Permissions</h6>
        <p className="text-gray-500">
          Create a new department to organize your team and streamline
          management. Define roles and assign a department head to lead the way.
        </p>
        {permissions.map((permission: any) => (
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col">
              <p className="font-medium">{permission.name}</p>
              <p className="text-gray-400">{permission.description}</p>
            </div>
            <Switch
              className="border"
              color="rose"
              checked={permission.enabled}
              onChange={(checked) =>
                handlePermissionChange(permission.id, checked)
              }
            />
          </div>
        ))}
        <div className="mt-2 flex flex-row justify-end gap-4">
          <button
            onClick={() => setShowPermisions(false)}
            className="px-3 py-1 font-medium"
          >
            Back
          </button>
          <button
            onClick={() => setShowPermisions(false)}
            className="rounded-lg bg-[#DC2626] px-3 py-1 text-white"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}
