import { Link } from 'react-router-dom';
import {
  ArrowDownIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { Icon } from '@tremor/react';
import usePagination from '../../../hooks/usePagination';
import { Pagination } from '../../../ui/component/pagination';
import { useQueryHolidayRequest } from '../../../hooks/useProfileDetails';
import { useState } from 'react';
import DeleteModal from './DeleteModal';
import { renderDecision } from '../../../utils/holidayRequestsTab';
import timeaway from '../../../network/timeaway';
import Notification from '../../../ui/component/notification';

const ProfileHolidayRequest = () => {
  const [limit, setLimit] = useState(10);
  const [open, setOpen] = useState(false);
  const [requestedTimeAway, setRequestedTimeAway] = useState();
  const [showErrorTimeawayNotification, setShowErrorTimeawayNotification] =
    useState(false);

  const {
    data: response = [],
    page,
    totalItems,
    setDirectPage,
    refetch,
  } = usePagination({
    key: 'profile-section',
    fetcher: useQueryHolidayRequest,
    initialPage: 0,
    initialItemsPerPage: limit,
  });

  const handleOpenDeleteModal = (request: any) => {
    setOpen(true);
    setRequestedTimeAway(request);
  };

  const deleteTimeaway = async (id: string | number) => {
    try {
      await timeaway.deleteTimeawayRequest(id);
      refetch();
      setOpen(false);
    } catch (error) {
      setOpen(false);
      setShowErrorTimeawayNotification(true);
    }
  };

  return (
    <div className="overflow-hidden rounded-lg bg-white shadow">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="m4-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="flex items-center py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Type
                      <Icon icon={ArrowDownIcon} color="gray" size="sm" />
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Details
                    </th>
                    <th
                      scope="col"
                      className="self-center px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <p className="flex items-center">
                        From
                        <Icon icon={ArrowDownIcon} color="gray" size="sm" />
                      </p>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <p className="flex items-center">
                        {' '}
                        Until
                        <Icon icon={ArrowDownIcon} color="gray" size="sm" />
                      </p>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="sr-only">View</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {response.map((item: any, index: number) => (
                    <tr key={index}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {item?.type.split(' ')[0]}
                        <br />
                        <span className="text-gray-500">
                          {item?.type.split(' ')[1].replace(/[()]/g, '')}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item?.details}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item?.start}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item?.end}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm ">
                        <div
                          className={`flex max-w-[120px] items-center justify-around rounded-full px-2 py-2 
                        ${
                          item?.decision === 'approved'
                            ? 'bg-tremor-background-lightGreen text-green-700'
                            : item?.decision === 'canceled'
                            ? 'bg-tremor-background-lightRed text-rose-700'
                            : 'bg-tremor-background-subtle text-gray-500'
                        }`}
                        >
                          <svg width="10" height="10">
                            <circle
                              cx="5"
                              cy="5"
                              r="5"
                              fill={`${
                                item?.decision === 'approved'
                                  ? 'green'
                                  : item?.decision === 'canceled'
                                  ? 'red'
                                  : 'gray'
                              }`}
                            />
                          </svg>
                          {renderDecision(item?.decision)}
                        </div>
                      </td>
                      <td className="relative space-x-2 whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <button onClick={() => handleOpenDeleteModal(item)}>
                          <Icon icon={TrashIcon} color="red" size="md" />
                        </button>
                        <Link
                          to={`/timeaway/${item.id}`}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          <Icon icon={PencilIcon} color="gray" size="md" />
                          <span className="sr-only">, {item?.type}</span>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                limit={limit}
                count={totalItems}
                page={page}
                onPageChange={setDirectPage}
              />
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        open={open}
        setOpen={setOpen}
        requestedTimeAway={requestedTimeAway}
        deleteTimeaway={deleteTimeaway}
      />

      <Notification
        title="Time away request can not be deleted."
        message="Something went wrong. Please try again later."
        open={showErrorTimeawayNotification}
        close={() => setShowErrorTimeawayNotification(false)}
        isError={true}
      />
    </div>
  );
};

export default ProfileHolidayRequest;
