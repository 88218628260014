import React, { useEffect, useMemo } from 'react';
import { Fragment, ReactNode, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  BellIcon,
  CalendarIcon,
  HomeIcon,
  ClockIcon,
  UserIcon,
  UsersIcon,
  ArrowRightOnRectangleIcon,
  Cog6ToothIcon,
  ChatBubbleBottomCenterTextIcon,
  ClipboardDocumentListIcon,
  DocumentIcon,
  CreditCardIcon,
  BanknotesIcon,
} from '@heroicons/react/24/outline';

import { fill } from '@cloudinary/url-gen/actions/resize';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { SidebarLayout } from '../../component/sidebar-layout';
import { Navbar } from '../../component/navbar';
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
} from '../../component/sidebar';

import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from '../../component/dropdown';
import { Icon } from '@tremor/react';
import { useClerk, useUser } from '@clerk/clerk-react';
import axios from 'axios';
import { useQuery } from 'react-query';
import configManagement from '../../../network/configManagement';
import Notifications from './components/Notifications';
import { useChatbotModal } from '../../../hooks/useChatbotModal';
import ChatbotModal from './components/Chatbot';

const myImage = new CloudinaryImage('redstone-icon_xwvsrc', {
  cloudName: 'dnzgb3sfo',
}).resize(fill().width(300).height(237));

const buildNotification = (notifications: any) => {
  return notifications.map((notification: any) => {
    return {
      ...notification,
      user: { full_name: notification?.name },
      message: notification?.description,
      content: `Holiday leave (${notification?.time_range?.start} - ${notification?.time_range?.end})`,
      time: notification?.time,
      read: false,
    };
  });
};

type Props = {
  children: ReactNode;
};

const { REACT_APP_API_HOST } = process.env;

export function ControlPanelLayout({ children }: Props) {
  const [navigation, setNavigtion] = useState<
    {
      id: string;
      href: string;
      label: string;
      icon: any;
      enabled: boolean;
    }[]
  >([]);
  const { user } = useUser();
  const [url, setUrl] = useState('');
  const { signOut } = useClerk();

  const { isChatbotModalOpen, closeChatbot } = useChatbotModal(); 
  const [newNotifications, setNewNotifications] = useState([]);
  const [isShownNotification, setIsShownNotification] = useState(false);

  const { data: value, status } = useQuery({
    queryKey: ['billingPortalUrl'],
    queryFn: async () => {
      const response = await axios.get(
        `${REACT_APP_API_HOST}/v1/profile/billing`,
      );
      return response.data;
    },
  });

  const { data: response, status: responseStatus } = useQuery({
    queryKey: ['notifications'],
    queryFn: async () => {
      const response = await axios.get(
        `${REACT_APP_API_HOST}/v1/notification`,
      );
      return response.data;
    },
  });

  useEffect(() => {
    if (responseStatus === 'success') {
      setNewNotifications(buildNotification(response?.notifications));
    }
  }, [responseStatus, response]);

  useEffect(() => {
    if (status === 'success') {
      setUrl(value?.data?.billing);
    }
  }, [status, value, url]);

  const userNavigation = useMemo(
    () => [
      {
        name: 'Your profile',
        href: '/profile',
        icon: UserIcon,
        external: false,
      },
      {
        name: 'Settings',
        href: '/settings',
        icon: Cog6ToothIcon,
        external: false,
      },
      {
        name: 'Organization',
        href: '/organization',
        icon: BanknotesIcon,
        external: false,
      },
      { name: 'Billing', href: url, icon: CreditCardIcon, external: true },
      {
        name: 'Sign out',
        href: '/signout',
        onClick: () => {
          signOut();
        },
        icon: ArrowRightOnRectangleIcon,
        external: false,
      },
    ],
    [url],
  );

  useEffect(() => {
    async function getConfig() {
      const { data } = await configManagement.getConfig();
      const navigationOptions = data?.data.features?.map((item: any) => {
        switch (item.id) {
          case 'calendar':
            return {
              ...item,
              icon: CalendarIcon,
              href: '/calendar',
            };
          case 'timeoff':
            return {
              ...item,
              icon: ClockIcon,
              href: '/time-off',
            };
          case 'rota_management':
            return {
              ...item,
              icon: DocumentIcon,
              href: '/rota-management',
            };
          case 'people_management':
            return {
              ...item,
              icon: UsersIcon,
              href: '/people',
            };
          case 'reports':
            return {
              ...item,
              icon: ChatBubbleBottomCenterTextIcon,
              href: '/report',
            };
          case 'ats':
            return {
              ...item,
              icon: ClipboardDocumentListIcon,
              href: '/ats',
            };
          case 'document_management':
            return {
              ...item,
              icon: DocumentIcon,
              href: '/document-management',
            };
          case 'timesheets':
            return {
              ...item,
              icon: ClockIcon,
              href: '/timesheets',
            };
        }
      });

      navigationOptions.length > 0 &&
        navigationOptions.unshift({
          id: 'home',
          label: 'Home',
          icon: HomeIcon,
          href: '/',
          enabled: true,
        }
      );
      setNavigtion(navigationOptions);
    }
    getConfig();
  }, []);

  return (
    <>
      <SidebarLayout
        navbar={<></>}
        sidebar={
          <Sidebar>
            <SidebarHeader>
              <div className="flex flex-shrink-0 items-center">
                <img className="h-8 w-auto " src={myImage.toURL()} alt="logo" />
                <SidebarLabel className="ml-2 font-medium">
                  Redstone HR
                </SidebarLabel>
              </div>
            </SidebarHeader>
            <SidebarBody className="flex justify-between">
              <SidebarSection className="space-y-1">
                {navigation?.map((item) =>
                  item?.enabled ? (
                    <NavLink key={item.id} to={item.href}>
                      <SidebarItem>
                        <Icon icon={item.icon} color="gray" />
                        <SidebarLabel>{item.label}</SidebarLabel>
                      </SidebarItem>
                    </NavLink>
                  ) : null,
                )}
              </SidebarSection>
              <SidebarSection>
                <SidebarItem
                  className="w-[100%] flex flex-row justify-between items-center "
                  onClick={() => {
                    setIsShownNotification(true);
                  }}
                >
                    <div className="flex items-center">
                      <Icon icon={BellIcon} color="gray" size="md" />
                      Notifications
                    </div>
                    <div >
                      <p className="rounded-lg bg-[#FEE2E2] px-2 text-[#DC2626]">
                        {newNotifications?.length || 0}
                      </p>
                    </div>
                </SidebarItem>
              </SidebarSection>
            </SidebarBody>
            <SidebarFooter>
              <Dropdown>
                <DropdownButton as={SidebarItem}>
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="h-8 w-8 rounded-full "
                    src={user?.imageUrl as string}
                    alt="User icon"
                  />
                  <div className="truncate">
                    <p className="text-sm text-tremor-content">
                      {user?.firstName}
                    </p>
                    <p className="text-[12px] text-tremor-content-subtle ">
                      {user?.primaryEmailAddress?.emailAddress}
                    </p>
                  </div>
                </DropdownButton>
                <DropdownMenu>
                  {userNavigation.map((item, index) => (
                    <React.Fragment key={item.name}>
                      <DropdownItem className="flex cursor-pointer justify-center">
                        {item.external ? (
                          <a
                            href={item.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex flex-row items-center space-x-2"
                            onClick={item.onClick}
                          >
                            <Icon icon={item.icon} color="gray" />
                            <DropdownLabel>{item.name}</DropdownLabel>
                          </a>
                        ) : (
                          <NavLink
                            to={item.href}
                            className="flex flex-row items-center space-x-2"
                            onClick={item.onClick}
                          >
                            <Icon icon={item.icon} color="gray" />
                            <DropdownLabel>{item.name}</DropdownLabel>
                          </NavLink>
                        )}
                      </DropdownItem>
                      {index < 2 && <DropdownDivider />}
                    </React.Fragment>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </SidebarFooter>
          </Sidebar>
        }
      >
        <Notifications
          isOpen={isShownNotification}
          setOpen={setIsShownNotification}
          notifications={newNotifications}
        />
        <ChatbotModal 
          isOpen={isChatbotModalOpen}
          setOpen={closeChatbot}
        />
        <main>
          <div className="mx-auto w-full ">{children}</div>
        </main>
      </SidebarLayout>
    </>
  );
}
