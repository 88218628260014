import { Input } from '../../../ui/form/Input';

export function WorkSpaceDetails() {
  return (
    <div className="flex flex-col gap-5">
      <div className="items-ceneter mt-1 flex flex-row justify-between">
        <h5 className="text-base font-medium">Workspace details</h5>
        <p className="rounded-lg bg-[#F4F4F5] px-3 py-1">
          Last update : 28 Oct,2024
        </p>
      </div>
      <div className="flex flex-row rounded-xl p-5 shadow">
        <div className="flex w-[50%] flex-col">
          <h5 className="text-base font-medium">Organization Information</h5>
          <p className="text-gray-500">Enter details about your organization</p>
        </div>
        <div className="flex w-[50%] flex-col">
          <div>
            <label
              htmlFor="company_name"
              className="mb-1 block text-base font-medium"
            >
              Company name
            </label>
            <Input
              type="text"
              placeholder="Company name"
              id="company_name"
              name="company_name"
            />
          </div>
          <div className="mt-2">
            <label
              htmlFor="industry"
              className="mb-1 block text-base font-medium"
            >
              Industry
            </label>
            <Input
              type="text"
              placeholder="Industry"
              id="industry"
              name="industry"
            />
          </div>
          <div className="mt-2">
            <label
              htmlFor="employees"
              className="mb-1 block text-base font-medium"
            >
              Number of Employeess
            </label>
            <Input
              type="text"
              placeholder="1-50"
              id="employees"
              name="employees"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row rounded-xl p-5 shadow">
        <div className="flex w-[50%] flex-col">
          <h5 className="text-base font-medium">Absence Policies</h5>
          <p className="text-gray-500">
            Define and manage your company’s absence policies
          </p>
        </div>
        <div className="flex w-[50%] flex-col">
          <div>
            <label
              htmlFor="sick_leave"
              className="mb-1 block text-base font-medium"
            >
              Sick Leave
            </label>
            <Input
              type="text"
              placeholder="24"
              id="sick_leave"
              name="sick_leave"
            />
          </div>
          <div className="mt-2">
            <label htmlFor="pto" className="mb-1 block text-base font-medium">
              Paid Time Off(PTO)
            </label>
            <Input type="text" placeholder="12" id="pto" name="pto" />
          </div>
          <div className="mt-2">
            <label
              htmlFor="parental_leave"
              className="mb-1 block text-base font-medium"
            >
              Parental Leave
            </label>
            <Input
              type="text"
              placeholder="12"
              id="parental_leave"
              name="parental_leave"
            />
          </div>
          <div className="mt-4 flex justify-end">
            <button
              className="flex items-center 
             rounded-lg border-[1px] px-3 py-1 font-normal shadow"
            >
              Edit absence policies
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-end gap-4">
        <button className="flex items-center rounded-lg border-[1px] px-3 py-1 font-normal shadow">
          Go Back
        </button>
        <button className="flex items-center rounded-lg bg-[#DC2626] px-3 py-1 font-normal text-white">
          Save changes
        </button>
      </div>
    </div>
  );
}
