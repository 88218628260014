import React, { useEffect } from 'react';

interface ElevenLabsWidgetProps {
  isVisible?: boolean;
}

const ElevenLabsWidget: React.FC<ElevenLabsWidgetProps> = ({ isVisible = true }) => {
  useEffect(() => {
    // Dynamically load the ElevenLabs script
    const script = document.createElement('script');
    script.src = 'https://elevenlabs.io/convai-widget/index.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup script when component unmounts
      document.body.removeChild(script);
    };
  }, []);

  if (!isVisible) return null;

  return (
    <>
    
      <elevenlabs-convai agent-id="0R17rv7FO4IgmgHFWYrH" />
    </>
  );
};

export default ElevenLabsWidget; 