export const EmptyStateIcon = () => {
  return (
    <svg
      width="398"
      height="385"
      viewBox="0 0 398 385"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M151.167 42.6282C68.1198 65.6198 19.4352 151.581 42.4268 234.628C65.4184 317.675 151.38 366.36 234.427 343.368C317.474 320.377 366.159 234.415 343.167 151.368C320.175 68.3212 234.214 19.6366 151.167 42.6282Z"
        fill="#DEE7FF"
        fillOpacity="0.56"
      />
      <path
        d="M247.533 87.997L250.494 86.5023C250.651 86.4589 250.921 86.2154 250.834 85.9019L250.593 82.5937C250.62 82.0801 250.846 81.6798 251.317 81.5496C251.787 81.4195 252.344 81.6027 252.474 82.073L253.969 85.0341C254.012 85.1908 254.256 85.4609 254.569 85.3741L257.877 85.1332C258.391 85.1597 258.791 85.3864 258.921 85.8566C259.052 86.3268 258.868 86.8838 258.398 87.014L255.437 88.5088C255.28 88.5522 255.01 88.7957 255.097 89.1091L255.338 92.4174C255.311 92.931 255.085 93.3312 254.614 93.4614C254.144 93.5916 253.587 93.4083 253.457 92.9381L251.962 89.977C251.919 89.8202 251.675 89.5502 251.362 89.637L248.054 89.8778C247.54 89.8513 247.14 89.6246 247.01 89.1544C246.923 88.8409 247.106 88.2839 247.533 87.997Z"
        fill="#F9CF7E"
      />
      <path
        d="M105.368 105.027L108.191 103.602C108.34 103.56 108.598 103.328 108.515 103.029L108.285 99.8755C108.311 99.3858 108.527 99.0043 108.975 98.8802C109.423 98.7561 109.954 98.9308 110.078 99.3791L111.503 102.202C111.545 102.351 111.777 102.609 112.076 102.526L115.23 102.296C115.719 102.322 116.101 102.538 116.225 102.986C116.349 103.434 116.174 103.965 115.726 104.089L112.903 105.514C112.754 105.556 112.496 105.788 112.579 106.087L112.809 109.241C112.783 109.73 112.567 110.112 112.119 110.236C111.671 110.36 111.14 110.185 111.016 109.737L109.591 106.914C109.549 106.765 109.317 106.507 109.018 106.59L105.864 106.82C105.375 106.794 104.993 106.578 104.869 106.13C104.745 105.682 104.92 105.151 105.368 105.027Z"
        fill="#D6DCE8"
      />
      <path
        d="M206.493 135.937L208.773 134.786C208.893 134.753 209.101 134.565 209.035 134.324L208.849 131.777C208.87 131.381 209.044 131.073 209.406 130.973C209.768 130.873 210.197 131.014 210.297 131.376L211.448 133.656C211.482 133.776 211.669 133.984 211.91 133.918L214.458 133.732C214.853 133.752 215.161 133.927 215.262 134.289C215.362 134.651 215.221 135.08 214.859 135.18L212.579 136.331C212.458 136.365 212.25 136.552 212.317 136.793L212.502 139.341C212.482 139.736 212.307 140.044 211.945 140.145C211.583 140.245 211.154 140.104 211.054 139.742L209.903 137.462C209.87 137.341 209.682 137.133 209.441 137.2L206.894 137.385C206.498 137.365 206.19 137.19 206.09 136.828C205.902 136.62 206.164 136.158 206.493 135.937Z"
        fill="#D6DCE8"
      />
      <path
        d="M116.665 296.511L120.758 294.444C120.975 294.384 121.348 294.048 121.228 293.614L120.895 289.041C120.932 288.331 121.245 287.778 121.895 287.598C122.545 287.418 123.315 287.671 123.495 288.321L125.562 292.415C125.622 292.631 125.958 293.005 126.392 292.885L130.965 292.552C131.675 292.589 132.228 292.902 132.408 293.552C132.588 294.202 132.335 294.972 131.685 295.152L127.591 297.218C127.375 297.278 127.001 297.615 127.121 298.048L127.454 302.621C127.418 303.331 127.104 303.885 126.454 304.065C125.804 304.244 125.034 303.991 124.854 303.341L122.788 299.248C122.728 299.031 122.391 298.658 121.958 298.778L117.385 299.111C116.675 299.074 116.122 298.761 115.942 298.111C115.762 297.461 116.075 296.907 116.665 296.511Z"
        fill="#F9CF7E"
      />
      <path
        d="M324.352 188.197L326.496 184.98C326.63 184.846 326.764 184.444 326.496 184.176L324.352 180.959C324.084 180.423 324.084 179.887 324.486 179.485C324.888 179.083 325.558 178.949 325.96 179.351L329.177 181.495C329.311 181.629 329.713 181.763 329.981 181.495L333.198 179.351C333.734 179.083 334.27 179.083 334.672 179.485C335.074 179.887 335.208 180.557 334.806 180.959L332.662 184.176C332.528 184.31 332.394 184.712 332.662 184.98L334.806 188.197C335.074 188.733 335.074 189.269 334.672 189.671C334.27 190.074 333.6 190.208 333.198 189.805L329.981 187.661C329.847 187.527 329.445 187.393 329.177 187.661L325.96 189.805C325.424 190.074 324.888 190.074 324.486 189.671C324.084 189.269 323.95 188.599 324.352 188.197Z"
        fill="#F9CF7E"
      />
      <path
        d="M117.049 165.249L119.793 159.745C119.978 159.507 120.109 158.846 119.633 158.476L115.608 153.826C115.078 153.033 114.971 152.187 115.526 151.472C116.08 150.757 117.111 150.412 117.826 150.967L123.33 153.711C123.568 153.896 124.23 154.028 124.599 153.551L129.25 149.526C130.042 148.996 130.889 148.889 131.603 149.444C132.318 149.999 132.663 151.03 132.108 151.744L129.364 157.248C129.179 157.487 129.048 158.148 129.524 158.518L133.549 163.168C134.079 163.961 134.186 164.807 133.631 165.522C133.077 166.236 132.046 166.581 131.331 166.027L125.827 163.282C125.589 163.098 124.927 162.966 124.558 163.443L119.907 167.468C119.115 167.998 118.268 168.104 117.554 167.55C116.839 166.995 116.494 165.964 117.049 165.249Z"
        fill="#F9CF7E"
      />
      <path
        d="M171.713 327.835L175.558 325.894C175.761 325.838 176.112 325.522 175.999 325.115L175.686 320.819C175.721 320.152 176.015 319.633 176.626 319.464C177.236 319.295 177.959 319.533 178.128 320.143L180.069 323.988C180.125 324.191 180.442 324.542 180.849 324.429L185.144 324.117C185.811 324.151 186.331 324.445 186.5 325.056C186.669 325.666 186.431 326.39 185.82 326.559L181.975 328.499C181.772 328.556 181.421 328.872 181.534 329.279L181.847 333.574C181.812 334.241 181.518 334.761 180.907 334.93C180.297 335.099 179.574 334.861 179.405 334.251L177.464 330.406C177.408 330.202 177.091 329.852 176.684 329.964L172.389 330.277C171.722 330.243 171.202 329.948 171.033 329.338C170.864 328.727 171.102 328.004 171.713 327.835Z"
        fill="#F9CF7E"
      />
      <path
        d="M97.81 229.481L100.484 224.738C100.659 224.536 100.805 223.957 100.4 223.608L97.0508 219.316C96.6181 218.591 96.5619 217.838 97.0844 217.231C97.6069 216.624 98.5341 216.365 99.141 216.888L103.884 219.562C104.086 219.737 104.665 219.883 105.013 219.478L109.306 216.129C110.031 215.696 110.784 215.64 111.391 216.162C111.998 216.685 112.256 217.612 111.734 218.219L109.059 222.962C108.885 223.164 108.739 223.743 109.144 224.091L112.493 228.384C112.926 229.109 112.982 229.862 112.459 230.469C111.937 231.076 111.01 231.334 110.403 230.812L105.66 228.137C105.458 227.963 104.879 227.817 104.53 228.222L100.238 231.571C99.5128 232.004 98.7598 232.06 98.1529 231.537C97.546 231.015 97.2875 230.088 97.81 229.481Z"
        fill="#F9CF7E"
      />
      <path
        d="M164.891 81.5366L169.911 79.1122C170.391 78.9792 170.498 78.4321 170.432 78.1918L169.93 72.6401C169.97 71.8528 170.492 70.9324 171.212 70.7329C171.933 70.5333 172.787 70.8143 173.293 71.7088L175.718 76.7285C175.851 77.209 176.398 77.3162 176.638 77.2497L182.19 76.7474C182.977 76.7881 183.897 77.3093 184.097 78.0301C184.296 78.7509 184.016 79.6047 183.121 80.1111L178.101 82.5355C177.621 82.6685 177.514 83.2155 177.58 83.4558L178.082 89.0076C178.042 89.7949 177.52 90.7152 176.8 90.9148C176.079 91.1143 175.225 90.8333 174.719 89.9388L172.294 84.9191C172.161 84.4386 171.614 84.3314 171.374 84.3979L165.822 84.9002C165.035 84.8595 164.115 84.3383 163.915 83.6175C163.649 82.6564 163.93 81.8026 164.891 81.5366Z"
        fill="#EAAB58"
      />
      <path
        d="M282.161 135.646L284.682 134.428C284.923 134.361 284.977 134.086 284.944 133.966L284.691 131.177C284.712 130.782 284.974 130.319 285.336 130.219C285.698 130.119 286.127 130.26 286.381 130.709L287.599 133.231C287.666 133.472 287.94 133.526 288.061 133.492L290.85 133.24C291.245 133.261 291.707 133.522 291.808 133.884C291.908 134.247 291.767 134.675 291.317 134.93L288.796 136.147C288.555 136.214 288.501 136.489 288.534 136.61L288.787 139.398C288.766 139.794 288.504 140.256 288.142 140.356C287.78 140.457 287.351 140.315 287.097 139.866L285.879 137.345C285.812 137.103 285.538 137.05 285.417 137.083L282.628 137.335C282.233 137.315 281.771 137.053 281.67 136.691C281.57 136.329 281.832 135.867 282.161 135.646Z"
        fill="#D6DCE8"
      />
      <path
        d="M259.762 292.254L265.444 289.51C265.988 289.359 266.109 288.74 266.034 288.468L265.465 282.184C265.512 281.293 266.102 280.251 266.917 280.025C267.733 279.799 268.7 280.117 269.273 281.13L272.017 286.811C272.168 287.355 272.787 287.477 273.059 287.401L279.343 286.833C280.234 286.879 281.276 287.469 281.502 288.285C281.728 289.101 281.41 290.067 280.397 290.64L274.715 293.385C274.171 293.535 274.05 294.154 274.125 294.426L274.694 300.71C274.648 301.602 274.058 302.643 273.242 302.869C272.426 303.095 271.46 302.777 270.886 301.765L268.142 296.083C267.992 295.539 267.372 295.417 267.1 295.493L260.816 296.061C259.925 296.015 258.883 295.425 258.657 294.609C258.432 293.793 258.75 292.827 259.762 292.254Z"
        fill="#F9CF7E"
      />
      <path
        d="M286.385 234.093L290.468 232.121C290.859 232.012 290.946 231.567 290.892 231.372L290.484 226.856C290.517 226.215 290.941 225.466 291.527 225.304C292.113 225.142 292.808 225.37 293.22 226.098L295.192 230.181C295.3 230.572 295.745 230.66 295.941 230.605L300.457 230.197C301.098 230.23 301.846 230.654 302.009 231.24C302.171 231.827 301.942 232.521 301.215 232.933L297.131 234.905C296.74 235.014 296.653 235.459 296.707 235.654L297.116 240.17C297.083 240.811 296.659 241.56 296.072 241.722C295.486 241.884 294.791 241.656 294.38 240.928L292.407 236.844C292.299 236.454 291.854 236.366 291.659 236.42L287.142 236.829C286.502 236.796 285.753 236.372 285.591 235.786C285.429 235.199 285.853 234.451 286.385 234.093Z"
        fill="#F9CF7E"
      />
      <path
        d="M59.247 174.284L65.3024 173.736C65.8265 173.591 66.2337 174.042 66.3062 174.304L68.9506 179.779C69.4303 180.493 70.4341 181.062 71.2202 180.844C72.0064 180.626 72.6475 179.885 72.6193 178.764L72.0715 172.708C71.9264 172.184 72.3779 171.777 72.64 171.705L78.115 169.06C78.8286 168.581 79.3971 167.577 79.1795 166.791C78.9618 166.004 78.2201 165.363 77.0993 165.391L71.0439 165.939C70.5197 166.084 70.1126 165.633 70.0401 165.371L67.3957 159.896C66.916 159.182 65.9122 158.614 65.126 158.831C64.3399 159.049 63.6988 159.791 63.727 160.912L64.2748 166.967C64.4199 167.491 63.9684 167.898 63.7063 167.971L58.2313 170.615C57.5177 171.095 56.9492 172.099 57.1668 172.885C57.457 173.933 58.4608 174.501 59.247 174.284Z"
        fill="#F9CF7E"
      />
      <path
        d="M26.8761 294.799L35.438 294.024C36.179 293.819 36.7547 294.457 36.8572 294.828L40.5961 302.569C41.2744 303.578 42.6937 304.382 43.8052 304.074C44.9168 303.766 45.8232 302.717 45.7834 301.133L45.0088 292.571C44.8036 291.83 45.442 291.254 45.8126 291.152L53.5538 287.413C54.5628 286.735 55.3666 285.315 55.0589 284.204C54.7511 283.092 53.7024 282.186 52.1177 282.225L43.5558 283C42.8148 283.205 42.2391 282.567 42.1366 282.196L38.3977 274.455C37.7194 273.446 36.3001 272.642 35.1886 272.95C34.077 273.258 33.1706 274.307 33.2104 275.891L33.985 284.453C34.1902 285.194 33.5518 285.77 33.1812 285.872L25.44 289.611C24.431 290.289 23.6272 291.709 23.935 292.82C24.3453 294.302 25.7645 295.106 26.8761 294.799Z"
        fill="#FFA32A"
      />
      <path
        d="M41.0892 77.4553L48.9835 76.7411C49.6667 76.5519 50.1975 77.1406 50.2921 77.4822L53.7394 84.6198C54.3648 85.5501 55.6734 86.2913 56.6983 86.0076C57.7232 85.7238 58.5589 84.7568 58.5222 83.2957L57.808 75.4014C57.6189 74.7182 58.2075 74.1874 58.5491 74.0928L65.6868 70.6455C66.6171 70.0201 67.3582 68.7115 67.0745 67.6866C66.7907 66.6617 65.8237 65.8259 64.3626 65.8626L56.4684 66.5769C55.7851 66.7661 55.2543 66.1773 55.1598 65.8357L51.7124 58.6981C51.087 57.7678 49.7784 57.0266 48.7535 57.3104C47.7286 57.5941 46.8929 58.5612 46.9296 60.0223L47.6438 67.9165C47.833 68.5998 47.2443 69.1306 46.9027 69.2251L39.765 72.6725C38.8347 73.2978 38.0936 74.6065 38.3774 75.6314C38.7557 76.9979 40.0643 77.7391 41.0892 77.4553Z"
        fill="#FFBB38"
      />
      <path
        d="M353.828 225.314L364.588 229.149C365.597 229.315 365.935 230.406 365.853 230.91L366.051 242.331C366.307 243.926 367.572 245.687 369.084 245.935C370.596 246.183 372.274 245.422 373.109 243.489L376.944 232.728C377.109 231.72 378.2 231.381 378.704 231.464L390.126 231.265C391.721 231.009 393.481 229.745 393.729 228.232C393.977 226.72 393.217 225.042 391.283 224.207L380.522 220.372C379.514 220.207 379.175 219.116 379.258 218.612L379.06 207.19C378.804 205.595 377.539 203.835 376.027 203.587C374.514 203.339 372.837 204.099 372.002 206.033L368.167 216.794C368.002 217.802 366.911 218.141 366.407 218.058L354.985 218.256C353.39 218.513 351.629 219.777 351.381 221.289C351.051 223.306 352.315 225.066 353.828 225.314Z"
        fill="#FFA32A"
      />
      <path
        d="M128.148 116.697C127.149 116.974 126.563 118.009 126.839 119.008C127.116 120.008 128.151 120.594 129.151 120.318C130.15 120.041 130.737 119.006 130.46 118.006C130.183 117.006 129.148 116.42 128.148 116.697Z"
        fill="#D6DCE8"
      />
      <path
        d="M192.797 163.896C191.797 164.173 191.211 165.208 191.488 166.208C191.765 167.207 192.799 167.794 193.799 167.517C194.799 167.24 195.385 166.205 195.108 165.205C194.831 164.206 193.797 163.62 192.797 163.896Z"
        fill="#D6DCE8"
      />
      <path
        d="M234.795 87.756C234.266 88.648 234.56 89.8004 235.452 90.33C236.344 90.8596 237.496 90.5657 238.026 89.6737C238.555 88.7817 238.261 87.6293 237.369 87.0997C236.477 86.5702 235.325 86.864 234.795 87.756Z"
        fill="#F9C256"
      />
      <path
        d="M174.186 51.756C173.656 52.648 173.95 53.8004 174.842 54.33C175.734 54.8596 176.887 54.5657 177.416 53.6737C177.946 52.7817 177.652 51.6293 176.76 51.0997C175.868 50.5702 174.715 50.864 174.186 51.756Z"
        fill="#D6DCE8"
      />
      <path
        d="M66.0687 134.447C65.5392 135.339 65.833 136.492 66.725 137.021C67.617 137.551 68.7694 137.257 69.299 136.365C69.8285 135.473 69.5347 134.321 68.6427 133.791C67.7507 133.262 66.5983 133.555 66.0687 134.447Z"
        fill="#F9C256"
      />
      <path
        d="M73.9437 208.311C73.4142 209.203 73.708 210.355 74.6 210.885C75.492 211.414 76.6444 211.12 77.174 210.228C77.7035 209.336 77.4097 208.184 76.5177 207.654C75.6257 207.125 74.4733 207.419 73.9437 208.311Z"
        fill="#F9C256"
      />
      <path
        d="M94.4281 282.135C93.8985 283.027 94.1924 284.179 95.0844 284.709C95.9764 285.238 97.1288 284.945 97.6583 284.053C98.1879 283.161 97.8941 282.008 97.0021 281.479C96.1101 280.949 94.9576 281.243 94.4281 282.135Z"
        fill="#F9C256"
      />
      <path
        d="M143.983 319.861C143.453 320.754 143.747 321.906 144.639 322.435C145.531 322.965 146.683 322.671 147.213 321.779C147.743 320.887 147.449 319.735 146.557 319.205C145.665 318.676 144.512 318.969 143.983 319.861Z"
        fill="#F9C256"
      />
      <path
        d="M234.623 320.467C234.094 321.359 234.388 322.511 235.28 323.041C236.172 323.57 237.324 323.277 237.854 322.385C238.383 321.493 238.089 320.34 237.197 319.811C236.305 319.281 235.153 319.575 234.623 320.467Z"
        fill="#F9C256"
      />
      <path
        d="M297.686 264.197C297.156 265.089 297.45 266.242 298.342 266.771C299.234 267.301 300.387 267.007 300.916 266.115C301.446 265.223 301.152 264.071 300.26 263.541C299.368 263.012 298.215 263.305 297.686 264.197Z"
        fill="#F9C256"
      />
      <path
        d="M331.303 203.166C330.774 204.058 331.067 205.211 331.959 205.74C332.851 206.27 334.004 205.976 334.533 205.084C335.063 204.192 334.769 203.039 333.877 202.51C332.985 201.98 331.833 202.274 331.303 203.166Z"
        fill="#F9C256"
      />
      <path
        d="M293.35 109.455C292.82 110.347 293.114 111.5 294.006 112.029C294.898 112.559 296.051 112.265 296.58 111.373C297.11 110.481 296.816 109.329 295.924 108.799C295.032 108.269 293.88 108.563 293.35 109.455Z"
        fill="#F9C256"
      />
      <path
        d="M266.276 190.825L282.672 178.355L276.839 170.614L260.444 183.083L266.276 190.825Z"
        fill="#1B243C"
      />
      <path
        d="M373.938 142.015L336.595 92.418L260.562 150.277L297.905 199.874L373.938 142.015Z"
        fill="#EEF4FF"
        stroke="#1B243C"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M317.665 185.672L279.312 135.004"
        stroke="#1B243C"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M336.423 171.477L298.07 120.809"
        stroke="#1B243C"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M355.181 157.277L316.828 106.609"
        stroke="#1B243C"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M348.39 109.329L273.367 166.117"
        stroke="#1B243C"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M361.195 126.247L286.172 183.035"
        stroke="#1B243C"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M284.798 255.282C278.845 259.788 270.384 258.609 265.869 252.643L258.384 242.754C258.012 242.264 258.111 241.547 258.6 241.176L278.416 226.177C278.905 225.807 279.622 225.907 279.993 226.397L287.478 236.285C291.913 242.313 290.669 250.838 284.798 255.282Z"
        fill="#D5DDEA"
      />
      <path
        d="M237.941 153.722L199.695 182.672L252.276 252.136L252.338 252.218C252.338 252.218 252.338 252.218 252.399 252.299C254.584 255.016 264.817 250.742 275.336 242.779C285.856 234.816 292.666 226.19 290.726 223.288C290.726 223.288 290.726 223.288 290.664 223.206L290.603 223.125L238.022 153.661L237.941 153.722Z"
        fill="#EF8903"
      />
      <path
        d="M256.803 178.649L271.402 197.935L271.464 198.017L271.526 198.099C273.548 200.939 266.655 209.627 256.136 217.59C245.616 225.552 235.465 229.765 233.199 227.11L233.137 227.028L233.075 226.947L218.477 207.66C220.661 210.377 230.894 206.102 241.413 198.14C251.933 190.177 258.763 181.407 256.803 178.649Z"
        fill="#0A142D"
      />
      <path
        d="M239.329 121.606C249.249 134.774 241.488 157.497 221.963 172.346C202.438 187.194 178.481 188.593 168.56 175.425C168.5 175.345 168.44 175.266 168.38 175.186C168.32 175.106 168.2 174.946 168.14 174.867C164.966 169.623 177.733 153.888 196.929 139.29C216.125 124.692 234.724 116.698 238.825 121.11C238.886 121.19 239.006 121.35 239.066 121.429C239.148 121.367 239.208 121.447 239.329 121.606Z"
        fill="#E3C295"
      />
      <path
        d="M238.717 121.16L238.777 121.24C239.893 122.89 239.285 125.849 237.207 129.426C233.029 136.721 223.104 147.007 210.221 156.794C197.337 166.58 184.695 173.435 176.646 175.428C172.652 176.464 169.649 176.247 168.369 174.722L168.309 174.642C168.249 174.563 168.189 174.484 168.129 174.404C164.969 169.187 177.686 153.533 196.806 139.009C215.926 124.485 234.452 116.532 238.538 120.922C238.598 121.001 238.657 121.081 238.717 121.16Z"
        fill="#AB8F6A"
      />
      <path
        d="M237.187 129.464L237.247 129.543C233.074 136.826 223.16 147.096 210.291 156.866C197.423 166.637 184.795 173.481 176.756 175.47L176.696 175.391C176.636 175.312 176.576 175.232 176.576 175.232C173.875 170.799 184.806 157.264 201.199 144.818C217.674 132.309 233.547 125.495 237.068 129.305C237.068 129.305 237.127 129.385 237.187 129.464Z"
        fill="#3D2609"
      />
      <path
        d="M207.499 145.365C207.782 145.915 206.413 147.594 204.403 149.121C202.31 150.712 200.33 151.58 199.902 151.012L186.216 129.537L190.151 126.546L207.377 145.203L207.499 145.365Z"
        fill="#304579"
      />
      <path
        d="M189.833 129.846C192.397 127.895 192.959 124.298 191.087 121.813C189.216 119.328 185.621 118.895 183.057 120.846C180.493 122.797 179.931 126.394 181.802 128.879C183.673 131.364 187.269 131.797 189.833 129.846Z"
        fill="#304579"
      />
      <path
        d="M152.093 310.237L114.75 260.641L190.783 202.781L228.126 252.378L152.093 310.237Z"
        fill="#EEF4FF"
        stroke="#1B243C"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M209.368 267.645L171.016 216.977"
        stroke="#1B243C"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M190.611 281.844L152.258 231.176"
        stroke="#1B243C"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M171.861 296.039L133.508 245.371"
        stroke="#1B243C"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M127.555 276.484L202.577 219.696"
        stroke="#1B243C"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M140.359 293.402L215.382 236.614"
        stroke="#1B243C"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M222.372 211.906L205.977 224.375L211.809 232.117L228.205 219.648L222.372 211.906Z"
        fill="#1B243C"
      />
      <path
        d="M105.209 75.2477C104.569 79.5121 101.797 87.8278 96.8929 89.1071C96.6797 89.1071 96.4664 89.1071 96.2532 89.3204C91.5623 89.96 86.2318 86.7617 82.8202 84.4162C81.1145 83.1369 79.4087 81.6444 77.9161 80.1518C77.2765 79.5121 76.4236 78.6593 75.7839 78.0196C70.8798 72.689 67.0418 66.0792 64.6964 59.0429C64.2699 57.5503 63.8435 56.0577 64.0567 54.5652C64.0567 54.1387 64.2699 53.9255 64.2699 53.7123C64.6964 52.6462 65.5493 51.5801 66.8286 51.3669C67.8947 51.1536 68.7476 51.5801 69.8137 51.7933C70.8798 52.2198 71.7327 52.6462 72.7988 53.2859C70.4534 50.514 69.8137 45.8231 69.8137 42.4116C69.8137 42.1983 69.8137 41.7719 70.0269 41.7719C70.2401 41.5587 70.8798 41.7719 71.093 41.9851C74.5046 44.5438 74.5046 49.2346 75.9971 53.0726C75.9971 50.3008 76.2104 47.5289 76.2104 44.757C76.2104 42.838 76.4236 40.4926 77.9161 39.2132C78.1294 39 78.3426 39 78.5558 39C78.9822 39 79.1955 39.4264 79.4087 39.6397C81.9674 44.1173 80.4748 49.6611 80.9012 54.7784C81.1145 56.6974 81.3277 58.6164 81.7541 60.5354C81.9674 61.3883 82.3938 63.3073 82.8202 65.2263C83.2467 66.0792 83.6731 66.9321 84.3128 67.7849C84.526 68.2114 84.9525 68.6378 85.3789 68.8511C86.0186 69.4907 86.8715 70.1304 87.7243 70.7701C89.4301 71.6229 91.1359 72.2626 93.0549 72.689C96.4664 73.3287 99.878 73.3287 103.29 73.5419C103.929 73.5419 104.569 73.7552 104.995 74.1816C105.209 74.608 105.209 74.8213 105.209 75.2477Z"
        fill="#79A5EE"
      />
      <path
        d="M150.103 145.391C145.625 145.391 140.934 143.898 137.523 141.766C130.913 137.501 126.861 131.105 123.023 125.348L130.486 120.444C133.898 125.561 137.309 131.105 142.213 134.09C146.051 136.649 152.235 137.501 155.646 134.516C159.911 130.678 158.845 123.429 157.139 118.098C155.007 111.488 150.956 105.731 146.051 102.107L151.382 95.0703C157.992 99.9744 163.109 107.224 165.668 115.54C169.293 126.84 168.013 135.796 161.617 141.339C158.418 144.111 154.154 145.391 150.103 145.391Z"
        fill="#38425B"
      />
      <path
        d="M159.274 74.603C159.487 77.8013 158.421 80.9997 157.142 84.198C152.238 96.9913 147.12 109.785 141.15 122.151C139.871 124.497 138.805 127.056 137.525 129.401C135.82 132.813 133.687 136.651 129.849 137.503C126.651 138.143 123.666 136.651 120.894 135.158C120.041 134.732 119.188 134.092 118.762 133.239C118.335 132.386 118.549 131.32 118.762 130.467C121.32 117.674 126.225 105.733 131.129 93.5798C133.474 87.6096 136.033 81.4261 137.952 75.2427C139.444 70.9783 140.51 65.4345 146.054 65.008C151.172 64.7948 158.848 68.846 159.274 74.603Z"
        fill="#1A2234"
      />
      <path
        d="M99.7839 112.769C99.7839 113.195 99.7839 113.409 99.7839 113.835C99.5707 116.607 98.931 119.166 97.4385 121.511C95.7327 124.07 93.174 126.202 90.1889 126.842C89.5493 127.055 88.9096 127.055 88.2699 127.055C85.498 127.268 82.7262 126.415 80.1675 125.349C76.9692 124.07 73.7708 122.577 70.1461 122.364C68.2271 122.151 66.3081 122.364 64.3891 122.79C63.323 123.004 62.4701 123.43 61.404 123.643C60.3379 124.07 59.2717 124.496 58.2056 124.923C54.5809 126.628 50.9561 128.974 47.3313 130.466C44.3462 131.746 40.7214 132.385 38.1628 130.466C36.8835 129.614 36.2438 128.121 35.6041 126.842C31.9793 120.019 28.781 113.195 25.5827 106.159C25.3695 105.946 25.1562 105.519 25.1562 105.093C25.1562 104.027 26.4356 103.174 27.2885 102.748C27.5017 102.534 27.7149 102.534 27.9281 102.321C30.2736 101.255 32.4058 100.402 34.9645 99.9757C37.7363 99.7625 40.7214 100.189 42.6404 102.108C43.9198 103.387 44.7727 104.88 45.6255 106.586C46.4784 108.505 47.5445 110.424 48.3974 112.343C50.9561 110.21 53.5148 108.078 56.2866 106.159C57.1395 105.519 57.9924 105.093 58.8453 104.667C60.3379 103.814 62.0436 102.961 63.7494 102.534C66.5213 101.468 69.5064 101.042 72.4915 100.829C73.9841 100.615 75.4766 100.402 76.9692 100.615C78.6749 100.615 80.3807 101.042 81.8733 101.255C85.9245 102.108 90.1889 102.961 94.2401 103.814L89.7625 103.387C93.3872 104.24 97.6517 105.519 99.1442 108.931C99.7839 109.997 99.7839 111.49 99.7839 112.769Z"
        fill="#88AEE0"
      />
      <path
        d="M84.002 102.537C82.5094 108.294 83.7888 114.477 87.4135 119.381C87.6268 119.594 87.84 120.021 88.2664 119.808C88.4796 119.808 88.4796 119.594 88.6929 119.381C89.3325 117.889 89.5457 116.183 89.759 114.69C90.1854 111.066 91.4647 105.309 90.1854 101.684C88.6929 98.2723 84.6416 99.7648 84.002 102.537Z"
        fill="#38425B"
      />
      <path
        d="M110.442 61.5951C108.736 63.9405 106.604 65.8595 104.685 67.9917C93.3845 80.1454 88.054 96.9899 84.8556 113.408C84.0028 117.672 83.5763 122.363 86.135 125.562C87.4143 127.054 89.1201 128.12 90.8259 128.973C93.5977 130.466 96.1564 131.958 98.9283 133.451C102.127 135.157 105.538 137.076 109.163 137.289C113.427 137.502 117.692 135.37 120.89 132.385C124.088 129.4 126.221 125.562 128.353 121.724C133.683 111.915 138.588 101.894 142.852 91.6594C144.131 88.6743 145.411 85.4759 145.837 82.2776C146.477 77.5867 145.411 72.8958 144.558 68.4182C144.345 67.3521 144.131 66.0727 143.278 65.2198C142.212 64.1537 140.507 64.1537 139.227 64.367C129.845 64.7934 120.25 65.6463 111.295 63.5141C109.376 63.0876 107.244 62.448 106.178 60.7422"
        fill="#FCFDFF"
      />
      <path
        d="M64.3915 123.003C63.3254 123.216 62.4725 123.642 61.4064 123.856C61.1932 120.871 60.7667 117.459 59.9138 114.261C59.0609 111.489 57.9948 108.93 56.2891 106.371C57.1419 105.732 57.9948 105.305 58.8477 104.879C60.5535 107.651 61.8328 110.423 62.6857 113.408C63.5386 116.606 64.1783 120.018 64.3915 123.003Z"
        fill="#38425B"
      />
      <path
        d="M93.3819 118.741C92.529 120.873 92.9554 118.102 92.7422 120.66C92.7422 123.006 93.3818 125.351 94.6612 127.483C97.2198 131.108 101.698 133.24 105.962 134.733C109.8 136.012 113.851 136.652 117.689 134.733C122.38 132.387 126.005 126.844 125.578 121.513C125.152 114.477 116.836 110.852 110.653 110.212C102.977 109.359 99.1388 112.131 93.1686 116.609V118.741H93.3819Z"
        fill="#38425B"
      />
      <path
        d="M152.876 51.1482C152.876 51.3614 152.663 51.7879 152.663 52.0011C151.597 56.692 150.318 61.1696 149.891 66.0737C149.678 68.2059 150.104 72.0439 147.972 73.5365C146.266 74.6026 143.281 73.9629 141.362 73.9629C135.392 73.5365 129.635 72.4704 123.452 72.4704C123.025 72.4704 122.599 72.4704 121.959 72.4704C119.827 72.2572 117.908 71.4043 116.202 70.3382C113.857 69.2721 111.724 67.9927 109.379 67.1398C107.46 66.5002 105.328 66.0737 104.262 64.368C102.982 62.6622 102.982 60.53 102.769 58.611C102.13 52.2143 102.343 45.3912 104.049 39.2078C105.115 35.3698 107.247 31.5318 110.445 28.7599C117.481 22.3632 128.782 21.0839 137.524 25.3484C143.921 28.5467 149.465 34.0904 152.023 40.9135C153.09 43.8987 153.516 47.5234 152.876 51.1482Z"
        fill="#E1EAF9"
      />
      <path
        d="M152.87 51.1487C152.87 51.3619 152.657 51.7884 152.657 52.0016C151.591 56.6925 150.312 61.1701 149.885 66.0742C149.672 68.2065 150.098 72.0444 147.966 73.537C146.26 74.6031 143.275 73.9634 141.356 73.9634C135.386 73.537 129.629 72.4709 123.446 72.4709C123.019 72.4709 122.593 72.4709 121.953 72.4709C124.512 71.4048 127.284 71.4048 130.056 70.9783C138.798 69.4858 146.474 63.3024 149.885 55.1999C150.951 52.6413 151.591 49.8694 151.804 47.0975C152.017 44.9653 152.017 42.8331 151.591 40.9141C153.084 43.8992 153.51 47.5239 152.87 51.1487Z"
        fill="white"
        stroke="#38425B"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.685 68.847C131.34 70.9792 128.568 72.4718 125.583 72.4718C120.465 72.4718 115.135 71.4057 110.444 69.0602C108.951 68.2074 107.672 67.3545 106.393 66.5016C105.753 65.8619 104.9 65.2223 104.474 64.5826C102.981 62.8768 102.128 60.9578 101.702 58.6124C100.423 53.7083 101.275 47.7381 102.981 43.0472C104.9 38.1431 107.885 36.2241 113.003 36.0109C118.12 35.5844 124.09 37.2902 128.994 39.6356C129.208 39.8488 129.421 39.8488 129.634 40.0621C131.766 41.1282 133.685 42.4075 135.178 43.9001C142.427 50.5099 140.508 62.4504 133.685 68.847Z"
        fill="#38425B"
      />
      <path
        d="M122.38 125.136C122.38 125.349 122.38 125.349 122.38 125.563C122.38 126.629 122.167 127.482 121.741 128.335C120.248 132.386 115.984 134.944 111.719 136.224C107.455 137.503 102.977 137.716 98.926 138.996C95.5145 140.062 92.1029 141.554 89.5443 143.9C88.9046 144.539 88.2649 144.966 87.8385 145.606C87.8385 145.819 87.6253 145.819 87.6253 146.032C82.9344 151.789 81.655 160.105 76.1113 165.222C75.6848 165.648 75.2584 165.862 74.8319 166.075C74.4055 166.075 73.9791 165.862 73.5526 165.862C65.6634 162.45 58.4139 158.186 51.1643 153.708C50.0982 153.068 48.8189 152.215 48.1792 151.149C47.7528 150.296 47.7528 149.443 48.1792 148.591C49.2453 145.392 53.9362 141.554 56.0684 140.701C58.4139 139.849 61.1857 140.062 63.5312 140.915C65.8766 141.768 68.2221 143.26 70.1411 144.753C70.5675 141.768 71.4204 138.569 72.6997 135.797C73.1262 134.944 73.5526 134.092 73.9791 133.239C74.4055 132.386 75.0452 131.746 75.4716 130.893C76.7509 128.974 78.4567 127.268 80.1625 125.776C82.5079 123.644 85.0666 121.725 87.6253 120.019C91.4632 117.46 95.5145 115.115 99.7789 114.049C100.419 113.835 101.058 113.835 101.698 113.622C105.962 112.769 110.653 113.409 114.704 115.115C117.05 115.968 119.182 117.46 120.675 119.379C121.741 120.872 122.38 123.004 122.38 125.136Z"
        fill="#88AEE0"
      />
      <path
        d="M89.3344 143.899C88.6948 144.539 88.0551 144.965 87.6287 145.605C87.6287 145.818 87.4154 145.818 87.4154 146.032C84.8568 142.833 81.8717 140.275 78.4601 138.356C76.9676 137.503 75.2618 136.863 73.556 136.223C73.3428 136.01 72.9163 136.01 72.7031 135.797C73.1296 134.944 73.556 134.091 73.9825 133.238C74.1957 133.238 74.4089 133.451 74.6221 133.451C76.3279 134.091 78.2469 134.731 79.9527 135.797C83.3642 137.716 86.7758 140.488 89.3344 143.899Z"
        fill="#38425B"
      />
      <path
        d="M186.444 88.6785C186.444 88.8917 186.444 89.3182 186.231 89.5314C185.591 91.8769 183.246 93.1562 180.9 93.5826C180.261 93.7958 179.408 93.7958 178.768 93.7958C177.062 94.0091 175.57 94.0091 173.864 94.4355C172.158 94.862 170.452 95.9281 168.747 96.781C167.041 97.6338 165.122 98.2735 163.416 98.6999C162.35 98.9132 161.284 99.1264 160.218 99.3396C155.527 99.9793 150.836 99.5528 146.358 98.0603C143.586 97.2074 141.454 94.862 140.175 91.8768C138.256 87.186 138.682 81.2158 143.16 78.4439C148.917 74.8191 152.542 79.9364 157.233 82.0686C158.085 82.4951 158.938 82.7083 159.791 82.9215C160.857 83.1348 161.923 83.1348 162.99 83.1348C165.335 82.9215 167.68 82.2819 169.813 81.2158C173.651 79.2968 177.062 76.3117 180.474 73.5398C181.113 73.1133 181.753 72.4737 182.606 72.6869C183.459 72.9001 183.885 73.9662 183.885 75.0323V75.2455C183.672 76.0984 183.246 76.9513 183.032 77.8042C182.606 81.6422 186.87 85.0538 186.444 88.6785Z"
        fill="#79A5EE"
      />
      <path
        d="M184.859 97.4189L169.081 19.8062C168.868 18.9533 169.507 18.3136 170.147 18.1004C171 17.8872 171.64 18.5269 171.853 19.1665L187.631 96.7793C187.844 97.6322 187.205 98.2718 186.565 98.4851C185.925 98.6983 185.073 98.0586 184.859 97.4189Z"
        fill="white"
        stroke="#3B4765"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M189.762 86.9707C189.549 86.3311 188.909 85.9046 188.27 85.9046L188.909 85.6914C189.976 85.2649 190.402 83.9856 189.976 82.9195C189.549 82.0666 188.483 81.6402 187.63 81.8534L188.057 81.6402C189.123 81.2137 189.549 79.9344 189.123 78.8683C188.909 78.2286 188.483 78.0154 187.843 77.8022C188.696 77.1625 189.123 76.0964 188.696 75.0303C188.27 73.9642 186.99 73.5378 185.924 73.9642L178.675 77.3757C177.609 77.8022 177.182 79.0815 177.609 80.1476C177.822 80.7873 178.248 81.0005 178.888 81.2137C178.035 81.8534 177.609 82.9195 178.035 83.9856C178.462 84.8385 179.528 85.265 180.381 85.0517L179.741 85.265C178.675 85.6914 178.248 86.9707 178.675 88.0368C178.888 88.6765 179.528 89.1029 180.167 89.1029C179.314 89.7426 179.101 90.8087 179.528 91.6616C179.954 92.7277 181.233 93.1542 182.3 92.7277L188.696 89.7426C189.762 89.3162 190.189 88.2501 189.762 86.9707Z"
        fill="#657796"
      />
      <path
        opacity="0.4"
        d="M125.793 48.1642C124.94 49.4435 123.234 49.87 121.955 49.2303C121.528 49.0171 120.889 48.8039 120.462 48.5906C114.492 46.2452 108.095 47.7377 106.39 52.2154C105.963 53.2815 104.471 53.0683 104.471 52.0022C104.257 50.5096 104.471 48.8039 105.11 47.3113C107.242 41.5543 114.279 38.9957 120.889 41.5543C122.594 42.194 124.087 43.2601 125.366 44.3262C126.432 45.3923 126.432 46.8849 125.793 48.1642Z"
        fill="#D3D0E3"
      />
      <path
        opacity="0.4"
        d="M105.849 80.9564C110.114 83.0886 118.856 85.8605 124.186 86.2869C125.252 86.5001 125.679 87.353 125.466 88.4191C121.841 96.948 118.856 105.477 117.79 108.675C117.576 109.528 116.724 109.955 116.084 109.741C112.246 108.675 101.585 105.69 97.3204 103.984C96.4675 103.771 96.2543 102.918 96.4675 102.065C100.092 93.11 102.651 85.2208 103.717 81.8092C104.144 80.9564 105.21 80.5299 105.849 80.9564Z"
        fill="#102455"
      />
      <path
        d="M163.753 98.0574C162.687 98.2706 161.621 98.4838 160.554 98.6971C158.849 96.1384 157.783 92.7268 157.996 89.3153C158.209 87.3963 158.849 84.6244 160.128 82.4922C161.194 82.7054 162.26 82.7054 163.326 82.7054C162.047 84.6244 160.981 87.3963 160.768 89.5285C160.554 92.7268 161.834 96.1384 163.753 98.0574Z"
        fill="#38425B"
      />
      <path
        d="M88.0544 70.3386C84.2164 72.8972 80.8048 76.0956 77.8197 79.7203C77.1801 79.0807 76.3272 78.2278 75.6875 77.5881C78.4594 74.1766 81.8709 71.1915 85.4957 68.6328L88.0544 70.3386Z"
        fill="#38425B"
      />
      <g filter="url(#filter0_d_2868_169658)">
        <path
          d="M226.648 47.7373C206.605 41.1274 198.289 66.2876 177.82 55.6265L170.57 20.2317C192.745 29.6135 201.274 8.29128 219.398 12.3425L226.648 47.7373Z"
          fill="url(#paint0_linear_2868_169658)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2868_169658"
          x="148.57"
          y="0.824219"
          width="100.078"
          height="90.4727"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2868_169658"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2868_169658"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2868_169658"
          x1="198.591"
          y1="10.7493"
          x2="198.591"
          y2="58.7969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DC2626" />
          <stop offset="0.9964" stopColor="#EF8903" />
        </linearGradient>
      </defs>
    </svg>
  );
};
