import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import { UserIcon } from '@heroicons/react/24/outline';
import {
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TextInput,
} from '@tremor/react';
import { Checkbox } from '../../../ui/component/checkbox';
import { RiSearchLine } from '@remixicon/react';
import { Switch } from '../../../ui/component/switch';

export default function PermissionsModal({
  open,
  setOpen,
  currentDepartment,
}: any) {
  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center ">
          <DialogPanel
            transition
            className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in "
          >
            <div className="bg-white px-6 pb-4 pt-2 ">
              <div className="flex flex-col">
                <div className="mb-2 mt-3 text-left">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold text-gray-900"
                  >
                    Permissions
                  </DialogTitle>
                </div>
                <TabGroup>
                  <TabList variant="solid" color="rose" className="w-full">
                    <Tab className="flex w-[50%] justify-center px-3 py-1">
                      Assigned
                    </Tab>
                    <Tab className="flex w-[50%] justify-center px-3 py-1">
                      Assign new
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <div className="flex flex-col">
                        {currentDepartment.permissions.map(
                          (permission: any) =>
                            permission.enabled && (
                              <div className="flex flex-row items-center justify-between">
                                <div className="flex flex-col">
                                  <p className="font-medium">
                                    {permission.name}
                                  </p>
                                  <p className="text-gray-400">
                                    {permission.description}
                                  </p>
                                </div>
                                <Switch
                                  className="border"
                                  color="rose"
                                  checked={permission.enabled}
                                  // onChange={(checked) => handlePermissionChange(permission.id, checked)}
                                />
                              </div>
                            ),
                        )}
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="flex flex-col gap-1">
                        {currentDepartment.permissions.map(
                          (permission: any) =>
                            !permission.enabled && (
                              <div className="flex flex-row items-center justify-between">
                                <div className="flex flex-col">
                                  <p className="font-medium">
                                    {permission.name}
                                  </p>
                                  <p className="text-gray-400">
                                    {permission.description}
                                  </p>
                                </div>
                                <Switch
                                  className="border"
                                  color="rose"
                                  checked={permission.enabled}
                                  // onChange={(checked) => handlePermissionChange(permission.id, checked)}
                                />
                              </div>
                            ),
                        )}
                      </div>
                    </TabPanel>
                  </TabPanels>
                </TabGroup>
              </div>
            </div>
            <div className="flex flex-row justify-end gap-3 px-6 pb-4">
              <button
                onClick={() => setOpen(false)}
                className=" rounded-lg  px-4 py-2 text-gray-900  hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                className=" gap-2 rounded-lg bg-red-600 px-4 py-2 text-white  hover:bg-red-500"
                // onClick={() => deleteFile(file.id)}
              >
                Save
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
