import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { WelcomeSection } from '../../WelcomeSection';
import { Stepper } from '../../Stepper';
import {
  OnboardingStep,
  Steps,
} from '../../../../../ui/layouts/OnboardingLayout';
import { Input } from '../../../../../ui/form/Input';
import { Select } from '../../../../../ui/form/Select';

import { UploadLogo } from '../../UploadLogo';
import { FooterSection } from '../../FooterSection';
import { OnboardingData } from '../../../../../types/onboardingTypes';
import onboardingProcess from '../../../../../network/onboardingProcess';

type Props = {
  currentStep: Steps;
  handleNext: () => void;
  handlePrevious: () => void;
  handleSkipForNow: () => void;
};

const employeesCountOptions = [
  {
    id: '1-50',
    label: 'Small Teams: 1-50 employees',
  },
  {
    id: '51-250',
    label: 'Medium Teams: 51-250 employees',
  },
  {
    id: '251-1,000',
    label: 'Large Teams: 251-1,000 employees',
  },
  {
    id: '1,001-5,000',
    label: 'Enterprise Teams: 1,001-5,000 employees',
  },
];

export function FirstStep({
  currentStep,
  handleNext,
  handlePrevious,
  handleSkipForNow,
}: Props) {
  const [formData, setFormData] = useState<Partial<OnboardingData>>({
    name: '',
    company_name: '',
    primary_contact_email: '',
    industry: '',
    number_of_employees: '',
    country: '',
  });
  const [industryOptions, setIndustryOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const formik = useFormik({
    initialValues: {
      name: formData.name || '',
      company_name: formData.company_name || '',
      primary_contact_email: formData.primary_contact_email || '',
      industry: formData.industry || '',
      number_of_employees: formData.number_of_employees || '',
      country: formData.country || '',
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup.string().required(),
      company_name: yup.string().required(),
      primary_contact_email: yup.string().required(),
      industry: yup.string().required(),
      number_of_employees: yup.string().required(),
      country: yup.string().required(),
    }),
    onSubmit: async (data) => {
      try {
        await onboardingProcess.onboardingProcess(
          OnboardingStep.InitialSetup,
          {
            ...data,
            import_method: 'manual'
          },
        );
        handleNext();
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    formik.handleSubmit(event);
  };

  function findError(fieldName: keyof typeof formik.initialValues) {
    return formik.touched[fieldName] && formik.errors[fieldName]
      ? formik.errors[fieldName]
      : undefined;
  }

  useEffect(() => {
    const isFormValid = Object.keys(formik.errors).length === 0;

    const isFormFilled = Object.values(formik.values).every(
      (value) => value !== '',
    );

    if (isFormValid && isFormFilled) {
      const updatedValues = Object.fromEntries(
        Object.entries(formik.values).filter(([key, value]) => value !== ''),
      );

      const updatedFormData = { ...formData, ...updatedValues };
      setFormData(updatedFormData);
    }
  }, [formik.errors, formik.values]);

  useEffect(() => {
    async function getInitialData() {
      const { data } = await onboardingProcess.getOnboardingData(
        OnboardingStep.InitialSetup,
      );
      const { data: industryOptions } =
        await onboardingProcess.getOnboardingIndustries();
      const { data: countriesOptions } =
        await onboardingProcess.getOnboardingCountries();

      setFormData({
        name: data?.data.name || '',
        company_name: data?.data.company_name || '',
        primary_contact_email: data?.data.primary_contact_email || '',
        industry: data?.data.industry || '',
        number_of_employees: data?.data.number_of_employees || '',
        country: data?.data.country || '',
      });
      setIndustryOptions(industryOptions);
      setCountryOptions(countriesOptions);
    }
    getInitialData();
  }, []);

  return (
    <>
      <div className="bg-center bg-no-repeat md:w-[40%] md:bg-form-image1 " />
      <div className="m-2 flex w-screen flex-col justify-between rounded-lg bg-white px-10 pt-8 md:w-[60%] ">
        <div>
          <WelcomeSection handlePrev={handlePrevious} />
          <Stepper currentStep={currentStep} />
          <p className="mt-4 text-lg font-semibold">
            We're excited to have you on board! Let's customize your Redstone HR
            experience to suit your business needs.
          </p>
          <form
            className="mt-3 h-[70vh] overflow-y-auto pr-2"
            onSubmit={handleSubmit}
          >
            <div>
              <label
                htmlFor="name"
                className="mb-2 block text-base font-medium"
              >
                Name
              </label>
              <Input
                type="text"
                placeholder="Your name"
                id="name"
                name="name"
                error={findError('name')}
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </div>
            <div className="mt-4">
              <label
                htmlFor="company_name"
                className="mb-2 block text-base font-medium"
              >
                Company name
              </label>
              <Input
                type="text"
                placeholder="Your company name"
                id="company_name"
                name="company_name"
                error={findError('company_name')}
                value={formik.values.company_name}
                onChange={formik.handleChange}
              />
            </div>
            <div className="mt-4">
              <label
                htmlFor="primary_contact_email"
                className="mb-2 block text-base font-medium"
              >
                Primary Contact Email
              </label>
              <Input
                type="email"
                placeholder="companyname@info.com"
                id="primary_contact_email"
                name="primary_contact_email"
                error={findError('primary_contact_email')}
                value={formik.values.primary_contact_email}
                onChange={formik.handleChange}
              />
            </div>
            <div className="mt-4">
              <label
                htmlFor="industry"
                className="mb-2 block text-base font-medium"
              >
                Industry
              </label>
              <Select
                id="industry"
                name="industry"
                value={formik.values.industry}
                onChange={formik.handleChange}
                error={findError('industry')}
              >
                <option>Select</option>
                {industryOptions.map((item: { id: number; name: string }) => (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </div>
            <div className="mt-4">
              <label
                htmlFor="number_of_employees"
                className="mb-2 block text-base font-medium"
              >
                Number of Employees
              </label>
              <Select
                id="number_of_employees"
                name="number_of_employees"
                value={formik.values.number_of_employees}
                onChange={formik.handleChange}
                error={findError('number_of_employees')}
              >
                <option>Select</option>
                {employeesCountOptions.map(
                  (item: { id: string; label: string }) => (
                    <option key={item.id} value={item.id}>
                      {item.label}
                    </option>
                  ),
                )}
              </Select>
            </div>
            <div className="mt-4">
              <label
                htmlFor="country"
                className="mb-2 block text-base font-medium"
              >
                Country/Region
              </label>
              <Select
                id="country"
                name="country"
                value={formik.values.country}
                onChange={formik.handleChange}
                error={findError('country')}
              >
                <option>Select</option>
                {countryOptions.map((item: { id: string; name: string }) => (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </div>
            {/* <div className="mt-4">
              <label htmlFor="log" className="mb-2 block text-base font-medium">
                Branding Options (optional)
              </label>
              <UploadLogo
                // value={formik.values.logo}
                onChange={(file) =>
                  formik.setFieldValue('logo', file ? file.name : '')
                }
              />
              {findError('logo') && (
                <p className="mt-1 text-sm text-red-500">{findError('logo')}</p>
              )}
            </div> */}
          </form>
        </div>
        <FooterSection
          nextHandle={handleSubmit}
          handleSkipForNow={handleSkipForNow}
        />
      </div>
    </>
  );
}
