import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import {
  OnboardingStep,
  Steps,
} from '../../../../../ui/layouts/OnboardingLayout';
import { ConnectToSystem } from '../../ConnetToSystems/inedx';
import { DownloadTemplate } from '../../DownloadTemplate';
import { FooterSection } from '../../FooterSection';
import { ManualSetUp } from '../../ManualSetUp';
import { Stepper } from '../../Stepper';
import { WelcomeSection } from '../../WelcomeSection';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@tremor/react';
import onboardingProcess from '../../../../../network/onboardingProcess';
import { Spinner } from '../../../../../ui/Spinner';

type Props = {
  currentStep: Steps;
  handleNext: () => void;
  handlePrevious: () => void;
  handleSkipForNow: () => void;
};

export function FourthStep({
  currentStep,
  handleNext,
  handlePrevious,
  handleSkipForNow,
}: Props) {
  const [users, setUsers] = useState([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);

  const { data: userData, isLoading } = useQuery(
    ['onboarding-users'],
    async () => {
      const { data } = await onboardingProcess.getOnboardingData(
        OnboardingStep.ImportUsers,
      );
      return data?.data?.users || [];
    }
  );

  useEffect(() => {
    if (userData) {
      setUsers(userData);
    }
  }, [userData]);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files) {
      setIsUploading(true);
      const formData = new FormData();
      formData.append('import_method', 'csv');
      formData.append('csvData', event.target.files[0]);

      try {
        const { data } = await onboardingProcess.uploadCsvFile(
          OnboardingStep.ImportUsers,
          formData,
        );
        if (data?.status === 'HANDLE_ONBOARDING_STEP_SUCCESS') {
          const { data: userData } = await onboardingProcess.getOnboardingData(
            OnboardingStep.ImportUsers,
          );
          setUsers(userData?.data.users);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <div className="bg-center bg-no-repeat md:w-[40%] md:bg-form-image4" />
      <div className="m-2 flex w-screen flex-col justify-between rounded-lg bg-white px-10 pt-8 md:w-[60%]">
        <div>
          <WelcomeSection handlePrev={handlePrevious} />
          <Stepper currentStep={currentStep} />
          <h3 className="my-4 text-lg font-semibold">
            Let's get your team set up in Redstone HR
          </h3>

          {isLoading ? (
            <Spinner className="my-8" />
          ) : (
            <div>
              <TabGroup>
                <TabList variant="solid" color="rose">
                  {/* <Tab className="text-md text-wrap px-3 py-1 ">
                    Download Template
                  </Tab> */}
                  <Tab className="text-md text-wrap px-3 py-1">Manual set up</Tab>
                </TabList>
                <TabPanels>
                  {/* <TabPanel>
                    <div className="pt-3">
                      <DownloadTemplate
                        users={users}
                        handleFileChange={handleFileChange}
                        fileInputRef={fileInputRef}
                        handleButtonClick={handleButtonClick}
                        isUploading={isUploading}
                      />
                    </div>
                  </TabPanel> */}
                  <TabPanel>
                    <div className="pt-3">
                      <ManualSetUp />
                    </div>
                  </TabPanel>
                </TabPanels>
              </TabGroup>
            </div>
          )}
        </div>
        <FooterSection
          handleSkipForNow={handleSkipForNow}
          nextHandle={handleNext}
        />
      </div>
    </>
  );
}
