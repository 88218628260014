import { useQuery } from "react-query";
import timesheets from "../../network/timesheets";


export function useGetTimesheets(){
  return useQuery({
    queryKey: ['get_timesheets'],
    queryFn: async () => {
      const { data }  = await timesheets.getTimesheets();
      return data;
    }  
  })
}