import axios from 'axios';

const API_HOST = process.env.REACT_APP_API_HOST;

async function getTimesheetsStatus() {
  const { data } = await axios.get(`${API_HOST}/v1/timesheet/stats`);
  return data;
}

async function getTimesheets() {
  const  { data }  = await axios.get(`${API_HOST}/v1/timesheet`);
  return data;
}

async function getTimesheetById (id: string){
  const  { data }  = await axios.get(`${API_HOST}/v1/timesheet/${id}`);
  return data;
}

async function makeDecision(body: any){
  const response = await axios.post(`${API_HOST}/v1/timesheet/decision`, body);
  return response
}

export default {
  getTimesheetsStatus,
  getTimesheets,
  getTimesheetById,
  makeDecision
}