import {
  BanknotesIcon,
  BuildingOffice2Icon,
  CalendarDaysIcon,
  ChartBarSquareIcon,
  ClipboardDocumentListIcon,
  ClockIcon,
  UserGroupIcon,
  UserMinusIcon,
} from '@heroicons/react/24/solid';
import {
  OnboardingStep,
  Steps,
} from '../../../../../ui/layouts/OnboardingLayout';
import { FooterSection } from '../../FooterSection';
import { Stepper } from '../../Stepper';
import { WelcomeSection } from '../../WelcomeSection';
import { useEffect, useState } from 'react';
import onboardingProcess from '../../../../../network/onboardingProcess';
import { WaitListModal } from './components/WaitListModal';

type Props = {
  currentStep: Steps;
  handleNext: () => void;
  handlePrevious: () => void;
  handleSkipForNow: () => void;
  features: string;
  setFeatures: (value: string) => void;
};

export function SecondStep({
  currentStep,
  handleNext,
  handlePrevious,
  handleSkipForNow,
  features,
  setFeatures,
}: Props) {
  const [options, setOptions] = useState<
    {
      type: string;
      title: string;
      description: string;
      id: number;
      icon: any;
      status: string | boolean;
    }[]
  >([]);
  const [isShownWaitListModal, setIsShownWaitListModal] = useState(false);

  useEffect(() => {
    async function getOptions() {
      const { data } = await onboardingProcess.getOnboardingFeaturesData();
      const optionsData = data?.data.map((item: { type: string }) => {
        switch (item.type) {
          case 'approval-workflow':
            return {
              ...item,
              icon: <ClockIcon className="w-5" color="blue" />,
            };
          case 'absence-management':
            return {
              ...item,
              icon: <UserMinusIcon className="w-5" color="blue" />,
            };
          case 'employee-self-service':
            return {
              ...item,
              icon: <UserGroupIcon className="w-5" color="blue" />,
            };
          case 'timesheets':
            return {
              ...item,
              icon: <ClipboardDocumentListIcon className="w-5" color="blue" />,
            };

          case 'rota-management':
            return {
              ...item,
              icon: <CalendarDaysIcon className="w-5" color="blue" />,
            };

          case 'reporting':
            return {
              ...item,
              icon: <ChartBarSquareIcon className="w-5" color="blue" />,
            };
          case 'payroll-integration':
            return {
              ...item,
              icon: <BanknotesIcon className="w-5" color="blue" />,
            };
          case 'compliance-management':
            return {
              ...item,
              icon: <BuildingOffice2Icon className="w-5" color="blue" />,
            };
        }
      });
      setOptions(optionsData);
    }
    getOptions();
  }, []);

  const handleSubmit = async () => {
    if (features.length > 0) {
      try {
        await onboardingProcess.onboardingProcess(
          OnboardingStep.FeatureSelection,
          { features },
        );
        handleNext();
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    async function getInitialData() {
      const { data } = await onboardingProcess.getOnboardingData(
        OnboardingStep.FeatureSelection,
      );
      setFeatures(data?.data.features || '');
    }
    getInitialData();
  }, []);

  return (
    <>
      <div className="bg-center bg-no-repeat md:w-[40%] md:bg-form-image2 " />
      <div className="m-2 flex w-screen flex-col justify-between rounded-lg bg-white px-10 pt-8 md:w-[60%]">
        <div>
          <WelcomeSection handlePrev={handlePrevious} />
          <Stepper currentStep={currentStep} />

          <h3 className="my-4 text-lg font-semibold">
            Tell us which feature you'd like to use so we can tailor your setup
            experience
          </h3>

          <div className="grid grid-cols-2 gap-3">
            {options.map(
              (item: {
                id: number;
                type: string;
                title: string;
                description: string;
                icon: any;
                status: string | boolean;
              }) => (
                <div
                  onClick={() => {
                    item.status === 'disabled'
                      ? setIsShownWaitListModal(true)
                      : setFeatures(item.type);
                  }}
                  key={item.id}
                  className={`
                              cursor-pointer rounded-lg border-[1px] border-[#DBEAFE] px-3 py-2 hover:bg-[#DBEAFE] 
                              ${
                                features.includes(item.type)
                                  ? 'bg-[#DBEAFE]'
                                  : null
                              }
                              ${
                                item.status === 'disabled'
                                  ? 'bg-gray-100'
                                  : null
                              }`}
                >
                  <div className="flex flex-row gap-2">
                    {item.icon}
                    <h4 className="font-semibold">{item.title}</h4>
                  </div>
                  <p className="text-gray-500">{item.description}</p>
                </div>
              ),
            )}
          </div>
        </div>
        <FooterSection
          handleSkipForNow={handleSkipForNow}
          nextHandle={handleSubmit}
        />
      </div>
      <WaitListModal
        isOpen={isShownWaitListModal}
        onClose={() => setIsShownWaitListModal(false)}
      />
    </>
  );
}
