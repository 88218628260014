import { Icon, TextInput } from '@tremor/react';
import { RiSearchLine } from '@remixicon/react';
import {
  FunnelIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';
import { RiArrowLeftLine } from '@remixicon/react';
import FolderDetailsFilesTable from './FolderDetailsFilesTable';
import RenameModal from './RenameModal';
import { useState } from 'react';
import folderManagement from '../../../network/folderManagement';
import filesManagement from '../../../network/filesManagement';

export default function FolderDetails({
  folder,
  setShowFolderDetails,
  refetchFolder,
  refetchFolders,
  refetchFiles,
}: {
  folder: any;
  setShowFolderDetails: any;
  refetchFolder: any;
  refetchFolders: any;
  refetchFiles: any;
}) {
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [isOpenDeleteFileModal, setIsOpenDeleteFileModal] = useState(false);
  const [isOpenRenameModal, setIsOpenRenameModal] = useState(false);

  const handleRenameFolder = async (id: string, newName: string) => {
    newName.length > 0 &&
      (await folderManagement
        .updateFolder(id, newName)
        .then(() => {
          refetchFolder(id);
          refetchFolders();
          setOpenRenameModal(false);
        })
        .catch((error) => console.log(error)));
  };

  const handleDeleteFolder = async () => {
    await folderManagement
      .deleteFolder(folder.id)
      .then(() => {
        refetchFolders();
        refetchFiles();
        setShowFolderDetails(false);
      })
      .catch((error) => console.log(error));
  };

  const deleteFile = async (id: string | number) => {
    await filesManagement
      .deleteDocumentById(id)
      .then(() => {
        refetchFolder(folder.id);
        refetchFolders();
        setIsOpenDeleteFileModal(false);
      })
      .catch((error) => console.log(error));
  };

  const renameFile = async (id: string | number, newName: string) => {
    newName.length > 0 &&
      (await filesManagement.updateDocument(id, newName).then(() => {
        refetchFolder(folder.id);
        refetchFolders();
        setIsOpenRenameModal(false);
      }));
  };

  const handleDeleteDocuments = async (ids: string[]) => {
    await filesManagement
      .deleteDocuments(ids)
      .then(() => {
        refetchFolder(folder.id);
        refetchFolders();
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <div className="border-b border-gray-200 py-5">
        <h1 className="text-xl font-semibold text-gray-900">
          Document Management
        </h1>
      </div>
      <div className="mt-5 flex flex-row items-center justify-between">
        <button
          onClick={() => setShowFolderDetails(false)}
          className="mb-4 mt-3 flex gap-2 border-none bg-white text-gray-900 "
        >
          <RiArrowLeftLine className="w-5" />
          Back to Page
        </button>
        <div className="flex flex-row items-center gap-2">
          <TextInput
            icon={RiSearchLine}
            placeholder="Search by document here"
            className="w-auto lg:w-[400px]"
          />
          <button
            className="flex items-center 
            rounded-lg border-[1px] px-3 py-1 font-normal shadow"
          >
            <Icon icon={FunnelIcon} size="sm" color="blue" />
            Filter
          </button>
        </div>
      </div>
      <div className="mt-3 rounded-xl border-[1px] border-gray-100 px-4 pb-1 pt-1 shadow">
        <div className="mt-3 flex flex-row justify-between">
          <h5 className="font-semibold">{folder?.name}</h5>
          <div className="flex gap-3">
            <button
              onClick={() => setOpenRenameModal(true)}
              className="flex items-center gap-2 rounded-lg border-[1px] px-3 py-1 shadow"
            >
              <PencilSquareIcon className="w-4" />
              Rename
            </button>
            <button
              onClick={() => handleDeleteFolder()}
              className="flex items-center gap-2 rounded-lg bg-white px-4 py-1 text-[#DC2626] shadow"
            >
              <TrashIcon color="red" className="w-4" />
              Delete
            </button>
          </div>
        </div>
        {folder.files.length > 0 ? (
          <FolderDetailsFilesTable
            files={folder.files}
            isOpenDeleteFileModal={isOpenDeleteFileModal}
            setIsOpenDeleteFileModal={setIsOpenDeleteFileModal}
            deleteFile={deleteFile}
            isOpenRenameModal={isOpenRenameModal}
            setIsOpenRenameModal={setIsOpenRenameModal}
            renameFile={renameFile}
            handleDeleteDocuments={handleDeleteDocuments}
          />
        ) : (
          <div className="flex justify-center py-2">
            <p>No files</p>
          </div>
        )}
      </div>
      <RenameModal
        open={openRenameModal}
        setOpen={setOpenRenameModal}
        file={folder}
        renameFile={handleRenameFolder}
        rename={'folder'}
      />
    </div>
  );
}
