import { UserIcon } from '@heroicons/react/24/outline';

export function TimeawayTable({ myTimeaway }: any) {
  return (
    <>
      <div className="overflow-hidden rounded-lg border px-7 py-2 shadow-sm">
        <div className=" flow-root w-full">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <table className="w-full divide-y divide-gray-300">
                <thead>
                  <th
                    scope="col"
                    className=" flex items-center gap-3 py-3.5 pl-4 text-left text-sm font-semibold text-gray-500 sm:pl-0"
                  >
                    Employee
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3.5 text-left text-sm font-semibold text-gray-500"
                  >
                    Leave type
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3.5 text-left text-sm font-semibold text-gray-500"
                  >
                    Dates Requested
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3.5 text-left text-sm font-semibold text-gray-500"
                  >
                    Duration
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3.5 text-left text-sm font-semibold text-gray-500"
                  >
                    Approval status
                  </th>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {myTimeaway &&
                    myTimeaway?.map((user: any) => (
                      <tr key={user.id}>
                        <td className="whitespace-nowrap py-2 pl-4 text-sm font-medium text-gray-900 sm:pl-0">
                          <div className="flex items-center gap-2">
                            <UserIcon className="w-5" />
                            {user?.full_name}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm ">
                          {user?.type}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                          {user?.start} - {user?.end}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                          {user?.details}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm ">
                          {user.decision === 'pending' ? (
                            <div className="flex max-w-[90px] justify-center rounded-lg bg-[#FFEDD5] px-2 py-1 text-[#C2410C]">
                              Pending
                            </div>
                          ) : user.decision === 'approved' ? (
                            <p className="flex max-w-[90px] justify-center rounded-lg bg-[#DCFCE7] px-2 py-1 text-[#15803D]">
                              Approved
                            </p>
                          ) : user.decision === 'rejected' ? (
                            <div className="flex max-w-[90px] justify-center rounded-lg bg-[#FEE2E2] px-2 py-1 text-[#DC2626]">
                              Rejected
                            </div>
                          ) : (
                            <div className="flex max-w-[200px] justify-center text-wrap rounded-lg bg-[#FEF9C3] px-2 py-1 text-[#A16207]">
                              Awaiting employee updates
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
