import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@tremor/react';
import { TimeOffTab } from './components/TimeOffTab';
import { TimeAwayRoute } from '../TimeAway';
import { useNavigate } from 'react-router-dom';
import ElevenLabsWidget from '../../components/ElevenLabsWidget';

export default function TimeOffRoute() {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex items-center justify-between border-b-2 border-[#E4E4E7] pb-4">
        <h1 className="text-3xl font-bold  text-tremor-content-strong">
          Time Off Management
        </h1>

        {/* <button
          className="flex items-center gap-2 rounded-lg bg-gradient-to-r 
          from-[#F77B23] to-[#F69620] px-5 py-2 font-normal text-white"
        >
          <AiGenerateIcon />
          Redstone AI
        </button> */}
      </div>
      <div className="my-4">
        <TabGroup>
          <TabList variant="solid" color="rose">
            <Tab className="px-3 py-1">Requests</Tab>
            <Tab className="px-3 py-1">My Time Away</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <TimeOffTab />
            </TabPanel>
            <TabPanel>
              <TimeAwayRoute />
            </TabPanel>
          </TabPanels>
        </TabGroup>
        <ElevenLabsWidget isVisible={true} />
      </div>
    </>
  );
}
