import {
  EllipsisHorizontalIcon,
  TrashIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import { Icon } from '@tremor/react';
import { Pagination } from '../../../ui/component/pagination';
import { Checkbox } from '../../../ui/component/checkbox';
import { useState } from 'react';
import { DocumentArrowDownIcon } from '@heroicons/react/20/solid';
import { Menu, Popover, Position } from 'evergreen-ui';
import {
  AdjustmentsHorizontalIcon,
  EyeIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/solid';
import downloadFile from '../../../utils/downloadFile';
import DeleteFileModal from './DeleteFileModal';
import RenameModal from './RenameModal';

export default function FolderDetailsFilesTable({
  files,
  isOpenDeleteFileModal,
  setIsOpenDeleteFileModal,
  deleteFile,
  isOpenRenameModal,
  setIsOpenRenameModal,
  renameFile,
  handleDeleteDocuments,
}: any) {
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [currentFile, setCurrentFile] = useState({});

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedFiles(files.map((file: any) => file.id));
    } else {
      setSelectedFiles([]);
    }
  };

  const handleSelectRow = (fileId: string, checked: boolean) => {
    if (checked) {
      setSelectedFiles((prev) => [...prev, fileId]);
    } else {
      setSelectedFiles((prev) => prev.filter((id) => id !== fileId));
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const isAllSelected =
    files.length > 0 && selectedFiles.length === files.length;

  const handleDownloadFile = (id: string) => {
    try {
      downloadFile(id);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteFileModal = (file: any) => {
    setCurrentFile(file);
    setIsOpenDeleteFileModal((prev: boolean) => !prev);
  };

  const handleOpenRenameModal = (file: any) => {
    setCurrentFile(file);
    setIsOpenRenameModal((prev: boolean) => !prev);
  };

  const handleBulkDownload = () => {
    try {
      selectedFiles.forEach((item: string) => downloadFile(item));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="overflow-hidden">
      <div className="mt-1 flow-root w-full">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full  align-middle">
            <table className="w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className=" flex items-center gap-3 py-3.5 pl-4 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    <Checkbox
                      className="rounded-md border-[1px]"
                      color="rose"
                      checked={isAllSelected}
                      onChange={handleSelectAll}
                    />
                    {selectedFiles.length === 0 ? (
                      'File name'
                    ) : (
                      <div className="flex flex-row gap-2">
                        <button
                          onClick={() => handleDeleteDocuments(selectedFiles)}
                          className="flex items-center rounded-lg border-2 border-[#FDA29B] text-[#F04438]"
                        >
                          <Icon icon={TrashIcon} color="red" size="sm" />
                        </button>
                        <button
                          onClick={handleBulkDownload}
                          className="flex items-center rounded-lg border-[1px] border-gray-300 shadow-sm"
                        >
                          <Icon
                            icon={DocumentArrowDownIcon}
                            style={{ color: '#000' }}
                            size="sm"
                          />
                        </button>
                      </div>
                    )}
                  </th>
                  <th
                    scope="col"
                    className="self-center px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    File size
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Date uploaded
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Last update
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Owner
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">View</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 ">
                {files?.map((item: any) => (
                  <tr key={item.id}>
                    <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      <section className="flex items-center gap-2">
                        <Checkbox
                          className="rounded-md border-[1px]"
                          color="rose"
                          checked={selectedFiles.includes(item.id)}
                          onChange={(checked) =>
                            handleSelectRow(item.id, checked)
                          }
                        />
                        {item?.name}
                      </section>
                    </td>
                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                      {item?.size}
                    </td>
                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                      {formatDate(item?.date_uploaded)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                      {formatDate(item?.date_updated)}
                    </td>
                    <td className="whitespace-nowrap py-2 text-sm ">
                      <div className="flex flex-row items-center">
                        <Icon icon={UserCircleIcon} size="lg" color="gray" />
                        <section>
                          <h5 className="text-sm">{item.owner}</h5>
                          <p className="text-xs text-gray-500">{item.email}</p>
                        </section>
                      </div>
                    </td>
                    <td className="whitespace-nowrap py-2 text-sm font-medium sm:pr-0">
                      <Popover
                        position={Position.BOTTOM_RIGHT}
                        content={
                          <Menu>
                            <Menu.Group>
                              <Menu.Item>
                                <div
                                  role="button"
                                  onClick={() => handleDownloadFile(item.id)}
                                  className="flex flex-row flex-nowrap gap-2 text-gray-600"
                                >
                                  <DocumentArrowDownIcon
                                    className="w-4"
                                    color="gray"
                                  />
                                  Download file
                                </div>
                              </Menu.Item>
                              <Menu.Item>
                                <div
                                  role="button"
                                  onClick={() => handleOpenRenameModal(item)}
                                  className="flex flex-row flex-nowrap gap-2 text-gray-600"
                                >
                                  <PencilSquareIcon
                                    className="w-4"
                                    color="gray"
                                  />
                                  Rename
                                </div>
                              </Menu.Item>
                              <Menu.Item>
                                <div className="flex flex-row flex-nowrap gap-2 text-gray-600">
                                  <AdjustmentsHorizontalIcon
                                    className="w-4"
                                    color="gray"
                                  />
                                  Settings
                                </div>
                              </Menu.Item>
                              <Menu.Item>
                                <div className="flex flex-row flex-nowrap gap-2 text-gray-600">
                                  <EyeIcon className="w-4" color="gray" />
                                  View
                                </div>
                              </Menu.Item>
                              <Menu.Item>
                                <div
                                  role="button"
                                  onClick={() => handleDeleteFileModal(item)}
                                  className="flex flex-row flex-nowrap gap-2 text-[#B91C1C] text-gray-600"
                                >
                                  <TrashIcon className="w-4" color="red" />
                                  Remove
                                </div>
                              </Menu.Item>
                            </Menu.Group>
                          </Menu>
                        }
                      >
                        <EllipsisHorizontalIcon className="h-5 w-5 cursor-pointer" />
                      </Popover>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              limit={10}
              count={20}
              page={0}
              onPageChange={() => console.log()}
            />
          </div>
        </div>
      </div>
      <DeleteFileModal
        open={isOpenDeleteFileModal}
        setOpen={setIsOpenDeleteFileModal}
        file={currentFile}
        deleteFile={deleteFile}
      />
      <RenameModal
        open={isOpenRenameModal}
        setOpen={setIsOpenRenameModal}
        file={currentFile}
        renameFile={renameFile}
        rename={'document'}
      >
        By renaming a file, it will also change in all folders.
      </RenameModal>
    </div>
  );
}
