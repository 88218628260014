import axios from 'axios';

const API_HOST = process.env.REACT_APP_API_HOST;

async function getConfig(){
  const { data } = await axios.get(`${API_HOST}/v1/config`);
  return { data };
}

export default {
  getConfig,
}