import {
  OnboardingStep,
  Steps,
} from '../../../../../ui/layouts/OnboardingLayout';
import { Stepper } from '../../Stepper';
import { WelcomeSection } from '../../WelcomeSection';
import { FirstSubStep } from './SubSteps/FirstSubStep';
import { SecondSubStep } from './SubSteps/SecondSubStep';
import { ThirdSubStep } from './SubSteps/ThirdSubStep';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState, useEffect } from 'react';
import { useToast } from '../../../../../utils/useToast';
import onboardingProcess from '../../../../../network/onboardingProcess';

type Props = {
  setCurrentStep: (data: Steps) => void;
  currentStep: Steps;
  handleNext: () => void;
  handlePrevious: () => void;
  handleSkipForNow: () => void;
};

export function SixthStep({
  setCurrentStep,
  currentStep,
  handleNext,
  handlePrevious,
  handleSkipForNow,
}: Props) {
  const [currentSubStep, setCurrentSubStep] = useState<number>(1);
  const [visitedSubStep, setVisitedSubStep] = useState<number[]>([]);
  const steps = [1, 2, 3];

  const [isInvitationSent, setIsInvitationSent] = useState(false);
  const { toast } = useToast();

  const [formData, setFormData] = useState({
    status: '',
    subject: '',
    message: '',
  });

  const formik = useFormik({
    initialValues: {
      status: formData.status || '',
      subject: formData.subject || '',
      message: formData.message || '',
    },
    validationSchema: yup.object({
      subject: yup.string().required(),
      message: yup.string(),
    }),
    onSubmit: async (data) => {
      try {
        const pureData = {
          ...data,
          message: data.message.replace(/<\/?[^>]+(>|$)/g, ''),
        };
        
        await onboardingProcess.onboardingProcess(
          OnboardingStep.SendInvitationsMessageTemplate,
          pureData,
        );

        await onboardingProcess.onboardingProcess(
          OnboardingStep.SendInvitations,
          { status: 'in_progress' }
        );

        await onboardingProcess.onboardingProcess(
          OnboardingStep.SendInvitationsSummary,
          { status: 'completed' }
        );

        await onboardingProcess.onboardingProcess(
          OnboardingStep.Complete,
          { status: 'completed' }
        );

        setCurrentStep(Steps.Seventh);
        handleNext();
      } catch (error) {
        toast({
          variant: 'error',
          title: 'Something went wrong. Please try again later.',
        });
      }
    },
  });

  function findError(fieldName: keyof typeof formik.initialValues) {
    return formik.touched[fieldName] && formik.errors[fieldName]
      ? formik.errors[fieldName]
      : undefined;
  }

  useEffect(() => {
    const isFormValid = Object.keys(formik.errors).length === 0;

    const isFormFilled = Object.values(formik.values).every(
      (value) => value !== '',
    );

    if (isFormValid && isFormFilled) {
      const updatedValues = Object.fromEntries(
        Object.entries(formik.values).filter(([key, value]) => value !== ''),
      );

      const updatedFormData = { ...formData, ...updatedValues };
      setFormData(updatedFormData);
    }
  }, [formik.errors, formik.values]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    formik.setFieldValue('status', 'submitted');
    formik.handleSubmit(event);
  };

  const renderForm = (step: number) => {
    switch (step) {
      case 1:
        return <FirstSubStep />;
      case 2:
        return <SecondSubStep formik={formik} findError={findError} />;
      case 3:
        return <ThirdSubStep />;
      default:
        return <p>Invalid</p>;
    }
  };

  const handleNextSubStep = () => {
    setCurrentSubStep((prev) => prev + 1);
    setVisitedSubStep((prev) => [...prev, currentSubStep]);
  };

  const handlePervSubStep = () => {
    setCurrentSubStep((prev) => prev - 1);
    setVisitedSubStep((prev) => prev.filter((el) => el !== currentSubStep));
  };

  return (
    <>
      <div className="bg-center bg-no-repeat md:w-[40%] md:bg-form-image6" />
      <div className="m-2 flex w-screen flex-col justify-between rounded-lg bg-white px-10 pt-8 md:w-[60%]">
        <div>
          <WelcomeSection
            handlePrev={currentSubStep > 1 ? handlePervSubStep : handlePrevious}
          />
          <Stepper currentStep={currentStep} />
          <h3 className="my-4 text-lg font-semibold">
            Invite your team to start using Redstone HR. You can send
            invitations now or later from your dashboard
          </h3>
          <div className="mt-3 flex items-center justify-center space-x-4">
            {steps.map((step, index) => (
              <div key={index} className="flex items-center gap-4">
                <div
                  className={`flex h-8 w-8 items-center justify-center rounded-full border-2 ${
                    currentSubStep === step
                      ? 'border-[#B91C1C]'
                      : visitedSubStep.includes(step)
                      ? 'border-[#B91C1C] bg-[#DC2626] text-white'
                      : 'border-gray-300 '
                  }`}
                >
                  {step}
                </div>
                {index !== steps.length - 1 && (
                  <div
                    className={`h-[2px] w-36  ${
                      currentSubStep > step ? 'bg-[#B91C1C]' : 'bg-gray-300'
                    }`}
                  />
                )}
              </div>
            ))}
          </div>
          <div className="mt-3">{renderForm(currentSubStep)}</div>
        </div>
        <div className="mb-4 mt-5 flex flex-row justify-end gap-3 border-t border-tremor-border py-3">
          <button
            onClick={handleSkipForNow}
            className="rounded-lg border px-3 py-1 font-normal shadow"
          >
            Skip for Now
          </button>
          {currentSubStep < 3 ? (
            <button
              onClick={handleNextSubStep}
              className="rounded-lg bg-[#DC2626] px-3 py-1 font-normal text-white shadow"
            >
              Next step
            </button>
          ) : (
            <form onSubmit={handleSubmit}>
              <button
                type="button"
                onClick={() => {
                  handleNext();
                }}
                className="rounded-lg bg-[#DC2626] px-3 py-1 font-normal text-white shadow"
              >
                Next
              </button>
            </form>
          )}
        </div>
      </div>
    </>
  );
}
