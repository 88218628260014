import { useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import classnames from 'classnames';

import colors from 'tailwindcss/colors';
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/solid';

import { Event } from '../../../../types';
import {
  EVENT_TIME_TYPE__START,
  EVENT_TIME_TYPE__MIDDAY,
  EVENT_TIME_TYPE__END,
  EVENT_TYPES,
} from '../../../../constants';
import { Dialog } from '../../../../ui/Dialog';
import { Popover } from '../../../../ui/Popover';

interface IProps {
  events: Event[];
  today: Moment;
}

type DayEvent = {
  event: Event;
  offset: number;
};

type DayItem = null | DayEvent;

type Day = {
  date: string;
  events: DayEvent[];
  items: DayItem[];
};

function useDays(events: any[]): any[] {
  return useMemo(() => {
    const days: any = {};

    events.forEach((event) => {
      const start = moment(event.start.date).startOf('day');
      const end = moment(event.end.date).endOf('day');
      const diffDays = end.diff(start, 'days');

      for (let i = 0; i <= diffDays; i++) {
        const dateObj = start.clone().add(i, 'days');
        const dateStr = dateObj.format('YYYY-MM-DD');

        if (!days[dateStr]) {
          days[dateStr] = { date: dateObj, events: [], items: [] };
        }

        let offset = 0;

        if (i > 0) {
          const prevDateStr = start
            .clone()
            .add(i - 1, 'days')
            .format('YYYY-MM-DD');
          const prevDay = days[prevDateStr];
          const prevEvent = prevDay.events.find(
            (e: any) => e.event.id === event.id,
          );
          if (prevEvent) {
            offset = prevEvent.offset;
          } else {
            const usedOffsets = days[dateStr].events.map((e: any) => e.offset);
            while (usedOffsets.includes(offset)) {
              offset += 1;
            }
          }
        } else {
          const usedOffsets = days[dateStr].events.map((e: any) => e.offset);
          while (usedOffsets.includes(offset)) {
            offset += 1;
          }
        }

        days[dateStr].events.push({ event, offset });
      }
    });

    Object.values(days).forEach((day: any) => {
      const offsets = day.events.map((e: any) => e.offset);
      for (let i = 0; i <= Math.max(...offsets); i++) {
        if (offsets.includes(i)) {
          day.items.push(day.events.find((e: any) => e.offset === i));
        } else {
          day.items.push(null);
        }
      }
    });

    return Object.values(days);
  }, [events]);
}

function useDay(days: Day[], day: null | Moment) {
  return useMemo(() => {
    if (!day) {
      return {
        items: [],
        events: [],
      };
    }
    const foundDay = days.find(({ date }) => day.isSame(date, 'day'));
    const items = foundDay?.items || [];
    const events = (items.filter((item) => item !== null) as DayEvent[]).map(
      ({ event }) => event,
    );
    return { items, events };
  }, [days, day]);
}

export function DesktopMonthCalendarView({ events, today }: IProps) {
  const [openDay, setOpenDay] = useState<null | Moment>(null);

  const startDay = today.clone().startOf('month').startOf('week');
  const daysMap = [...Array(42)].map((_, index) =>
    startDay.clone().add(index, 'day'),
  );
  const sortedEvents = useMemo(
    () =>
      [...events].sort((a, b) =>
        moment(a.start.date).isBefore(b.start.date)
          ? -1
          : moment(a.start.date).isAfter(b.start.date)
          ? 1
          : 0,
      ),
    [events],
  );

  const firstDay = daysMap[0];
  const lastDay = daysMap[daysMap.length - 1];

  const monthEvents = useMemo(
    () =>
      sortedEvents.filter(
        ({ start, end }) =>
          moment(start.date).isBetween(firstDay, lastDay, undefined, '[]') ||
          moment(end.date).isBetween(firstDay, lastDay, undefined, '[]') ||
          (moment(start.date).isBefore(firstDay) &&
            moment(end.date).isAfter(lastDay)),
      ),
    [sortedEvents, firstDay, lastDay],
  );

  const days = useDays(sortedEvents);

  return (
    <>
      <div className="flex flex-auto flex-col shadow ring-1 ring-black ring-opacity-5">
        <div className="grid flex-none grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700">
          {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((name) => (
            <div key={name} className="bg-white py-2">
              {name.charAt(0)}
              <span className="sr-only sm:not-sr-only">{name.slice(1)}</span>
            </div>
          ))}
        </div>
        <div className="flex flex-auto bg-gray-200 text-xs leading-6 text-gray-700">
          <div className="grid w-full grid-cols-7 grid-rows-1 gap-px sm:grid-rows-6">
            {daysMap.map((day, index) => (
              <DayCell
                key={index}
                today={today}
                days={days}
                day={day}
                onDialogOpen={() => setOpenDay(day)}
              />
            ))}
          </div>
        </div>
      </div>
      {monthEvents.length > 0 && (
        <div className="px-4 py-5 sm:px-6 sm:py-7 lg:hidden">
          <UserEventItemList events={monthEvents} />
        </div>
      )}
      <DayDialog
        days={days}
        day={openDay}
        onClose={() => setOpenDay(null)}
        onDayChange={(day) => setOpenDay(day)}
      />
    </>
  );
}

function DayCell({
  today,
  days,
  day,
  onDialogOpen,
}: {
  today: Moment;
  days: Day[];
  day: Moment;
  onDialogOpen(): void;
}) {
  const { items } = useDay(days, day);
  const [item] = items;

  return (
    <div
      className={classnames(
        today.isSame(day, 'month') ? 'bg-white' : 'bg-gray-50 text-gray-500',
        'relative py-2',
      )}
      onClick={() => onDialogOpen()}
    >
      <div className="px-3">
        <time
          dateTime={day.toISOString()}
          className={classnames('font-medium', {
            'flex h-6 w-6 items-center justify-center rounded-full bg-red-600 font-semibold text-white':
              moment().isSame(day, 'day'),
          })}
        >
          {day.format('D')}
        </time>
      </div>
      <EventItemList items={items} day={day} />
    </div>
  );
}

function DayDialog({
  days,
  day,
  onClose,
  onDayChange,
}: {
  days: Day[];
  day: null | Moment;
  onClose(): void;
  onDayChange(day: Moment): void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { events } = useDay(days, day);

  useEffect(() => {
    if (day) {
      setIsOpen(true);
    }
  }, [day, setIsOpen]);

  function handleClose() {
    setIsOpen(false);
    setTimeout(() => onClose(), 200);
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      noActions
      panelClassName="sm:max-w-2xl"
      title={
        day && (
          <div className="flex flex-auto items-center justify-between space-x-4">
            <div className="flex">
              <span className="mr-2 font-bold">
                <span className="sm:hidden">{day.format('ddd D')}</span>
                <span className="hidden sm:inline">{day.format('dddd D')}</span>
              </span>
              <span className="font-normal">
                <span className="sm:hidden">{day.format('MMM YYYY')}</span>
                <span className="hidden sm:inline">
                  {day.format('MMMM YYYY')}
                </span>
              </span>
            </div>
            <div className="flex space-x-3 text-gray-400">
              <ChevronLeftIcon
                className="h-5 w-5 cursor-pointer hover:text-red-500"
                onClick={() => onDayChange(day.clone().add(-1, 'days'))}
              />
              <ChevronRightIcon
                className="h-5 w-5 cursor-pointer hover:text-red-500"
                onClick={() => onDayChange(day.clone().add(1, 'days'))}
              />
            </div>
          </div>
        )
      }
    >
      {day && <UserEventItemList events={events} className="-mt-4 sm:-mt-5" />}
    </Dialog>
  );
}

function EventItemList({ items, day }: { items: DayItem[]; day: Moment }) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [rootElement, setRootElement] = useState<null | HTMLElement>(null);
  const maxLength = 5; // Display first 5 items directly
  const shownItems = items.slice(0, maxLength);
  const hiddenItems = items.slice(maxLength).filter((item) => item !== null); // Filter out null items

  const moreBtnClassName = `more-btn--${day.format('YYYY-MM-DD')}`;

  return (
    <ol className="mt-2 space-y-1 leading-4 sm:leading-6">
      {shownItems.map((item, index) => (
        <EventItem key={index} index={index} item={item} day={day} />
      ))}
      {hiddenItems.length > 0 && (
        <li ref={setRootElement} className="flex justify-center px-1">
          <span
            className={classnames(
              moreBtnClassName,
              'mt-1 inline-block cursor-pointer truncate rounded border border-gray-300 px-1 pb-0.5 text-[11px] font-medium leading-3 leading-tight text-gray-400 sm:leading-4',
              'hover:border-current hover:bg-red-50 hover:text-red-500',
              { 'border-current bg-red-50 text-red-500': isPopoverOpen },
            )}
            onClick={(event) => {
              event.stopPropagation();
              setIsPopoverOpen(!isPopoverOpen);
            }}
          >
            <span>+{hiddenItems.length}</span>
            <span className="ml-1 hidden sm:inline">more</span>
          </span>
          <Popover
            refElement={rootElement}
            isOpen={isPopoverOpen}
            onClose={() => setIsPopoverOpen(false)}
            outsideClickOptions={{
              ignoreSelectors: [`.${moreBtnClassName}`, '.event-popover'],
            }}
          >
            <ol className="space-y-1 text-[12px] leading-6">
              {hiddenItems.map((item, index) => (
                <EventItem
                  key={index}
                  index={index}
                  item={item}
                  day={day}
                  inPopover
                />
              ))}
            </ol>
          </Popover>
        </li>
      )}
    </ol>
  );
}

function EventItem({
  index,
  item,
  day,
  inPopover = false,
}: {
  index: number;
  item: DayItem;
  day: Moment;
  inPopover?: boolean;
}) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [rootElement, setRootElement] = useState<null | HTMLElement>(null);

  if (inPopover && item === null) return null;

  const itemType =
    !item ||
    (!inPopover &&
      day.isoWeekday() > 1 &&
      !day.isSame(item.event.start.date, 'day'))
      ? 'BREAK'
      : day.isSame(item.event.start.date, 'day')
      ? 'START'
      : 'CONTINUE';

  if (!item || itemType === 'BREAK') {
    return (
      <li key={index}>
        <br />
      </li>
    );
  }

  const { event } = item;
  const eventType = EVENT_TYPES.find(({ title }) => title === event.type);
  const colorName = eventType ? eventType.colorName : 'gray';
  const durationDays =
    1 +
    moment(event.end.date)
      .endOf('day')
      .diff(moment(event.start.date).startOf('day'), 'days');
  const durationToEndDays =
    1 +
    moment(event.end.date)
      .endOf('day')
      .diff(day.clone().startOf('day'), 'days');
  const numCols =
    itemType === 'START'
      ? Math.min(durationDays, 8 - day.isoWeekday())
      : Math.min(7, durationToEndDays);
  const isStartedInWeek = day
    .clone()
    .startOf('week')
    .isSameOrBefore(event.start.date);
  const isEndedInWeek = day.clone().endOf('week').isSameOrAfter(event.end.date);
  const isStartedInDay = day.isSame(event.start.date, 'day');
  const isEndedInDay = day.isSame(event.end.date, 'day');
  const isStartedHere = !inPopover ? isStartedInWeek : isStartedInDay;
  const isEndedHere = !inPopover ? isEndedInWeek : isEndedInDay;

  return (
    <li
      key={index}
      ref={setRootElement}
      className="relative z-[1]"
      style={{
        width: inPopover
          ? '100%'
          : `calc(${100 * numCols}% + ${numCols - 1}px)`,
      }}
      onClick={(event) => event.stopPropagation()}
    >
      <div
        className={classnames(
          { 'rounded-l-sm': isStartedHere },
          { 'rounded-r-sm': isEndedHere },
          { 'ml-1': !inPopover && isStartedInWeek },
          { 'mr-1': !inPopover && isEndedInWeek },
          { '-ml-4 pl-[25px]': inPopover && !isStartedHere },
          { '-mr-4': inPopover && !isEndedHere },
          'flex cursor-pointer px-2 text-[11px] opacity-75 hover:opacity-100',
        )}
        style={{
          backgroundColor: colorName
            ? colors[colorName][100]
            : colors['red'][100],
          ...(isStartedHere && {
            borderLeft: `2px solid ${colors[colorName][600]}`,
          }),
        }}
        onClick={() => setIsPopoverOpen(true)}
      >
        <p
          className={`flex-auto truncate font-medium`}
          style={{
            color: colorName ? colors[colorName][800] : colors['red'][800],
          }}
        >
          {event.title}
        </p>
      </div>

      <Popover
        refElement={rootElement}
        isOpen={isPopoverOpen}
        className="event-popover"
        onClose={() => setIsPopoverOpen(false)}
        options={{
          placement: inPopover ? 'left' : 'bottom-start',
        }}
      >
        <div className="flex items-center space-x-4">
          <img
            alt=""
            src={event.userPictureURL}
            className="h-11 w-11 flex-none rounded-full bg-gray-100"
          />
          <div>
            <div className="font-medium">{event.userFullName}</div>
            <EventTypeBadge type={event.type} className="mb-1" />
            <EventDateRange
              start={event.start}
              end={event.end}
              startTimeOfDay={event.startTimeOfDay}
              endTimeOfDay={event.endTimeOfDay}
            />
          </div>
        </div>
      </Popover>
    </li>
  );
}

function UserEventItemList({
  events,
  className,
}: {
  events: Event[];
  className?: string;
}) {
  return (
    <ol
      className={classnames(
        'divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5',
        className,
      )}
    >
      {events.length ? (
        events.map((event, index) => (
          <UserEventItem key={index} event={event} />
        ))
      ) : (
        <div className="flex h-20 items-center justify-center text-base text-gray-400">
          No events
        </div>
      )}
    </ol>
  );
}

function UserEventItem({ event }: { event: Event }) {
  return (
    <li className="flex space-x-3 p-4 text-[14px]">
      <img
        alt=""
        src={event.userPictureURL}
        className="h-11 w-11 flex-none rounded-full bg-gray-100"
      />
      <div className="flex flex-grow flex-wrap justify-between">
        <div className="mb-1 mr-4 sm:mb-0">
          <p className="font-semibold text-gray-900">{event.userFullName}</p>
          <EventTypeBadge type={event.type} />
        </div>
        <div className="align-center flex">
          <EventDateRange
            start={event.start}
            end={event.end}
            startTimeOfDay={event.startTimeOfDay}
            endTimeOfDay={event.endTimeOfDay}
          />
          <div className="ml-6 hidden flex-none self-center rounded-md border border-gray-300 bg-white px-3 py-2 font-semibold text-gray-700 opacity-0 shadow-sm hover:bg-gray-50 focus:opacity-100 group-hover:opacity-100">
            Edit
            <span className="sr-only">, {event.userFullName}</span>
          </div>
        </div>
      </div>
    </li>
  );
}

function EventTypeBadge({
  type,
  className,
}: {
  type: Event['type'];
  className?: string;
}) {
  const eventType = EVENT_TYPES.find(({ title }) => title === type);
  const colorName = eventType ? eventType.colorName : 'gray';

  return (
    <div
      className={classnames(
        'mt-1.5 w-[140px] rounded-sm px-2 pb-1.5 pt-1 text-[13px] font-medium leading-none',
        className,
      )}
      style={{
        backgroundColor: colors[colorName][100],
        color: colors[colorName][800],
        borderLeft: `2px solid ${colors[colorName][600]}`,
      }}
    >
      {type}
    </div>
  );
}

function EventDateRange({ start, end, startTimeOfDay, endTimeOfDay }: any) {
  function getTimeTypeLabel(type: string) {
    switch (type) {
      case EVENT_TIME_TYPE__START:
        return 'day start';
      case EVENT_TIME_TYPE__MIDDAY:
        return 'midday';
      case EVENT_TIME_TYPE__END:
        return 'day end';
    }
  }

  return (
    <div className="flex items-center whitespace-nowrap font-medium text-slate-500 opacity-80">
      <CalendarIcon className="mr-3 h-5 w-5" aria-hidden="true" />
      <div className="flex w-[105px] items-center justify-between">
        <div className="relative">
          <div className="text-xs font-normal opacity-80">
            {getTimeTypeLabel(startTimeOfDay)}
          </div>
          <div>{moment(start.date).format('DD MMM')}</div>
        </div>
        <div className="relative top-2 mx-1">-</div>
        <div className="relative">
          <div className="text-xs font-normal opacity-80">
            {getTimeTypeLabel(endTimeOfDay)}
          </div>
          <div>{moment(end.date).format('DD MMM')}</div>
        </div>
      </div>
    </div>
  );
}
