import { useParams } from 'react-router-dom';
import { EmployeeDetails } from '../components/EmployeeDetails';
import { useQuery } from 'react-query';
import timeaway from '../../../network/timeaway';
import { Spinner } from '../../../ui/Spinner';

export function EmployeeDetailsRoute() {
  const { id } = useParams();

  const { data: timeawayData, isLoading } = useQuery(
    ['timeaway-details', id],
    () => timeaway.getEmployeeDetails(id!),
    {
      enabled: !!id,
    }
  );

  if (isLoading) {
    return (
      <div className='h-[75vh] flex flex-col gap-3 justify-center items-center'>
        <Spinner size={10} className="text-red-500"/>
        <p className='font-semibold'>
          Loading...
        </p>
      </div>
    );
  }

  return <EmployeeDetails currentUser={timeawayData?.data} />;
} 