import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Input } from '../../../ui/form/Input';
import { Select } from '../../../ui/form/Select';
import { useMutation } from 'react-query';
import officesManagement from '../../../network/officesManagement';
import { useToast } from '../../../utils/useToast';

const countryOptions = [
  {
    id: 1,
    label: 'United Kingdom',
  },
  {
    id: 2,
    label: 'Germany',
  },
  {
    id: 3,
    label: 'Netherlands',
  },
  {
    id: 4,
    label: 'Poland',
  },
];

const cityOptions = [
  {
    id: 1,
    label: 'Dresden',
  },
  {
    id: 2,
    label: 'Berlin',
  },
  {
    id: 3,
    label: 'Bremen',
  },
  {
    id: 4,
    label: 'Hannover',
  },
];

export default function HanldeNewLocation({
  open,
  setOpen,
  setCurrentOffice,
  mode = 'create',
  currentOffice,
  refetchOffices,
}: any) {
  const { mutateAsync: createMutateAsync } = useMutation(
    officesManagement.creteOffice,
  );
  const { mutateAsync: updateMutateAsync } = useMutation(
    officesManagement.updateOffice,
  );

  const { toast } = useToast();

  const isEdit = mode === 'edit';

  const formik = useFormik({
    initialValues: {
      name: currentOffice?.name || '',
      address: currentOffice?.address.line1 || '',
      country: currentOffice?.address.country || '',
      city: currentOffice?.address.town || '',
      phone_number: currentOffice?.phone_number || '',
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup.string(),
      address: yup.string().required(),
      country: yup.string().required(),
      city: yup.string().required(),
      phone_number: yup.string().required(),
    }),
    onSubmit: async (data) => {
      try {
        if (!isEdit) {
          await createMutateAsync(data);
          toast({
            variant: 'success',
            title: 'Office successfully created.',
          });
        } else {
          await updateMutateAsync({ id: currentOffice.id, body: data });
          toast({
            variant: 'success',
            title: 'Office successfully updated.',
          });
        }
        refetchOffices();
        setOpen(false);
        formik.resetForm();
        setCurrentOffice({
          id: '',
          office_name: '',
          address: '',
          country: '',
          city: '',
          phone: '',
        });
      } catch (error) {
        toast({
          variant: 'error',
          title: 'Something went wrong. Please try again later.',
        });
      }
    },
  });

  function findError(
    fieldName: keyof typeof formik.initialValues,
  ): string | undefined {
    const error =
      formik.touched[fieldName] && formik.errors[fieldName]
        ? formik.errors[fieldName]
        : undefined;

    if (Array.isArray(error)) {
      return error.join(', ');
    }

    return error as string | undefined;
  }

  const handlSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center ">
          <DialogPanel
            transition
            className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in "
          >
            <div className="px-6 pt-4 ">
              <div className="flex flex-col">
                <DialogTitle
                  as="h6"
                  className="text-base font-semibold text-gray-900"
                >
                  Add new location
                </DialogTitle>
                <p className="text-gray-500">
                  Determine the location of the new office
                </p>
              </div>
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col gap-2 px-6 pb-4 pt-3"
            >
              <div>
                <label
                  htmlFor="name"
                  className="mb-1 block text-base font-medium"
                >
                  Office name
                </label>
                <Input
                  type="text"
                  placeholder="Enter office name (optional)"
                  id="name"
                  name="name"
                  error={findError('name')}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
              </div>
              <div>
                <label
                  htmlFor="address"
                  className="mb-1 block text-base font-medium"
                >
                  Streat Address
                </label>
                <Input
                  type="text"
                  placeholder="Enter streat address"
                  id="address"
                  name="address"
                  error={findError('address')}
                  value={formik.values.address}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="flex flex-row gap-4">
                <div className="w-[50%]">
                  <label
                    htmlFor="country"
                    className="mb-1 block text-base font-medium"
                  >
                    Country
                  </label>
                  <Select
                    id="country"
                    name="country"
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    error={findError('country')}
                  >
                    <option>Select</option>
                    {countryOptions.map(
                      (item: { id: number; label: string }) => (
                        <option key={item.id} value={item.label}>
                          {item.label}
                        </option>
                      ),
                    )}
                  </Select>
                </div>
                <div className="w-[50%]">
                  <label
                    htmlFor="city"
                    className="mb-1 block text-base font-medium"
                  >
                    City
                  </label>
                  <Select
                    id="city"
                    name="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    error={findError('city')}
                  >
                    <option>Select</option>
                    {cityOptions.map((item: { id: number; label: string }) => (
                      <option key={item.id} value={item.label}>
                        {item.label}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div>
                <label
                  htmlFor="phone_number"
                  className="mb-1 block text-base font-medium"
                >
                  Phone number
                </label>
                <Input
                  type="text"
                  placeholder="+11-111-11-11-111"
                  id="phone_number"
                  name="phone_number"
                  error={findError('phone_number')}
                  value={formik.values.phone_number}
                  onChange={formik.handleChange}
                />
              </div>
            </form>
            <div className="flex flex-row justify-end gap-3 px-6 pb-5">
              <button
                type="button"
                data-autofocus
                onClick={() => {
                  setOpen(false);
                  formik.resetForm();
                }}
                className="inline-flex justify-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={handlSubmit}
                className="inline-flex justify-center rounded-md bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-sm  hover:bg-red-500"
              >
                {isEdit ? 'Save' : 'Add office location'}
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
