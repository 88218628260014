import moment, { Moment } from 'moment';
import { WeekCellProps } from '../../../types/RotaTypes';
import classnames from 'classnames';
import { Popover, Menu } from 'evergreen-ui';
import {
  ArrowRightIcon,
  ChevronUpIcon,
  EllipsisVerticalIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { MinusCircleIcon, UserIcon } from '@heroicons/react/24/solid';

const getUniqueDayRotas = (dayRotas: any[]) => {
  return Array.from(new Set(dayRotas.map((rota) => rota.id))).map((id) =>
    dayRotas.find((rota) => rota.id === id),
  );
};

export function WeekDayCell({ days, rotas, today }: WeekCellProps) {
  return (
    <div className="grid w-full grid-cols-7  bg-gray-50">
      {days?.map((day, index) => {
        const dayRotas = rotas.filter((rota) =>
          moment(day).isBetween(rota.start_date, rota.end_date, 'day', '[]'),
        );
        const uniqueDayRotas = getUniqueDayRotas(dayRotas);
        return (
          <div key={index} className="border border-gray-200 ">
            <div className="flex flex-col items-center border-b py-2">
              <p className="text-sm font-semibold">
                {moment(day).format('ddd')}
              </p>
              <div
                className={classnames(
                  'flex h-6 w-6 items-center justify-center rounded-full',
                  {
                    'bg-red-600 text-white': moment().isSame(day, 'day'),
                  },
                )}
              >
                {moment(day).format('D')}
              </div>
            </div>

            <div className="flex flex-col gap-2 p-1">
              {uniqueDayRotas.map((rota) => (
                <div key={rota.id}>
                  <Popover
                    content={
                      <div className="flex min-w-[350px] flex-col gap-2 px-6 py-4">
                        <div className="flex flex-row items-center justify-between">
                          <p>{rota.name}</p>
                          <Popover
                        content={
                          <Menu>
                            <Menu.Group>
                              <Menu.Item>
                                <div className="flex flex-row gap-2">
                                  <PencilSquareIcon className="w-5" />
                                  Edit shift
                                </div>
                              </Menu.Item>
                              <Menu.Item>
                                <div className="flex flex-row gap-2">
                                  <MinusCircleIcon
                                    className="w-5"
                                    color="red"
                                  />
                                  Remove this shift
                                </div>
                              </Menu.Item>
                            </Menu.Group>
                          </Menu>
                        }
                      >
                        <button>
                          <EllipsisVerticalIcon className="w-5" />
                        </button>
                      </Popover>
                        </div>
                        <div className="flex flex-row gap-2 ">
                          <label className="flex-1  rounded-lg border px-3 py-1 shadow-sm">
                            {moment(rota.start_time, 'HH:mm').format('hh:mm A')}
                          </label>
                          <ArrowRightIcon className="w-5" />
                          <label className="flex-1  rounded-lg border px-3 py-1 shadow-sm">
                            {moment(rota.end_time, 'HH:mm').format('hh:mm A')}
                          </label>
                        </div>
                        <div className="flex flex-col gap-1">
                          <div className="flex flex-row justify-between rounded-lg bg-[#FAFAFA] px-3 py-1">
                            <p>Assigned employees</p>
                            <ChevronUpIcon className="w-4" />
                          </div>
                          <div className="flex flex-col gap-1">
                            {rota?.employees.length > 0 &&
                              rota?.employees.map((employee: any) => (
                                <div
                                  key={employee.id}
                                  className="flex flex-row items-center gap-2 border-b py-2"
                                >
                                  <UserIcon className="w-7" />
                                  <div className="flex flex-col">
                                    <p>{employee.name}</p>
                                    <p className="text-gray-500">
                                      {employee.email}
                                    </p>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    }
                  >
                    <div className="cursor-pointer rounded-md px-2 py-1 text-xs hover:text-red-600 bg-[#E0F2FE] ">
                      <p className="font-semibold">{rota?.name}</p>
                      <p className='text-[#4338CA]'>
                        {moment(rota?.start_time, 'HH:mm').format('hh:mm A')} -{' '}
                        {moment(rota?.end_time, 'HH:mm').format('hh:mm A')}
                      </p>
                      <p className='text-gray-500'>{rota?.employees?.length || 0} Employees</p>
                    </div>
                  </Popover>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}
