import { useQuery } from 'react-query';
import officesManagement from '../../network/officesManagement';
import departmentsManagement from '../../network/departmentsManagement';

export function useGetOffices() {
  return useQuery({
    queryKey: ['get_office'],
    queryFn: async () => {
      const { data } = await officesManagement.getOffices();
      return data;
    },
  });
}

export function useGetDepartments() {
  return useQuery({
    queryKey: ['get_departments'],
    queryFn: async () => {
      const { data } = await departmentsManagement.getDepartments();
      return data;
    },
  });
}
