import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import timeaway from '../../../network/timeaway';
import { UserTable } from './UserTable';
import ApproveModal from './ApproveModal';
import RejectModal from './RejectModal';

interface TimeawayUser {
  id: string;
  type: string;
  full_name: string;
  start: string;
  end: string;
  date_requested: string;
  date_approved: string | null;
  decision: string;
  decision_by: string | null;
  details: string;
}

export function TimeOffTab() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: timeOffStatus = { approved: 0, pending: 0, rejected: 0 } } = useQuery({
    queryKey: ['timeOffStatus'],
    queryFn: async () => {
      const { data } = await timeaway.getTimeOffStatus();
      return data;
    }
  });

  const [isOpenApproveModal, setIsOpenApproveModal] = useState(false);
  const [isOpenRjectModal, setIsOpenRjectModal] = useState(false);
  const [currentUser, setCurrentUser] = useState<TimeawayUser>({
    id: '',
    type: '',
    full_name: '',
    start: '',
    end: '',
    date_requested: '',
    date_approved: null,
    decision: '',
    decision_by: null,
    details: ''
  });

  const handleApproveModal = (user: TimeawayUser) => {
    setCurrentUser(user);
    setIsOpenApproveModal(true);
  };

  const handleRejectModal = (user: TimeawayUser) => {
    setCurrentUser(user);
    setIsOpenRjectModal(true);
  };

  const handleEmployeeDetails = (userId: string) => {
    navigate(`/time-off/${userId}`);
  };

  const refreshData = () => {
    queryClient.invalidateQueries({ queryKey: ['timeOffStatus'] });
  };

  return (
    <div className="mt-3 flex flex-col">
      <div className="flex flex-row gap-3">
        <div className="flex min-h-[50px] w-[33%] flex-col gap-1 rounded-lg bg-[#FFEDD5] p-4">
          <p className="text-[#71717A]">Pending Requests</p>
          <h4 className="text-2xl font-semibold">
            {timeOffStatus.pending}
          </h4>
        </div>
        <div className="flex min-h-[50px] w-[33%] flex-col gap-1 rounded-lg bg-[#DCFCE7] p-4">
          <p className="text-[#71717A]">Approved Requests</p>
          <h4 className="text-2xl font-semibold">
            {timeOffStatus.approved}
          </h4>
        </div>
        <div className="flex min-h-[50px] w-[33%] flex-col gap-1 rounded-lg bg-[#FEE2E2] p-4">
          <p className="text-[#71717A]">Rejected Requests</p>
          <h4 className="text-2xl font-semibold">
            {timeOffStatus.rejected}
          </h4>
        </div>
      </div>
      <div className="mt-4">
        <UserTable
          handleApproveModal={handleApproveModal}
          handleRejectModal={handleRejectModal}
          handleEmployeeDetails={handleEmployeeDetails}
        />
      </div>
      <ApproveModal
        open={isOpenApproveModal}
        setOpen={setIsOpenApproveModal}
        user={currentUser}
        timeawayId={currentUser.id}
        onApproveSuccess={refreshData}
      />
      <RejectModal
        open={isOpenRjectModal}
        setOpen={setIsOpenRjectModal}
        user={currentUser}
        onRejectSuccess={refreshData}
      />
    </div>
  );
}
