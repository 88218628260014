import {
  ArrowDownTrayIcon,
  ArrowRightIcon,
  CalendarDaysIcon,
  ChevronLeftIcon,
  ClockIcon,
  DocumentIcon,
  EyeIcon,
  HashtagIcon,
  UserIcon,
} from '@heroicons/react/24/solid';
import {
  ArrowPathIcon,
  CheckCircleIcon,
  UserCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import axios from 'axios';
import { addDays, format, set, startOfWeek } from 'date-fns';
import { Pane, Popover } from 'evergreen-ui';
import { useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

const { REACT_APP_API_HOST } = process.env;

const today = new Date();
const startDate = startOfWeek(today, { weekStartsOn: 1 });

// First, let's add an interface for the absentee data
interface Absentee {
  id: string;
  name: string;
  role: string;
  email: string;
  team: string;
  leave_type: string;
}

interface DayAbsentees {
  date: string;
  absentees: Absentee[];
}

// Add interface for currentUser
interface CurrentUser {
  applicant: string;
  email: string;
  start_date: string;
  end_date: string;
  days_requested: string;
  type: string;
  documents: Array<{ name: string; id: string }>;
}

// Update the interface to match the API response
interface ApiResponse {
  status: string;
  total: number;
  absentees: DayAbsentees[];
}

// Add interface for shift times
interface ShiftTimes {
  start: string;
  end: string;
}

// Update the days interface
interface DayData {
  day: string;
  weekday: string;
  date: string;
  employees: number;
  absent: number;
  absentees: Absentee[];
  shift?: ShiftTimes;
}

export function EmployeeDetails({ 
  currentUser 
}: { 
  currentUser: CurrentUser;
}) {
  const navigate = useNavigate();
  const [selectedDay, setSelectedDay] = useState<number | null>(null);

  const { data, isLoading, isError } = useQuery<ApiResponse>({
    queryKey: ['absentees', startDate.toISOString()],
    queryFn: async () => {
      const { data } = await axios.post(`${REACT_APP_API_HOST}/v1/timeaway/absentees`, { 
        date: startDate 
      });
      return data;
    },
  });

  const days = useMemo<DayData[]>(() => 
    Array.from({ length: 14 }, (_, i) => {
      const date = addDays(startDate, i);
      const formattedDate = format(date, 'yyyy-MM-dd');
      const dayAbsentees = data?.absentees?.find((day) => day.date === formattedDate);
      
      return {
        day: format(date, 'd'),
        weekday: format(date, 'E'),
        date: formattedDate,
        employees: data?.total || 0,
        absent: dayAbsentees?.absentees?.length || 0,
        absentees: dayAbsentees?.absentees || [],
        shift: { start: '09:00AM', end: '06:00PM' }
      };
    }),
    [data?.absentees]
  );

  if (isError) {
    return <div className="flex justify-center p-4 text-red-500">Error loading calendar data</div>;
  }

  return (
    <div className="flex flex-col">
      <div className="flex-start mt-5 flex">
        <button
          onClick={() => navigate('/time-off')}
          className="flex flex-row items-center gap-2 px-2 py-1"
        >
          <ChevronLeftIcon className="w-4" />
          Back to page
        </button>
      </div>
      <div className="mt-3 flex flex-row items-center justify-between">
        <div className="flex flex-row gap-2">
          <UserCircleIcon color="gray" className="w-10" />
          <div className="flex flex-col">
            <p className="text-lg font-semibold">{currentUser.applicant}</p>
            <p className="text-sm text-gray-500">{currentUser.email}</p>
          </div>
        </div>
        <div className="flex flex-row gap-3">
          <button
            className="flex flex-row items-center justify-center  gap-1
                        gap-2 rounded-lg bg-[#F97316] px-4 py-2 text-white"
          >
            <ArrowPathIcon color="white" className="w-5" />
            Request Changes
          </button>
          <button
            className="flex flex-row items-center justify-center gap-2
                        rounded-lg bg-[#DC2626] px-4 py-2 text-white"
          >
            <XCircleIcon color="white" className="w-5" />
            Reject
          </button>
          <button
            className="flex flex-row items-center justify-center gap-2
                       rounded-lg bg-[#15803D] px-4 py-2 text-white"
          >
            <CheckCircleIcon color="white" className="w-5" />
            Approve
          </button>
        </div>
      </div>
      <div className="mt-3 flex flex-col rounded-lg border p-4">
        <p className="font-medium">Leave Details</p>
        <div className="mt-2 flex flex-row items-center gap-[70px]">
          <div className="flex flex-row gap-2">
            <CalendarDaysIcon className="w-6" />
            Dates Requested
          </div>
          <div className="rounded-lg bg-gray-100 px-2 py-1">
            {currentUser.start_date} - {currentUser.end_date}
          </div>
        </div>
        <div className="mt-2 flex flex-row items-center gap-[132px]">
          <div className="flex flex-row gap-2">
            <ClockIcon className="w-6" />
            Duration
          </div>
          <div className="rounded-lg bg-gray-100 px-2 py-1">
            {currentUser.days_requested}
          </div>
        </div>
        <div className="mt-2 flex flex-row items-center gap-[114px]">
          <div className="flex flex-row gap-2">
            <HashtagIcon className="w-6" />
            Leave type
          </div>
          <div className="rounded-lg bg-gray-100 px-2 py-1">
            {currentUser.type}
          </div>
        </div>
        <div className="mt-2 flex flex-row justify-between">
          <div className="flex flex-row items-center  gap-[110px]">
            <div className="flex flex-row gap-2">
              <DocumentIcon className="w-6" />
              Supporting <br /> Documents
            </div>
            <div > 
              { currentUser.documents.length > 0 && currentUser.documents.map((document) => (
                <p key={document.id} className="flex flex-row gap-1 rounded-lg bg-gray-100 px-2 py-1">
                  {document.name}
                </p>
              ))}
            </div>
          </div>
          <div className="flex flex-row items-center gap-2">
            <button 
              className="rounded-lg border p-2 shadow"
              title="View document"
              aria-label="View document"
            >
              <EyeIcon className="w-4" />
            </button>
            <button 
              className="rounded-lg border p-2 shadow"
              title="Download document"
              aria-label="Download document"
            >
              <ArrowDownTrayIcon className="w-4" />
            </button>
          </div>
        </div>
      </div>
      <p className="mt-3 font-medium">Team calendar</p>
      <div className="mt-2 rounded-lg border ">
        <div className="bg-[#FAFAFA] px-4 py-5">
          <p className="font-semibold">
            {format(today, 'd MMM yyyy')} -{' '}
            {format(addDays(today, 13), 'd MMM yyyy')}
          </p>
        </div>
        <div className="grid grid-cols-7 rounded-b-lg border-l border-t">
          {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(
            (day, index) => (
              <div
                key={index}
                className="border-b border-r p-2 text-center font-medium"
              >
                {day}
              </div>
            ),
          )}
          {days.map((day, index) => (
            <div
              key={index}
              onClick={() => setSelectedDay(index)}
              className={`cursor-pointer border-b border-r p-3 ${
                selectedDay === index ? 'bg-[#FEE2E2]' : ''
              }`}
            >
              <Popover
                onClose={() => setSelectedDay(null)}
                content={
                  <Pane className="w-auto px-4 py-3">
                    <p className="text-sm font-medium">Shift details</p>
                    <div className="mt-2 flex flex-row gap-2">
                      <span className="rounded-lg border px-4 py-1 shadow">
                        {days[selectedDay || 0]?.shift?.start || '09:00AM'}
                      </span>
                      <ArrowRightIcon className="w-4" />
                      <span className="rounded-lg border px-4 py-1 shadow">
                        {days[selectedDay || 0]?.shift?.end || '06:00PM'}
                      </span>
                    </div>
                    <p className="mt-3 rounded-lg bg-[#FAFAFA] px-2 py-1 text-sm font-medium">
                      {days[selectedDay || 0]?.absent || 0} Employees Absent
                    </p>

                    <div className="mt-1 max-h-[200px] overflow-auto">
                      {days[selectedDay || 0]?.absentees.map((user) => (
                        <div key={user.id} className="flex flex-row items-center gap-2 border-b">
                          <UserIcon className="w-5" />
                          <div className="flex flex-col py-1 text-sm">
                            <p>{user.name}</p>
                            <p>{user.team} - {user.leave_type}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Pane>
                }
              >
                <div className="flex min-h-[70px] flex-col gap-2 text-left ">
                  <span className="text-gray-600">{day.day}</span>
                  <div>
                    <p className="text-xs text-gray-500">
                      {day.employees} Employees
                    </p>
                    {day.absent > 0 && (
                      <p className="text-xs">{day.absent} Employees absent</p>
                    )}
                  </div>
                </div>
              </Popover>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
