import filesManagement from '../network/filesManagement';

export default async function downloadFile(id: string) {
  const { data, headers } = await filesManagement.downloadDocument(id);

  const contentType = headers['content-type'];

  const fileData = data.file.data;

  const arrayBuffer = new Uint8Array(fileData).buffer;
  const fileBlob = new Blob([arrayBuffer], { type: contentType.split(';')[0] });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(fileBlob);
  link.download = data.fileName;
  link.click();

  URL.revokeObjectURL(link.href);
}
