import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import {
  UserIcon,
  XMarkIcon,
  ChevronUpDownIcon,
} from '@heroicons/react/24/solid';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@tremor/react';
import { Menu, Popover, Position } from 'evergreen-ui';
import { useState } from 'react';
import { Checkbox } from '../../../ui/component/checkbox';
import { Textarea } from '../../../ui/form/Textarea';
import timeawayApi from '../../../network/timeaway';

const reasons = [
  {
    id: '1',
    title: 'Exceeding Leave Balance',
    content: 'Your leave balance does not cover the requested duration',
  },
  {
    id: '2',
    title: 'High Workload',
    content:
      'Due to a high workload during this period, we are unable to approve your leave request',
  },
  {
    id: '3',
    title: 'Staff Shortages',
    content:
      'We currently have a shortage of staff during this period, making it challenging to accommodate your leave',
  },
  {
    id: '4',
    title: 'Project Deadlines',
    content:
      'Your leave request coincides with critical project deadlines or deliverables',
  },
];

interface RejectModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  user: {
    id: string;
    type: string;
    full_name: string;
  };
  onRejectSuccess?: () => void;
}

export default function RejectModal({ open, setOpen, user, onRejectSuccess }: RejectModalProps) {
  const [selectedReason, setSelectedReason] = useState('');
  const [manualReason, setManualReason] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleReasonSelection = (reasonId: string) => {
    if (selectedReason === reasonId) {
      setSelectedReason('');
    } else {
      setSelectedReason(reasonId);
    }
  };

  const handleReject = async () => {
    try {
      setIsSubmitting(true);
      const reason = activeTab === 0 
        ? reasons.find(r => r.id === selectedReason)?.content || ''
        : manualReason;
      
      await timeawayApi.rejectTimeawayRequest(user.id, reason);
      setOpen(false);
      onRejectSuccess?.();
    } catch (error) {
      console.error('Failed to reject request:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center ">
          <DialogPanel
            transition
            className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in "
          >
            <div className="bg-white px-6 pt-2">
              <div className="flex flex-col">
                <div className="mt-4 flex justify-between">
                  <DialogTitle as="h6" className="font-semibold text-gray-900">
                    Reject
                  </DialogTitle>
                  <button 
                    onClick={() => setOpen(false)}
                    aria-label="Close dialog"
                  >
                    <XMarkIcon className="w-4" />
                  </button>
                </div>
                <div className="mt-3">
                  <p className="text-sm text-gray-600">
                    The employee will receive a notification about their
                    request being rejected.
                  </p>
                </div>
                <div className="mt-2 flex flex-row gap-2 rounded-lg bg-[#FAFAFA] px-4 py-2">
                  <UserIcon className="w-4" />
                  {user.full_name}
                </div>
                <div className="mt-3">
                  <TabGroup index={activeTab} onIndexChange={setActiveTab}>
                    <TabList
                      variant="solid"
                      color="rose"
                      className="w-full justify-center"
                    >
                      <Tab className="flex w-[50%] justify-center">
                        Template
                      </Tab>
                      <Tab className="flex w-[50%] justify-center">
                        Manually specify
                      </Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        <div className="mt-1 flex flex-col gap-1">
                          <p className="font-semibold">Reason</p>
                          <Popover
                            position={Position.BOTTOM}
                            content={
                              <Menu>
                                <Menu.Group>
                                  <div className="flex w-full flex-col gap-2">
                                    {reasons.map((item: any) => (
                                      <Menu.Item
                                        key={item.id}
                                        onClick={() =>
                                          handleReasonSelection(item.id)
                                        }
                                      >
                                        <div className="flex flex-row ">
                                          <Checkbox
                                            color="white"
                                            onChange={() =>
                                              handleReasonSelection(item.id)
                                            }
                                            checked={selectedReason === item.id}
                                          />
                                          <div>
                                            <p className="font-semibold">
                                              {item.title}
                                            </p>
                                            <p className="text-gray-500">
                                              {item.content}
                                            </p>
                                          </div>
                                        </div>
                                      </Menu.Item>
                                    ))}
                                  </div>
                                </Menu.Group>
                              </Menu>
                            }
                          >
                            <div className="flex w-full justify-between rounded-lg  border-[1px] border-gray-300 px-2 px-3 py-2 shadow-sm">
                              Select
                              <ChevronUpDownIcon className="w-5" color="gray" />
                            </div>
                          </Popover>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="mt-3 flex flex-col gap-1">
                          <p className="font-semibold ">Comment</p>
                          <Textarea 
                            placeholder="Specify the reason for refusal, ex: all days are used up"
                            value={manualReason}
                            onChange={(e) => setManualReason(e.target.value)}
                          />
                        </div>
                      </TabPanel>
                    </TabPanels>
                  </TabGroup>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-end gap-3 px-6 py-5">
              <button
                onClick={() => setOpen(false)}
                className="px-4 py-1"
                disabled={isSubmitting}
              >
                Cancel
              </button>
              <button 
                onClick={handleReject}
                className="rounded-lg bg-red-600 px-4 py-1 text-white"
                disabled={isSubmitting || (activeTab === 0 ? !selectedReason : !manualReason)}
              >
                {isSubmitting ? 'Rejecting...' : 'Reject'}
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
