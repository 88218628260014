import { EmptyStateIcon } from '../../../assets/EmptyState/EmptyStateIcon';

export function EmptyState({
  handleOnboardingNavigate,
}: {
  handleOnboardingNavigate: () => void;
}) {
  return (
    <>
      <div className="flex items-center justify-between border-b-2 border-[#E4E4E7] pb-4">
        <h1 className="text-2xl font-bold  text-tremor-content-strong">Home</h1>
      </div>
      <div className="flex h-[85vh] flex-col items-center justify-center gap-2">
        <EmptyStateIcon />
        <h1 className="text-3xl font-bold">No data available</h1>
        <p>Set up your account to access your dashboard</p>
        <button
          onClick={handleOnboardingNavigate}
          className="rounded-lg bg-[#DC2626] px-4 py-2 text-white"
        >
          Setup account
        </button>
      </div>
    </>
  );
}
