import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { usePeople } from '../../People/hooks';
import { useToast } from '../../../utils/useToast';
import { Input } from '../../../ui/form/Input';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { AssignEmployee } from './AssignEmployee';
import rota from '../../../network/rota';

const colorOptions = [
  {
    id: '1',
    hex: '#F4F4F5',
  },
  {
    id: '2',
    hex: '#BE185D',
  },
  {
    id: '3',
    hex: '#A21CAF',
  },
  {
    id: '4',
    hex: '#7E22CE',
  },
  {
    id: '5',
    hex: '#6D28D9',
  },
  {
    id: '6',
    hex: '#4338CA',
  },
  {
    id: '7',
    hex: '#1D4ED8',
  },
  {
    id: '8',
    hex: '#0369A1',
  },
  {
    id: '9',
    hex: '#0E7490',
  },
  {
    id: '10',
    hex: '#0F766E',
  },
  {
    id: '11',
    hex: '#047857',
  },
  {
    id: '12',
    hex: '#15803D',
  },
];

export default function CreateNewRotaModal({ open, setOpen, refetchRotas }: any) {
  const [isShownAssignEmployees, setIsShownAssignEmployyes] = useState(false);

  const { data: peopleOptions = [] } = usePeople();
  const { toast } = useToast();

  const formik = useFormik({
    initialValues: {
      name: '',
      start_time: '',
      end_time: '',
      employees: [],
      color: '#F4F4F5',
      start_date: '',
      end_date: '',
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup.string().required(),
      start_time: yup.string().required(),
      end_time: yup.string().required(),
      start_date: yup.string().required(),
    }),
    onSubmit: async (data) => {
      try {
        await rota.createRota(data);
        refetchRotas();
        toast({
          variant: 'success',
          title: 'Rota successfully created.',
        });
      } catch (error) {
        toast({
          variant: 'error',
          title: 'Something went wrong. Please try again later.',
        });
      }
      setOpen(false);
      formik.resetForm();
    },
  });

  function findError(
    fieldName: keyof typeof formik.initialValues,
  ): string | undefined {
    const error =
      formik.touched[fieldName] && formik.errors[fieldName]
        ? formik.errors[fieldName]
        : undefined;

    if (Array.isArray(error)) {
      return error.join(', ');
    }

    return error as string | undefined;
  }

  const handleSelectPeople = (id: string, checked: boolean) => {
    const updatedPeople = checked
      ? [...formik.values.employees, id]
      : formik.values.employees.filter((personId) => personId !== id);
    formik.setFieldValue('employees', updatedPeople);
  };

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center ">
          <DialogPanel
            as="div"
            transition
            className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in "
          >
            {!isShownAssignEmployees ? (
              <>
                <div className="px-6 pb-2 pt-4">
                  <div className="flex flex-col">
                    <div className="mt-4 text-left">
                      <DialogTitle
                        as="h6"
                        className=" font-semibold text-gray-900"
                      >
                        Create new rota
                      </DialogTitle>
                      <p className="text-gray-500">
                        Create a new rota, assign working hours and employees
                      </p>
                    </div>
                  </div>
                </div>
                <form
                  onSubmit={formik.handleSubmit}
                  className="flex flex-col gap-3 px-6 pb-4"
                >
                  <div className="flex flex-col gap-1">
                    <label htmlFor="name" className="text-base font-medium">
                      Name
                    </label>
                    <Input
                      type="text"
                      placeholder="Enter name"
                      id="name"
                      name="name"
                      error={findError('name')}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <label className="font-semibold ">Work time</label>
                    <div className="flex flex-row gap-3">
                      <div className="flex w-[50%] flex-col gap-1">
                        <label
                          htmlFor="start_time"
                          className="text-base font-medium"
                        >
                          Start time
                        </label>
                        <Input
                          type="text"
                          placeholder="00:00"
                          id="start_time"
                          name="start_time"
                          error={findError('start_time')}
                          value={formik.values.start_time}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div className="flex w-[50%] flex-col gap-1">
                        <label
                          htmlFor="end_time"
                          className="text-base font-medium"
                        >
                          End time
                        </label>
                        <Input
                          type="text"
                          placeholder="00:00"
                          id="end_time"
                          name="end_time"
                          error={findError('end_time')}
                          value={formik.values.end_time}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-1">
                    <label className="font-semibold ">Work days</label>
                    <div className="flex flex-row gap-3">
                      <div className="flex w-[50%] flex-col gap-1">
                        <label
                          htmlFor="start_date"
                          className="text-base font-medium"
                        >
                          Start date
                        </label>
                        <Input
                          type="date"
                          id="start_date"
                          name="start_date"
                          value={formik.values.start_date}
                          onChange={formik.handleChange}
                          error={findError('start_date')}
                        />
                      </div>
                      <div className="flex w-[50%] flex-col gap-1">
                        <label
                          htmlFor="end_date"
                          className="text-base font-medium"
                        >
                          End date
                        </label>
                        <Input
                          type="date"
                          id="end_date"
                          name="end_date"
                          value={formik.values.end_date}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => setIsShownAssignEmployyes(true)}
                    className="flex w-full flex-row items-center justify-between rounded-lg border
                            px-3 py-1"
                  >
                    Assign people
                    <ChevronRightIcon className="w-4" />
                  </button>
                  <div className="flex flex-col gap-1">
                    <label htmlFor="color" className="text-base font-medium">
                      Color
                    </label>
                    <div className="mt-1 flex flex-wrap gap-2">
                      {colorOptions.map((color) => (
                        <button
                          key={color.id}
                          type="button"
                          className={`h-7 w-7 rounded-lg border-2 ${
                            formik.values.color === color.hex
                              ? 'border-red-500'
                              : 'border-gray-300'
                          }`}
                          style={{ backgroundColor: color.hex }}
                          onClick={() =>
                            formik.setFieldValue('color', color.hex)
                          }
                        />
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-row justify-end gap-3 px-6 pb-5">
                    <button
                      type="button"
                      data-autofocus
                      onClick={() => {
                        setOpen(false);
                        formik.resetForm();
                      }}
                      className="inline-flex justify-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500"
                    >
                      Create
                    </button>
                  </div>
                </form>
              </>
            ) : (
              <AssignEmployee
                peopleOptions={peopleOptions.data || []}
                setIsShownAssignEmployyes={setIsShownAssignEmployyes}
                handleSelectPeople={handleSelectPeople}
                selectedPeople={formik.values.employees}
              />
            )}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
