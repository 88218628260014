import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@tremor/react';
import { LeaveTypesComponent } from './components/LeaveTypesComponent';
import { AiGenerateIcon } from '../../assets/DocumentManagementIcon/AIGenerate';
import { WorkSpaceDetails } from './components/WorkSpaceDetails';

export function SettingsRoute() {
  return (
    <>
      <div className="flex flex-row items-center justify-between border-b-2 border-[#E4E4E7] pb-4">
        <div>
          <h1 className="text-3xl font-bold  text-tremor-content-strong">
            Settings
          </h1>
          <p className="text-gray-500">
            Manage your personal details, workspace governance and notifications
          </p>
        </div>
        {/* <button
          className="flex items-center gap-2 rounded-lg bg-gradient-to-r 
          from-[#F77B23] to-[#F69620] px-5 py-2 font-normal text-white"
        >
          <AiGenerateIcon />
          Redstone AI
        </button> */}
      </div>
      <div className="my-5">
        <TabGroup>
          <TabList variant="solid" color="rose">
            <Tab className="text-md px-3 py-2">Workspace details</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <WorkSpaceDetails />
            </TabPanel>
            <TabPanel>
              <LeaveTypesComponent />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </>
  );
}
