import { useEffect, useState } from 'react';
import moment from 'moment';
import { getDay } from 'date-fns';

import axios from 'axios';
import { useQuery } from 'react-query';
import { UserGroupIcon } from '@heroicons/react/24/outline';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@tremor/react';
import { Select } from '../../ui/form/Select';
import { Spinner } from '../../ui/Spinner';
import { UserPreview } from './components/UserPreview';
import { UserType } from '../../types';
import { Position, Tooltip } from 'evergreen-ui';
import { useUser } from '@clerk/clerk-react';
import { EmptyState } from './components/EmptyState';
import { useNavigate, Link } from 'react-router-dom';
import ElevenLabsWidget from '../../components/ElevenLabsWidget';

type Person = {
  id: string;
  name: string;
};

type Team = {
  id: string;
  name: string;
};

type Office = {
  id: string;
  name: string;
};

const { REACT_APP_API_HOST } = process.env;
const API_HOST = process.env.REACT_APP_API_HOST;

const Overview = () => {
  const [token, setToken] = useState<string>();
  const [currentOffice, setCurrentOffice] = useState<string>('');
  const [currentTeam, setCurrentTeam] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {}, [token]);

  const { data: response = [], refetch } = useQuery({
    queryKey: ['home-section', { office: currentOffice, team: currentTeam }],
    queryFn: async ({ queryKey }) => {
      setIsLoading(true);
      const { data = [] } = await axios({
        method: 'get',
        url: `${REACT_APP_API_HOST}/v1/people/overview`,
        headers: { Authorization: `Bearer ${token}` },
        params: {
          office: currentOffice,
          team: currentTeam,
        },
      });
      setIsLoading(false);
      return data;
    },
    staleTime: 300000,
    cacheTime: 900000,
  });

  const { data: offices = [] } = useQuery({
    queryKey: ['offices'],
    queryFn: async () => {
      const { data = [] } = await axios({
        method: 'get',
        url: `${REACT_APP_API_HOST}/v1/office`,
      });
      return data;
    },
  });

  const { data: teams = [] } = useQuery({
    queryKey: ['teams'],
    queryFn: async () => {
      const { data = [] } = await axios({
        method: 'get',
        url: `${REACT_APP_API_HOST}/v1/team`,
      });
      return data;
    },
  });

  useEffect(() => {
    if (currentOffice !== '0' || currentTeam !== '0') {
      refetch();
    }
  }, [currentOffice, currentTeam, refetch]);

  const renderStatus = () => {
    const showStatusMessage = (status: string) => {
      switch (status) {
        case 'In office':
          return 'working in the office';
        case 'Working remotely':
          return 'working remotely';
        case 'Not contracted to work':
          return 'not contracted to work today';
        case 'Non-contracted day':
          return 'not contracted day today';
        case 'Holiday':
          return 'on holiday';
        case 'Sick leave':
          return 'sick';
        case 'Parental leave':
          return 'on parental leave';
        case 'Other leave':
          return 'on other leave';
      }
    };

    function isWeekday(date: Date): boolean {
      const day = getDay(date);
      return day !== 0 && day !== 6;
    }

    const statusUsers =
      response?.status?.flatMap(({ users }: any) => users) || [];

    const remoteUsers = statusUsers.filter(
      (user: any) => user.status_now === 'Working remotely',
    );
    const contractedToWorkUsers = statusUsers.filter(
      (user: any) => user.status_now === 'Not contracted to work',
    );
    const contractedDaykUsers = statusUsers.filter(
      (user: any) => user.status_now === 'Non-contracted day',
    );
    const holidayUsers = statusUsers.filter((user: any) =>
      user.status_now.includes('Holiday'),
    );
    const sickLeaveUsers = statusUsers.filter(
      (user: any) => user.status_now === 'Sick leave',
    );
    const parentalLeaveUsers = statusUsers.filter(
      (user: any) => user.status_now === 'Parental leave',
    );
    const otherLeaveUsers = statusUsers.filter(
      (user: any) => user.status_now === 'Other leave',
    );

    const summary = [
      { label: 'working remotely', count: remoteUsers.length },
      { label: 'on holiday', count: holidayUsers.length },
      { label: 'on sick leave', count: sickLeaveUsers.length },
      { label: 'on parental leave', count: parentalLeaveUsers.length },
      { label: 'on other leave', count: otherLeaveUsers.length },
      { label: 'not contracted to work', count: contractedToWorkUsers.length },
      { label: 'not contracted day today', count: contractedDaykUsers.length },
    ];

    const summaryMessage = summary
      .filter((item) => item.count > 0)
      .map((item) => `${item.count} ${item.label}`)
      .reduce((acc, current, index, array) => {
        return acc + current + (index < array.length - 1 ? ', ' : '');
      }, '');

    const office_users = response?.office?.map(
      ({ office_name, users }: any, index: number) => {
        return (
          <div key={index} className="space-y-2">
            <div>
              <span className="text-gray-400">{users.length} people </span>
              <span>in the {office_name} office:</span>
            </div>
            <div className="-mx-1 flex flex-wrap">
              {users.map((user: UserType) => {
                if (
                  user.status_now === 'In office' ||
                  user.office_name === 'Elstree'
                ) {
                  return (
                    <Tooltip
                      key={user.id}
                      content={user.full_name}
                      position={Position.TOP}
                    >
                      <Link to={`/profile/${user.id}`} className="p-1">
                        <img
                          alt={user.full_name}
                          src={user.safe_profile_picture_url}
                          className="h-11 w-11 flex-none rounded-full bg-gray-100"
                        />
                      </Link>
                    </Tooltip>
                  );
                }
                return null;
              })}
            </div>
          </div>
        );
      },
    );

    const status_users = response?.status
      ?.filter(({ status_now }: any) => status_now !== 'In office')
      .map(({ status_now, users }: any, index: number) => {
        return (
          <div key={index} className="space-y-2">
            <div>
              <span className="text-gray-400">{users.length} people </span>
              <span>: {status_now}</span>
            </div>
            <div className="-mx-1 flex flex-wrap">
              {users &&
                users.map((user: UserType) => {
                  if (user?.status_now !== 'In office') {
                    return <UserPreview user={user} key={user.id} />;
                  }
                  return null;
                })}
            </div>
          </div>
        );
      });

    const renderUsers = () => {
      let users: any[] = [];

      if (isWeekday(new Date())) {
        users = users.concat(office_users);
      }

      users = users.concat(status_users);
      return users.map((user) => user);
    };

    return (
      <div>
        <div className="flex flex-wrap items-center justify-between border-b border-gray-200 px-6 py-4 sm:flex-nowrap">
          <div className="">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Team Status today
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              A list of all employees and their locations.
            </p>
          </div>

          <div className="flex flex-row items-center gap-2">
            <div>Show:</div>
            <Select onChange={(event) => setCurrentTeam(event.target.value)}>
              <option value={''}>Everyone</option>
              {teams.data &&
                teams.data.map((team: Team) => {
                  return (
                    <option key={team.id} value={team.id}>
                      {team.name}
                    </option>
                  );
                })}
            </Select>
            <div>from</div>
            <Select onChange={(event) => setCurrentOffice(event.target.value)}>
              <option value={''}>All locations</option>
              {offices.data &&
                offices.data.map((office: Office) => {
                  return (
                    <option key={office.id} value={office.id}>
                      {office.name}
                    </option>
                  );
                })}
            </Select>
          </div>
        </div>

        <>
          {isLoading ? (
            <Spinner className="my-8" />
          ) : (
            <>
              <div className="space-y-6 px-6 py-4">{renderUsers()}</div>

              <div className="px-6 py-4">
                {summaryMessage.length > 0 ? (
                  <div className="text-md mt-1 flex flex-row gap-2 text-gray-500">
                    {summaryMessage}
                  </div>
                ) : response?.office?.length !== 0 ? (
                  <div className="text-md mt-1 text-gray-500">
                    No one is recorded as working remotely, on holiday, on
                    parental leave, on sick leave, or away for other reasons
                  </div>
                ) : (
                  <div className="text-md mt-1 text-gray-500">
                    No users found with current filters.
                  </div>
                )}
              </div>
            </>
          )}
        </>
      </div>
    );
  };

  return <>{renderStatus()}</>;
};

export function HomeRoute() {
  const navigate = useNavigate();

  const { data: response, status } = useQuery({
    queryKey: ['onboarding-status'],
    queryFn: async () => {
      const { data } = await axios({
        method: 'get',
        url: `${REACT_APP_API_HOST}/v1/onboarding`,
      });

      return data;
    },
  });

  useEffect(() => {
    if (status === 'success' && response?.data.status === 'onboarding') {
    }
  }, [response?.data.status, status]);

  const handleOnboardingNavigate = () => {
    navigate('/onboarding');
  };

  return (
    <>
      {response?.data?.status === 'onboarding' && (
        <EmptyState handleOnboardingNavigate={handleOnboardingNavigate} />
      )}
      <div>
        {response?.data?.status !== 'onboarding' && (
          <>
            <Greeting />
            <TabGroup>
              <TabList className="ml-3 mt-1">
                <Tab icon={UserGroupIcon}>Overview</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Overview />
                </TabPanel>
              </TabPanels>
            </TabGroup>
            <ElevenLabsWidget isVisible={true} />
          </>
        )}
      </div>
    </>
  );
}

function Greeting() {
  const { user } = useUser();

  const { data: response } = useQuery({
    queryKey: ['identity-state'],
    queryFn: async (options) => {
      const { data } = await axios.get(`${API_HOST}/v1/identity/state`);
      return data;
    },
  });

  return (
    <div className="mb-4">
      <h1 className="mb-3 text-2xl font-semibold text-gray-900">
        {moment().format('MMMM Do YYYY')}
      </h1>
      <h3 className="text-1xl mb-1 text-gray-900">Hello {user?.fullName}</h3>
      <h3 className="text-1xl text-gray-900">{response?.state?.status}</h3>
    </div>
  );
}
