import { useState } from 'react';
import { FirstStep } from '../../../app/components/Onboarding/Steps/FirstStep';
import { SecondStep } from '../../../app/components/Onboarding/Steps/SecondStep';
import { ThirdStep } from '../../../app/components/Onboarding/Steps/ThirdStep';
import { FourthStep } from '../../../app/components/Onboarding/Steps/FourthStep';
import { FifthStep } from '../../../app/components/Onboarding/Steps/FifthStep';
import { SixthStep } from '../../../app/components/Onboarding/Steps/SixthStep';
import { SeventhStep } from '../../../app/components/Onboarding/Steps/SeventhStep';
import { FeedbackModal } from '../../../app/components/Onboarding/FeedbackModal';
import { useNavigate } from 'react-router-dom';

export enum Steps {
  First = 0,
  Second = 1,
  Third = 2,
  Fourth = 3,
  Fifth = 4,
  Sixth = 5,
  Seventh = 6
}

export enum OnboardingStep {
  InitialSetup = 'initial_setup',
  FeatureSelection = 'feature_selection',
  ApprovalWorkflows = 'approval_workflows',
  ImportUsers = 'import_users',
  SendInvitations = 'send_invitations',
  SendInvitationsMessageTemplate = 'send_invitations_message_template',
  SendInvitationsSummary = 'send_invitations_summary',
  Complete = 'complete',
  AbsenceManagementConfiguration = 'absence_management_configuration',
}

export function OnboardingLayout() {
  const [currentStep, setCurrentStep] = useState(Steps.First);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [features, setFeatures] = useState<string>('');

  const navigate = useNavigate();

  const handleNext = () => {
    if (currentStep < Steps.Seventh) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > Steps.First) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const handleSkipForNow = () => {
    navigate('/');
  };

  const renderForm = () => {
    switch (currentStep) {
      case Steps.First:
        return (
          <FirstStep
            currentStep={currentStep}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            handleSkipForNow={handleSkipForNow}
          />
        );
      case Steps.Second:
        return (
          <SecondStep
            currentStep={currentStep}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            handleSkipForNow={handleSkipForNow}
            features={features}
            setFeatures={setFeatures}
          />
        );
      case Steps.Third:
        return (
          <ThirdStep
            currentStep={currentStep}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            handleSkipForNow={handleSkipForNow}
          />
        );
      case Steps.Fourth:
        return (
          <FourthStep
            currentStep={currentStep}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            handleSkipForNow={handleSkipForNow}
          />
        );
      case Steps.Fifth:
        return (
          <FifthStep
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            features={features}
            handleSkipForNow={handleSkipForNow}
          />
        );
      case Steps.Sixth:
        return (
          <SixthStep
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            handleSkipForNow={handleSkipForNow}
          />
        );
      case Steps.Seventh:
        return (
          <SeventhStep
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            setIsFeedbackModalOpen={setIsFeedbackModalOpen}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="flex w-screen from-[rgb(254,233,233,0.5)] via-[rgb(255,190,157,0.6)] to-[rgb(254,233,233,0.5)] md:bg-[radial-gradient(ellipse_at_top_left,_var(--tw-gradient-stops))]">
        {renderForm()}
      </div>
      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        onClose={() => setIsFeedbackModalOpen(false)}
      />
    </>
  );
}
