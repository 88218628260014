import { Icon, TextInput } from '@tremor/react';
import { RiSearchLine } from '@remixicon/react';
import { EyeIcon, FunnelIcon } from '@heroicons/react/24/solid';
import {
  CheckIcon,
  ChevronLeftIcon,
  UserIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Menu, Popover, Position } from 'evergreen-ui';
import { Checkbox } from '../../../ui/component/checkbox';
import { CustomDatePicker } from '../../../app/components/Onboarding/Steps/SixthStep/components/CustomDatePricker';

const fakeEmployee = [
  { id: '1', name: 'Employee 1' },
  { id: '2', name: 'Employee 2' },
  { id: '3', name: 'Employee 3' },
];

const fakeStatus = [
  {
    id: 'pending',
    label: 'Pending',
  },
  {
    id: 'approved',
    label: 'Approved',
  },
  {
    id: 'rejected',
    label: 'Rejected',
  },
];

export function TimesheetsTable({
  timesheetData,
  handleDeclineModal,
  handleApprove,
  handleTimesheetsDetails,
}: any) {
  return (
    <div className="mt-2 rounded-xl border-[1px] border-gray-100 px-6 py-2 shadow">
      <div className="flex flex-row justify-end gap-4">
        <TextInput
          icon={RiSearchLine}
          placeholder="Search"
          className="w-auto lg:w-[200px]"
        />
        <Popover
          position={Position.LEFT}
          content={
            <Menu>
              <Menu.Group>
                <Popover
                  position={Position.LEFT}
                  content={
                    <Menu>
                      <Menu.Group>
                        <div className="flex flex-col gap-1">
                          {fakeEmployee.map((employee) => (
                            <Menu.Item
                              key={employee.id}
                              // onClick={handleCheckboxClick}
                            >
                              <div className="flex flex-row items-center gap-2">
                                <Checkbox
                                  className="rounded-md border-[1px]"
                                  color="rose"
                                  // checked={selectedOwners.includes(owner.id)}
                                  // onChange={() => {
                                  //   setSelectedOwners((prev) =>
                                  //     prev.includes(owner.id)
                                  //       ? prev.filter((id) => id !== owner.id)
                                  //       : [...prev, owner.id],
                                  //   );
                                  // }}
                                />
                                {employee.name}
                              </div>
                            </Menu.Item>
                          ))}
                        </div>
                        <div className="mt-1 flex flex-row justify-center gap-2">
                          <button
                            className=" w-[40%] rounded-lg border-[1px] py-1 shadow"
                            // onClick={handleOwnerClearAll}
                          >
                            Clear all
                          </button>
                          <button
                            // onClick={handleOwnerApply}
                            className="w-[40%] rounded-lg border-[1px] shadow"
                          >
                            Apply
                          </button>
                        </div>
                      </Menu.Group>
                    </Menu>
                  }
                >
                  <Menu.Item>
                    <div className="flex flex-row flex-nowrap gap-2">
                      <ChevronLeftIcon className="w-4" />
                      Employee
                    </div>
                  </Menu.Item>
                </Popover>

                <Popover 
                  position={Position.LEFT}
                  content={
                    <div className='flex flex-col p-1'>
                      <CustomDatePicker />
                      <div className="mt-1 pb-2 px-2">
                          <button
                            // onClick={handleOwnerApply}
                            className="w-full py-1 text-white rounded-lg bg-[#DC2626]"
                          >
                            Apply
                          </button>
                        </div>
                    </div>
                  }
                >
                  <Menu.Item>
                    <div className="flex flex-row flex-nowrap gap-2">
                      <ChevronLeftIcon className="w-4" />
                      Dates
                    </div>
                  </Menu.Item>
                </Popover>

                <Popover
                  position={Position.LEFT}
                  content={
                    <Menu>
                      <Menu.Group>
                        <div className="flex flex-col gap-1">
                          {fakeStatus.map((status) => (
                            <Menu.Item
                              key={status.id}
                              // onClick={handleCheckboxClick}
                            >
                              <div className="flex flex-row items-center gap-2">
                                <Checkbox
                                  className="rounded-md border-[1px]"
                                  color="rose"
                                  // checked={selectedOwners.includes(owner.id)}
                                  // onChange={() => {
                                  //   setSelectedOwners((prev) =>
                                  //     prev.includes(owner.id)
                                  //       ? prev.filter((id) => id !== owner.id)
                                  //       : [...prev, owner.id],
                                  //   );
                                  // }}
                                />
                                {status.label}
                              </div>
                            </Menu.Item>
                          ))}
                        </div>
                        <div className="mt-1 flex flex-row justify-center gap-2">
                          <button
                            className=" w-[40%] rounded-lg border-[1px] py-1 shadow"
                            // onClick={handleOwnerClearAll}
                          >
                            Clear all
                          </button>
                          <button
                            // onClick={handleOwnerApply}
                            className="w-[40%] rounded-lg border-[1px] shadow"
                          >
                            Apply
                          </button>
                        </div>
                      </Menu.Group>
                    </Menu>
                  }
                >
                  <Menu.Item>
                    <div className="flex flex-row flex-nowrap gap-2">
                      <ChevronLeftIcon className="w-4" />
                      Status
                    </div>
                  </Menu.Item>
                </Popover>
              </Menu.Group>
            </Menu>
          }
        >
          <button
            className="flex items-center 
            rounded-lg border-[1px] px-3 font-normal shadow"
          >
            <Icon icon={FunnelIcon} size="sm" color="blue" />
            Filter
          </button>
        </Popover>
      </div>
      <div className="overflow-hidden">
        <div className=" flow-root w-full">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <table className="w-full divide-y divide-gray-300">
                <thead>
                  <th
                    scope="col"
                    className=" flex items-center gap-3 py-3.5 pl-4 text-left text-sm font-medium text-gray-500 sm:pl-0"
                  >
                    Employee
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    Work time
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    Hours worked
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    Approval status
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3.5 text-left text-sm font-medium text-gray-500"
                  ></th>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {timesheetData?.length > 0 &&
                    timesheetData?.map((item: any) => (
                      <tr key={item._id}>
                        <td className="whitespace-nowrap py-2 pl-4 text-sm text-gray-900 sm:pl-0">
                          <div className="flex items-center gap-2">
                            <UserIcon className="w-5" />
                            <div className="flex flex-col ">
                              <p>{item?.user?.name}</p>
                              <p className="text-sm text-gray-500">
                                {item?.user?.email}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm ">
                          {item?.date}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm ">
                          {item?.work_time?.from} - {item?.work_time?.to}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                          {item?.hours_worked}h
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm ">
                          {item?.approval_status === 'pending' ? (
                            <div className="flex max-w-[90px] justify-center rounded-lg bg-[#FFEDD5] px-2 py-1 text-[#C2410C]">
                              Pending
                            </div>
                          ) : item?.status === 'approved' ? (
                            <p className="flex max-w-[90px] justify-center rounded-lg bg-[#DCFCE7] px-2 py-1 text-[#15803D]">
                              Approved
                            </p>
                          ) : (
                            <div className="flex max-w-[90px] justify-center rounded-lg bg-[#FEE2E2] px-2 py-1 text-[#DC2626]">
                              Rejected
                            </div>
                          )}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                          {item?.details?.approval_status === 'approved' ? (
                            <p>{item?.approved_time}</p>
                          ) : (
                            <div className="flex flex-row items-center gap-5">
                              <button
                                onClick={() => handleDeclineModal(item?.id)}
                                className="rounded-full bg-[#DC2626] p-1"
                              >
                                <XMarkIcon className="w-3" color="white" />
                              </button>
                              <button
                                onClick={() => handleApprove(item)}
                                className="rounded-full bg-[#15803D] p-1"
                              >
                                <CheckIcon className="w-3" color="white" />
                              </button>
                              <button
                                onClick={() => handleTimesheetsDetails(item?.id)}
                                className="rounded-full"
                              >
                                <EyeIcon className="w-5" />
                              </button>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
