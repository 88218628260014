// 'use client';

import { Card, Divider, Select, SelectItem, LineChart, BarChart, DonutChart, AreaChart, Title, Text } from '@tremor/react';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ReportType } from '../../services/reportService';

interface DetailedReportProps {
  kpi?: ReportType;
  isModal?: boolean;
  onClose?: () => void;
}

// Add proper types for our data
type ChartData = {
  date: string;
  'Sick Leave': number;
  'Annual Leave': number;
  'Other': number;
}

type KpiCardData = {
  name: string;
  stat: string;
  change: string;
  changeType: 'positive' | 'negative';
}

// More realistic chart data
const chartdata: ChartData[] = [
  {
    date: '2024-01',
    'Sick Leave': 12,
    'Annual Leave': 8,
    'Other': 3
  },
  {
    date: '2024-02',
    'Sick Leave': 15,
    'Annual Leave': 10,
    'Other': 4
  },
  {
    date: '2024-03',
    'Sick Leave': 10,
    'Annual Leave': 12,
    'Other': 2
  },
  {
    date: '2024-04',
    'Sick Leave': 8,
    'Annual Leave': 15,
    'Other': 5
  },
  {
    date: '2024-05',
    'Sick Leave': 14,
    'Annual Leave': 9,
    'Other': 3
  },
  {
    date: '2024-06',
    'Sick Leave': 11,
    'Annual Leave': 13,
    'Other': 4
  }
];

const kpiData: KpiCardData[] = [
  {
    name: 'Total Absences',
    stat: '156',
    change: '+12.1%',
    changeType: 'positive'
  },
  {
    name: 'Average Duration',
    stat: '3.2 days',
    change: '-4.3%',
    changeType: 'negative'
  },
  {
    name: 'Compliance Rate',
    stat: '94.5%',
    change: '+2.3%',
    changeType: 'positive'
  }
];

// Add these types near the top of the file
type ChartComposition = {
  layout: 'single' | 'double' | 'triple' | 'quad';
  charts: {
    type: 'line' | 'bar' | 'donut' | 'area';
    title: string;
    data: any;
    size?: 'small' | 'medium' | 'large';
  }[];
};

type ReportTemplate = {
  id: string;
  name: string;
  description: string;
  composition: ChartComposition;
};

// Add report templates
const reportTemplates: Record<string, ReportTemplate> = {
  'absence': {
    id: 'absence',
    name: 'Absence Overview',
    description: 'Comprehensive view of absence patterns and trends',
    composition: {
      layout: 'quad',
      charts: [
        {
          type: 'line',
          title: 'Absence Trends',
          data: chartdata,
          size: 'large'
        },
        {
          type: 'donut',
          title: 'Leave Distribution',
          data: [
            { name: 'Sick Leave', value: 156 },
            { name: 'Annual Leave', value: 234 },
            { name: 'Other', value: 45 },
          ],
          size: 'medium'
        },
        {
          type: 'bar',
          title: 'Department Comparison',
          data: chartdata,
          size: 'medium'
        },
        {
          type: 'area',
          title: 'Cumulative Absences',
          data: chartdata,
          size: 'medium'
        }
      ]
    }
  },
  'compliance': {
    id: 'compliance',
    name: 'Compliance Report',
    description: 'Policy compliance and violation metrics',
    composition: {
      layout: 'triple',
      charts: [
        {
          type: 'line',
          title: 'Compliance Rate Trend',
          data: chartdata,
          size: 'large'
        },
        {
          type: 'donut',
          title: 'Violation Categories',
          data: [
            { name: 'Late Submissions', value: 45 },
            { name: 'Missing Documents', value: 23 },
            { name: 'Policy Breaches', value: 12 },
          ],
          size: 'medium'
        },
        {
          type: 'bar',
          title: 'Department Compliance',
          data: chartdata,
          size: 'medium'
        }
      ]
    }
  }
};

// Add this function to render chart compositions
const renderChartComposition = (composition: ChartComposition) => {
  const { layout, charts } = composition;
  
  const renderChart = (chart: ChartComposition['charts'][0]) => {
    const commonProps = {
      className: chart.size === 'large' ? 'h-80' : 'h-60',
      data: chart.data,
      index: 'date',
      categories: ['Sick Leave', 'Annual Leave', 'Other'],
      colors: ['blue', 'green', 'orange'],
      yAxisWidth: 40,
      showLegend: true
    };

    switch (chart.type) {
      case 'line':
        return <LineChart {...commonProps} />;
      case 'bar':
        return <BarChart {...commonProps} />;
      case 'donut':
        return <DonutChart {...commonProps} category="value" index="name" />;
      case 'area':
        return <AreaChart {...commonProps} />;
      default:
        return null;
    }
  };

  return (
    <div className="space-y-6">
      {layout === 'single' && (
        <Card>
          <Title>{charts[0].title}</Title>
          {renderChart(charts[0])}
        </Card>
      )}
      
      {layout === 'double' && (
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
          {charts.map((chart, i) => (
            <Card key={i}>
              <Title>{chart.title}</Title>
              {renderChart(chart)}
            </Card>
          ))}
        </div>
      )}
      
      {(layout === 'triple' || layout === 'quad') && (
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 mt-6">
          <Card className="lg:col-span-2">
            <Title>{charts[0].title}</Title>
            {renderChart(charts[0])}
          </Card>
          {charts.slice(1).map((chart, i) => (
            <Card key={i}>
              <Title>{chart.title}</Title>
              {renderChart(chart)}
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

function ContentPlaceholder() {
  return (
    <div className="relative h-full overflow-hidden rounded bg-gray-50 dark:bg-dark-tremor-background-subtle">
      <svg
        className="absolute inset-0 h-full w-full stroke-gray-200 dark:stroke-gray-700"
        fill="none"
      >
        <defs>
          <pattern
            id="pattern-1"
            x="0"
            y="0"
            width="10"
            height="10"
            patternUnits="userSpaceOnUse"
          >
            <path d="M-3 13 15-5M-5 5l18-18M-1 21 17 3"></path>
          </pattern>
        </defs>
        <rect
          stroke="none"
          fill="url(#pattern-1)"
          width="100%"
          height="100%"
        ></rect>
      </svg>
    </div>
  );
}

export default function DetailedReport({ kpi, isModal, onClose }: DetailedReportProps) {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  
  // Get the template based on the route or kpi
  const getReportTemplate = () => {
    if (id && reportTemplates[id]) {
      return reportTemplates[id];
    }
    if (kpi?.id) {
      return reportTemplates[`${kpi.id}`];
    }
    return reportTemplates['absence']; // default template
  };

  const template = getReportTemplate();

  useEffect(() => {
    if (!kpi && id) {
      setLoading(true);
      // Fetch report data using ID
      // fetchReportData(id).then(...)
      setLoading(false);
    }
  }, [id, kpi]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const content = (
    <div className="p-4 sm:p-6 lg:p-8">
      <header>
        <div className="sm:flex sm:items-center sm:justify-between">
          <div>
            <Title>{kpi?.name || 'Report Details'}</Title>
            {kpi?.description && (
              <Text className="mt-2">{kpi.description}</Text>
            )}
          </div>
          <div className="mt-4 sm:mt-0 sm:flex sm:items-center sm:space-x-2">
            {isModal && onClose && (
              <button 
                onClick={onClose}
                className="rounded-tremor-small border px-3 py-2"
              >
                Close
              </button>
            )}
            <Select
              className="w-full sm:w-fit [&>button]:rounded-tremor-small"
              enableClear={false}
              defaultValue="1"
            >
              <SelectItem value="1">Today</SelectItem>
              <SelectItem value="2">Last 7 days</SelectItem>
              <SelectItem value="3">Last 4 weeks</SelectItem>
              <SelectItem value="4">Last 12 months</SelectItem>
            </Select>
            <Select
              className="mt-2 w-full sm:mt-0 sm:w-fit [&>button]:rounded-tremor-small"
              enableClear={false}
              defaultValue="1"
            >
              <SelectItem value="1">All Departments</SelectItem>
              <SelectItem value="2">Engineering</SelectItem>
              <SelectItem value="3">Sales</SelectItem>
              <SelectItem value="4">Marketing</SelectItem>
            </Select>
          </div>
        </div>
      </header>
      
      <Divider />
      
      {/* KPI Cards */}
      <div className="mt-6">
        <dl className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {kpiData.map((item) => (
            <Card key={item.name}>
              <dt className="text-tremor-default font-medium text-tremor-content">
                {item.name}
              </dt>
              <dd className="mt-2 flex items-baseline space-x-2.5">
                <span className="text-tremor-metric font-semibold text-tremor-content-strong">
                  {item.stat}
                </span>
                <span
                  className={classNames(
                    item.changeType === 'positive' 
                      ? 'text-emerald-700 dark:text-emerald-500'
                      : 'text-red-700 dark:text-red-500',
                    'text-tremor-default font-medium'
                  )}
                >
                  {item.change}
                </span>
              </dd>
            </Card>
          ))}
        </dl>
      </div>

      {/* Replace chart section with composition renderer */}
      {renderChartComposition(template.composition)}
    </div>
  );

  return isModal ? (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl">
          {content}
        </div>
      </div>
    </div>
  ) : (
    content
  );
}