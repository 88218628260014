import { Input } from '../../../../../../ui/form/Input';
import { CustomTextArea } from '../components/CustomTextArea';
import { CustomDatePicker } from '../components/CustomDatePricker';
import { Popover, Position } from 'evergreen-ui';
import { CalendarDaysIcon } from '@heroicons/react/24/solid';

export function SecondSubStep({ formik, findError }: any) {
  return (
    <div className="flex flex-col">
      <h6 className="text-md font-semibold">Invitation Message</h6>
      <p className="text-gray-500">
        Provide a pre-written email invitation message
      </p>
      <div className="mt-3">
        <label htmlFor="subject" className="mb-2 block text-base font-medium">
          Subject
        </label>
        <Input
          type="text"
          placeholder="You're Invited to Join Our Team!"
          id="subject"
          name="subject"
          value={formik.values.subject}
          onChange={formik.handleChange}
          error={findError('subject')}
        />
      </div>
      <div className="mt-3">
        <CustomTextArea
          message={formik.values.message}
          setMessage={(value: string) => formik.setFieldValue('message', value)}
        />
      </div>
      <div className="mt-3 flex justify-end">
        <Popover
          position={Position.LEFT}
          content={
            <div className="flex flex-col px-7 py-5">
              <h6 className="text-lg">Select a date and time</h6>
              <div className="mt-5 flex flex-row gap-6 ">
                <CustomDatePicker />
                <div className="w-[100px]">
                  <label>Time</label>
                  <Input
                    type="text"
                    placeholder="HH:MM"
                    id="time"
                    name="time"
                    // value={formik.values.greetings}
                    // onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className="mt-1 flex justify-end">
                <button className="rounded-lg bg-[#DC2626] px-4 py-1 text-white">
                  Apply
                </button>
              </div>
            </div>
          }
        >
          <button className="flex items-center justify-center gap-2 rounded-lg bg-[#DC2626] px-4 py-2 text-white">
            <CalendarDaysIcon color="white" className="w-4" />
            Schedule send
          </button>
        </Popover>
      </div>
    </div>
  );
}
