import { CalendarHeader } from "./CalendarHeader";
import { CalendarBody } from "./CalendarBody";
import { CalendarProps } from "../../../types/RotaTypes";


export function Calendar({
  today, 
  daysMap, 
  rotas,
  setIsOpenNewRotaModal,
  handlePrevious,
  handleToday,
  handleNext,
  setMode,
  mode}: CalendarProps){
  return(
    <div className="flex overflow-hidden rounded-lg shadow lg:flex-1">
      <div className="flex-1 lg:flex lg:flex-col">
        <CalendarHeader 
          today={today}
          setIsOpenNewRotaModal={setIsOpenNewRotaModal}
          handlePrevious={handlePrevious}
          handleToday={handleToday}
          handleNext={handleNext}
          setMode={setMode}
          mode={mode}  />
        <CalendarBody today={today}  daysMap={daysMap} rotas={rotas} mode={mode}/>
      </div>
    </div>
  )
}