import { FolderIcon } from '@heroicons/react/24/outline';
import { Icon } from '@tremor/react';
import Folders from './components/Folders';
import Files from './components/Files';
import { useEffect, useState } from 'react';
import FolderDetails from './components/FolderDetails';
import CreateNewFolderModal from './components/CreateNewFolderModal';
import folderManagement from '../../network/folderManagement';
import filesManagement from '../../network/filesManagement';
import { AiGenerateIcon } from '../../assets/DocumentManagementIcon/AIGenerate';
import { RedstoneAiButton } from '../../ui/AiChatbot/inedx';
import { useChatbotModal } from '../../hooks/useChatbotModal';

export default function DocumentManagement() {
  const [showFolderDetails, setShowFolderDetails] = useState(false);
  const [currentFolder, setCurrentFolder] = useState({});
  const [isOpenNewFolderModal, setIsOpenNewFolderModal] = useState(false);
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);

  const { openChatbot } = useChatbotModal();

  const handleNewFolderModal = () => {
    setIsOpenNewFolderModal((prev) => !prev);
  };

  const refetchFolders = async () => {
    folderManagement
      .getFolders()
      .then((response) => {
        setFolders(response);
      })
      .catch((error) => console.log(error));
  };

  const refetchFiles = async () => {
    filesManagement
      .getDocuments()
      .then((response) => {
        setFiles(response?.data);
      })
      .catch((error) => console.log(error));
  };

  const handleShowFolderDetails = (id: string | number) => {
    folderManagement
      .getFolderById(id)
      .then((response) => {
        setCurrentFolder(response);
        setShowFolderDetails(true);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    refetchFolders();
    refetchFiles();
  }, []);

  return (
    <>
      {showFolderDetails ? (
        <>
          <FolderDetails
            folder={currentFolder}
            setShowFolderDetails={setShowFolderDetails}
            refetchFolder={handleShowFolderDetails}
            refetchFolders={refetchFolders}
            refetchFiles={refetchFiles}
          />
        </>
      ) : (
        <div className="flex w-full flex-col">
          <div className="border-b border-gray-200 pb-4">
            <div className="flex flex-row items-center justify-between">
              <h1 className="text-xl font-semibold text-gray-900">
                Document Management
              </h1>
              <div className="flex flex-row items-center gap-4">
                <button
                  onClick={handleNewFolderModal}
                  className="flex items-center gap-1 rounded-lg border border-gray-400 px-4 py-1 text-sm font-semibold"
                >
                  <Icon icon={FolderIcon} color="blue" size="sm" />
                  Create new folder
                </button>
                {/* <RedstoneAiButton onClick={openChatbot}/> */}
              </div>
            </div>
          </div>
          <Folders folders={folders} showFolder={handleShowFolderDetails} />
          <Files folders={folders} refetchFolders={refetchFolders} />
          <CreateNewFolderModal
            open={isOpenNewFolderModal}
            setOpen={setIsOpenNewFolderModal}
            refetchFolders={refetchFolders}
            files={files}
          />
        </div>
      )}
    </>
  );
}
