import { Transition } from '@headlessui/react';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { UserIcon } from '@heroicons/react/24/solid';
import { Fragment } from 'react';

export default function ChatbotHistory({ isOpen, setOpen}: any) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <div className="fixed mt-14">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-x-4"
          enterTo="opacity-100 translate-x-0"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-4"
        >
          <div className=" bg-[#FAFAFA] z-60 flex flex-col h-full min-w-[300px] max-w-[350px] max-h-[400px] min-h-[350px] rounded-xl shadow-md">
            <div className="px-3 py-2 ">
              <div className="flex justify-end gap-3">
                <button className='p-1 rounded-lg shadow bg-white'>
                  <PlusIcon className='w-4'/>
                </button>
              </div>
            </div>
            
          </div>
        </Transition.Child>
      </div>
    </Transition>
  );
}
