import { useContext } from "react";
import { ChatbotModalContext } from "../ui/context/chatbot";


export const useChatbotModal = () => {
  const context = useContext(ChatbotModalContext);
  if (!context) {
    throw new Error("Something went wrong. Please try again later.");
  }
  return context;
};