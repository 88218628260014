import { Menu } from '@headlessui/react';
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { CALENDAR_MODE__MONTH, CALENDAR_MODE__WEEK } from '../../../constants';

export function CalendarHeader({
  today,
  setIsOpenNewRotaModal,
  handlePrevious,
  handleToday,
  handleNext,
  setMode,
  mode
}: any) {
  return (
    <div className="flex flex-row items-center justify-between space-x-3 border-b border-gray-200 bg-[#FAFAFA] px-6 py-4 lg:flex-none">
      <h1 className="text-lg font-semibold text-gray-900">
        <time dateTime={today.format('YYYY-MM')} className="sm:hidden">
          {today.format('MMM YYYY')}
        </time>
        <time dateTime={today.format('YYYY-MM')} className="hidden sm:inline">
          {today.format('MMMM YYYY')}
        </time>
      </h1>
      <div className="items-cennter flex flex-row gap-3">
        <div className="flex items-center rounded-md shadow-sm md:items-stretch">
          <button
            onClick={handlePrevious}
            type="button"
            className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400  "
          >
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            onClick={handleToday}
            type="button"
            className=" border-b border-t border-gray-300 bg-white px-4 text-sm font-medium text-gray-700"
          >
            This {mode}
          </button>
          <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
          <button
            onClick={handleNext}
            type="button"
            className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400  "
          >
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className='flex items-center'>
          <Menu as="div">
            <Menu.Button 
              type='button'
              className='flex flex-row gap-2 items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm'>
                {mode} view
              <ChevronDownIcon className="w-5" />   
            </Menu.Button>
            <Menu.Items className="absolute z-10 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className='pu-1'>
              {[
                  CALENDAR_MODE__MONTH,
                  CALENDAR_MODE__WEEK,
                ].map((mode) => (
                  <Menu.Item key={mode}>
                    {({ active }) => (
                      <div
                        onClick={() => setMode(mode)}
                        className={`${active ? 'bg-gray-100 text-gray-900' :  'text-gray-700'}
                                    block cursor-pointer px-4 py-2 text-sm`}
                      >
                        {mode} view
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Menu>
        </div>
        <div className="flex items-center">
          <button
            onClick={() => setIsOpenNewRotaModal(true)}
            className="rounded-lg bg-[#DC2626] px-3 py-2 text-white shadow-sm"
          >
            Create new rota
          </button>
        </div>
      </div>
    </div>
  );
}
