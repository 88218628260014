import { useQuery } from 'react-query';
import { Moment } from 'moment';
import rota from '../../network/rota';

interface IRota {
  id: string;
  name: string;
  start_time: string;
  end_time: string;
  employees: any;
  color: string;
  published: any;
  published_at: any;
}

export function useGetRotas(daysMap: Moment[]) {
  return useQuery({
    queryKey: ['get_rotas'],
    queryFn: async () => {
      const results = await Promise.allSettled(
        daysMap.map((day) =>
          rota.getRotaSchedule(day.format('YYYY-MM-DD'))
        )
      );
      return results
        .filter((result) => result.status === 'fulfilled')
        .map((result: any) => result.value);
    },
    retry: false,
    onError: (error) => {
      console.error('Error fetching rotas:', error);
    },
  });
}