import axios from 'axios';
import { Dialog } from 'evergreen-ui';
import { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { TrashIcon } from '@heroicons/react/24/outline';

type Props = {
  isOpen: boolean;
  onClose(): void;
  refetchTeam(): void;
  team: any;
  handleTeamChange(team: any): void;
};

export const DeleteTeamDialog = ({
  isOpen,
  onClose,
  team,
  refetchTeam,
  handleTeamChange,
}: Props) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteTeam = async (id: string) => {
    setIsDeleting(true);
    await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_HOST}/v1/team/${id}`,
    });
    onClose();
    refetchTeam();
    setIsDeleting(false);
    handleTeamChange({
      id: '',
      name: '',
      description: '',
      lead_id: '',
      lead_name: '',
      email: '',
      role: '',
      image: '',
      users: [],
    });
  };

  return (
    <Dialog
      isShown={isOpen}
      onCloseComplete={onClose}
      hasFooter={false}
      hasHeader={false}
    >
      <div className="flex flex-col">
        <div className="mb-4 flex flex-row justify-between pt-6">
          <div className="rounded-[10px] bg-[#FEE9E9] p-3 shadow-sm">
            <TrashIcon color="#F04438" className="w-6" />
          </div>
          <XMarkIcon className="h-6 w-6 cursor-pointer" onClick={onClose} />
        </div>

        <div className="mb-1 flex flex-col gap-1">
          <div className="text-lg font-semibold">
            Are you sure you want to delete {team?.name}?
          </div>
        </div>

        <div className="mb-6 mt-6 flex flex-row justify-between gap-3">
          <button
            className="w-full rounded-lg border border-[#D0D5DD] bg-white px-[18px] py-[10px] text-base font-semibold text-[#344054] shadow-sm"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="w-full rounded-lg bg-[#DC2626] px-[18px] py-[10px] text-base font-semibold text-white shadow-sm "
            onClick={() => handleDeleteTeam(team?.id)}
            disabled={isDeleting}
          >
            Delete
          </button>
        </div>
      </div>
    </Dialog>
  );
};
