import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import UploadFileSection from './UploadFileSection';
import { ChangeEvent, useState } from 'react';
import filesManagement from '../../../network/filesManagement';
import { ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Menu, Popover, Position } from 'evergreen-ui';
import { Checkbox } from '../../../ui/component/checkbox';

export default function UploadNewFileModal({
  open,
  setOpen,
  refetch,
  folders,
  refetchFolders,
}: any) {
  const [newFile, setNewFile] = useState<File[]>([]);
  const [selectedFolderOption, setSelectedFolderOption] = useState<
    string | null
  >(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setNewFile((prevFiles) => [...prevFiles, ...Array.from(files)]);
    }
  };

  const handleDeleteFile = (file: File) => {
    setNewFile((prevFiles) =>
      prevFiles.filter((prevFile) => prevFile !== file),
    );
  };

  const handleFolderSelection = (folderId: string) => {
    if (selectedFolderOption === folderId) {
      setSelectedFolderOption(null);
    } else {
      setSelectedFolderOption(folderId);
    }
  };

  const handleSubmit = async () => {
    try {
      if (newFile) {
        for (const file of newFile) {
          const formData = new FormData();
          formData.append('file', file);
          await filesManagement.uploadFile(formData, selectedFolderOption);
        }
        setOpen(false);
        setNewFile([]);
        setSelectedFolderOption(null);
        refetch();
        refetchFolders();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center ">
          <DialogPanel
            transition
            className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in "
          >
            <div className="bg-white px-6  pt-5 ">
              <div className="flex flex-col">
                <div className="mt-4 text-left">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold text-gray-900"
                  >
                    Upload and attach files
                  </DialogTitle>
                  <div className="mb-4 mt-2">
                    <p className="text-sm text-gray-500">
                      Upload and attach the file to the relevant document so it
                      can be viewed by the people in your company. Permission
                      can be set after.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <UploadFileSection handleFileChange={handleFileChange} />

            <div className="mt-5 flex flex-col gap-1 px-6">
              <Popover
                position={Position.BOTTOM}
                content={
                  <Menu>
                    <Menu.Group>
                      <div className="flex w-full flex-col">
                        <Menu.Item>
                          <p className="text-gray-400">
                            Choose files that will appear in this folder
                          </p>
                        </Menu.Item>
                        {folders.map((item: any) => (
                          <Menu.Item
                            key={item.system.id}
                            onClick={() =>
                              handleFolderSelection(item.system.id)
                            }
                          >
                            <div className="flex flex-row items-center gap-2">
                              <Checkbox
                                color="white"
                                onChange={() =>
                                  handleFolderSelection(item.system.id)
                                }
                                checked={
                                  selectedFolderOption === item.system.id
                                }
                              />
                              {item.system.name}
                            </div>
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Group>
                  </Menu>
                }
              >
                <div className="flex w-full justify-between rounded-lg  border-[1px] border-gray-300 px-2 px-3 py-2 shadow-sm">
                  Select folder
                  <ChevronUpDownIcon className="w-5" color="gray" />
                </div>
              </Popover>
            </div>

            <div className="flex justify-center px-6 pt-4">
              {newFile.length > 0 ? (
                <ul>
                  {newFile.map((file, index) => (
                    <li
                      key={index}
                      className="flex justify-between text-sm text-gray-500"
                    >
                      {file.name}
                      <button
                        onClick={() => handleDeleteFile(file)}
                        className="ml-2"
                      >
                        <XMarkIcon className="w-4" />
                      </button>
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>

            <div className="flex flex-row justify-end gap-3 px-6 py-6">
              <button
                type="button"
                data-autofocus
                onClick={() => setOpen(false)}
                className="inline-flex justify-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                type="button"
                className="inline-flex justify-center rounded-md bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-sm  hover:bg-red-500"
              >
                Confirm
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
