import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import { renderDecision } from '../../../utils/holidayRequestsTab';
import { TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Icon } from '@tremor/react';

export default function DeleteModal({
  open,
  setOpen,
  requestedTimeAway,
  deleteTimeaway,
}: any) {
  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center ">
          <DialogPanel
            transition
            className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in "
          >
            <div className="bg-white px-6 pb-6 pt-5 ">
              <div className="flex flex-col">
                <div className="mb-2 flex flex-row justify-between">
                  <div className="flex h-12 w-12 justify-center rounded-lg bg-red-100">
                    <Icon icon={TrashIcon} color="red" size="md" />
                  </div>
                  <button
                    onClick={() => setOpen(false)}
                    className="h-12 w-12 rounded-lg hover:bg-gray-50"
                  >
                    <Icon icon={XMarkIcon} color="gray" size="lg" />
                  </button>
                </div>
                <div className="mt-4 text-left">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold text-gray-900"
                  >
                    Are you sure you want to delete holiday requests?
                  </DialogTitle>
                  <div className="mb-4 mt-2">
                    <p className="text-sm text-gray-500">
                      This action cannot be recovered
                    </p>
                  </div>
                  <div className="flex flex-col gap-y-2 text-base">
                    <p>From: {requestedTimeAway?.start}</p>
                    <p>Until: {requestedTimeAway?.end}</p>
                    <p>Status: {renderDecision(requestedTimeAway?.decision)}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-3 px-6 py-5">
              <button
                type="button"
                data-autofocus
                onClick={() => setOpen(false)}
                className="inline-flex w-auto w-full justify-center rounded-md bg-white px-3 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={() => deleteTimeaway(requestedTimeAway.id)}
                className="inline-flex w-auto w-full justify-center rounded-md bg-red-600 px-3 py-3 text-sm font-semibold text-white shadow-sm  hover:bg-red-500"
              >
                Delete
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
