import DescriptionList from './DescriptionList';

const ProfileDescription = ({ data }: any) => {
  const basicInformationPayload = {
    title: 'Basic information',
    attachments: false,
    fields: [
      {
        name: 'Preferred name',
        value: data?.preferred_first_name || data?.first_name,
      },
      {
        name: 'First name',
        value: data?.first_name,
      },
      {
        name: 'Last name',
        value: data?.last_name,
      },
    ],
  };

  const contactInformationPayload = {
    title: 'Contact information',
    attachments: false,
    fields: [
      {
        name: 'Telephone',
        value: data?.work_phone_number_primary,
      },
      {
        name: 'Login email',
        value: data?.email,
      },
    ],
  };

  const employmentInformationPayload = {
    title: 'Employment',
    attachments: false,
    fields: [
      {
        name: 'Position',
        value: data?.role_name,
      },
      {
        name: 'Team',
        value: data?.team,
      },
      {
        name: 'Office',
        value: data?.office_name,
      },
      {
        name: 'Date joined',
        value: data?.date_joined,
      },
    ],
  };

  const accountInformationPayload = {
    title: 'Account',
    fields: [
      {
        name: 'Permissions',
        value: data?.is_admin === true ? 'Admin' : 'Employee',
      },
    ],
  };

  const buildUserObject = (users: any[] = []) => {
    const firstRow = users.slice(0, 5);
    const remainingUsers = users.slice(5);
    
    return (
      <div className="space-y-2">
        <div className="flex flex-wrap">
          {firstRow.map((user) => (
            <a
              key={user.id}
              href={`/profile/${user.id}`}
              className="p-1"
              title={user.full_name}
            >
              <img
                alt=""
                src={user.safe_profile_picture_url}
                className="h-11 w-11 flex-none rounded-full bg-gray-100"
              />
            </a>
          ))}
        </div>
        {remainingUsers.length > 0 && (
          <div className="flex flex-wrap">
            {remainingUsers.map((user) => (
              <a
                key={user.id}
                href={`/profile/${user.id}`}
                className="p-1"
                title={user.full_name}
              >
                <img
                  alt=""
                  src={user.safe_profile_picture_url}
                  className="h-11 w-11 flex-none rounded-full bg-gray-100"
                />
              </a>
            ))}
          </div>
        )}
      </div>
    );
  };

  const managementInformationPayload = {
    title: 'Management',
    fields: [
      {
        name: 'Manager',
        id: data?.management?.manager?.id,
        image: data?.management?.manager?.safe_profile_picture_url,
        value: data?.management?.manager?.full_name,
      },
      {
        name: 'Direct reports',
        value: data?.management?.reports,
        mapper: buildUserObject,
      },
    ],
  };

  return (
    <>
      <DescriptionList payload={basicInformationPayload}></DescriptionList>
      <DescriptionList payload={contactInformationPayload}></DescriptionList>
      <DescriptionList payload={employmentInformationPayload}></DescriptionList>
      <DescriptionList payload={accountInformationPayload}></DescriptionList>
      <DescriptionList payload={managementInformationPayload}></DescriptionList>
    </>
  );
};

export default ProfileDescription;
