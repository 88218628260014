import axios from 'axios';

export type ReportType = {
  id: string;
  name: string;
  description: string;
  type: 'absence_rate' | 'absenteeism_frequency' | 'absence_rate_by_category' | 'lost_time_rate';
  category: 'absence' | 'compliance' | 'financial' | 'operational';
  viewType: 'page';
}

// Mock data for development
const mockReports: ReportType[] = [
  {
    id: '1',
    name: 'Absence Rate',
    description: 'Total number of absence days divided by total available working days',
    type: 'absence_rate',
    category: 'absence',
    viewType: 'page'
  },
  {
    id: '2',
    name: 'Absenteeism Frequency',
    description: 'Number of absence occurrences per employee',
    type: 'absenteeism_frequency',
    category: 'absence',
    viewType: 'page'
  },
  {
    id: '3',
    name: 'Leave Categories',
    description: 'Distribution of absences across different leave types',
    type: 'absence_rate_by_category',
    category: 'absence',
    viewType: 'page'
  },
  {
    id: '4',
    name: 'Lost Time Rate',
    description: 'Percentage of total working time lost to absences',
    type: 'lost_time_rate',
    category: 'operational',
    viewType: 'page'
  }
];

export const reportService = {
  async getReportTypes(): Promise<ReportType[]> {
    // Return mock data for now
    return Promise.resolve(mockReports);
    
    // Uncomment when API is ready
    // const response = await axios.get('/api/report-types');
    // return response.data;
  },

  async getReportData(type: string, params?: { company?: string; year?: string }) {
    // Mock data for now
    return Promise.resolve({
      data: [],
      metadata: {
        type,
        params
      }
    });
    
    // Uncomment when API is ready
    // const response = await axios.get(`/api/report/${type}`, { params });
    // return response.data;
  }
}; 