import axios from 'axios';
import { useQuery } from 'react-query';

const API_HOST = process.env.REACT_APP_API_HOST;

export function useProfileDetails(id?: string) {
  return useQuery({
    queryKey: ['profile-details', id],
    queryFn: async () => {
      const { data } = await axios.get(
        `${API_HOST}/v1/people/${id}`
      );
      return data;
    },
  });
}

export const useQueryHolidayRequest = async (page: number, count: number) => {
  const { data } = await axios({
    method: 'get',
    url: `${API_HOST}/v1/timeaway/requests`,
    params: { page, count },
  });
  return data;
};
