import { Dialog, Transition } from '@headlessui/react';
import {
  BuildingOffice2Icon,
  ChevronDoubleRightIcon,
  MapPinIcon,
  PencilSquareIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { CalendarDaysIcon } from '@heroicons/react/24/solid';
import { Fragment } from 'react';

export default function DepartmentDetails({
  isOpen,
  setOpen,
  currentDepartment,
  setIsShownAssignModal,
  setIsShownPermissionsModal,
}: any) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-30" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full justify-end  text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4"
            >
              <div className="flex w-full max-w-2xl transform flex-col justify-between overflow-hidden bg-white px-7 py-4 text-left align-middle shadow-xl transition-all">
                <div>
                  <div className="border-b">
                    <div className="flex flex-row items-center justify-start gap-3">
                      <button
                        onClick={() => setOpen(false)}
                        className="rounded-lg border p-2 shadow-sm"
                      >
                        <ChevronDoubleRightIcon className="w-4" />
                      </button>
                      <p className="font-medium">Department details</p>
                    </div>
                    <div className="flex flex-col gap-3 py-3">
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-row items-center gap-2">
                          <CalendarDaysIcon className="w-5" />
                          <p className="font-medium">Created date:</p>
                        </div>
                        <div className="w-[70%]">
                          <p>Sep 12, 2024</p>
                        </div>
                      </div>
                      <div className="flex flex-row justify-between">
                        <div>
                          <div className="flex flex-row gap-2">
                            <BuildingOffice2Icon className="w-5" />
                            <p className="font-medium">Department:</p>
                          </div>
                        </div>
                        <div className="w-[70%]">
                          <p>{currentDepartment.name}</p>
                          <p>{currentDepartment.description}</p>
                        </div>
                      </div>
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-row items-center gap-2">
                          <MapPinIcon className="w-5" />
                          <p className="font-medium">Location:</p>
                        </div>
                        <div className="w-[70%]">
                          <p>{currentDepartment.location.name}</p>
                        </div>
                      </div>
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-row items-center gap-2">
                          <UserIcon className="w-5" />
                          <p className="font-medium">Manager:</p>
                        </div>
                        <div className="w-[70%]">
                          <div className="flex flex-row items-center gap-3">
                            <UserIcon className="w-5" />
                            <div className="flex flex-col">
                              <p>
                                {currentDepartment.manager.first_name}{' '}
                                {currentDepartment.manager.first_name}
                              </p>
                              <p className="text-sm">
                                {currentDepartment.manager.email}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col border-b pb-5">
                    <div className="flex flex-row items-center justify-between pt-3">
                      <h6 className="font-medium">Assigned people</h6>
                      <button
                        onClick={() => setIsShownAssignModal(true)}
                        className="rounded-lg border p-2 shadow-sm"
                      >
                        <PencilSquareIcon className="w-5" />
                      </button>
                    </div>
                    <div className="mt-2 flex flex-col gap-1">
                      {currentDepartment?.assigned_people.length > 0 &&
                        currentDepartment?.assigned_people.map(
                          (person: any) => (
                            <div
                              key={person.id}
                              className="flex flex-row gap-2 border-b pb-2"
                            >
                              <UserIcon className="w-5" />
                              <p>
                                {person.first_name} {person.last_name}
                              </p>
                            </div>
                          ),
                        )}
                    </div>
                  </div>
                  <div className="mt-2 flex flex-col">
                    <div className="flex flex-row items-center justify-between pt-3">
                      <h6 className="font-medium">Permissions</h6>
                      <button
                        onClick={() => setIsShownPermissionsModal(true)}
                        className="rounded-lg border p-2 shadow-sm"
                      >
                        <PencilSquareIcon className="w-5" />
                      </button>
                    </div>
                    <div className="mt-2 flex flex-col gap-1">
                      {/* {currentDepartment?.permissions.map(
                        (permission: any) =>
                          permission.enabled && (
                            <div
                              key={permission.id}
                              className="flex flex-col gap-1 border-b pb-2"
                            >
                              <p>{permission.name}</p>
                              <p>{permission.description}</p>
                            </div>
                          ),
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-end gap-3">
                  <button
                    onClick={() => setOpen(false)}
                    className="rounded-lg px-4 py-1"
                  >
                    Cancel
                  </button>
                  <button className="rounded-lg bg-[#DC2626] px-4 py-1 text-white">
                    Save Changes
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
