import React, { createContext, useContext, useState, ReactNode } from "react";

interface ChatbotModalProps {
  isChatbotModalOpen: boolean;
  openChatbot: () => void;
  closeChatbot: () => void;
}

export const ChatbotModalContext = createContext<ChatbotModalProps | undefined>(undefined);

export const ChatbotModalProvider = ({ children }: { children: ReactNode }) => {
  const [isChatbotModalOpen, setIsChatbotModalOpen] = useState(false);

  const openChatbot = () => setIsChatbotModalOpen(true);
  const closeChatbot = () => setIsChatbotModalOpen(false);

  return (
    <ChatbotModalContext.Provider value={{ isChatbotModalOpen, openChatbot, closeChatbot }}>
      {children}
    </ChatbotModalContext.Provider>
  );
};
