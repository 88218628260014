import { useEffect, useState } from 'react';
import { TimesheetsTable } from './components/TimesheetsTable';
import DeclineModal from './components/DeclineModal';
import { useToast } from '../../utils/useToast';
import TimesheetsDetails from './components/TimesheetsDetails';
import { useGetTimesheets } from './hooks';
import timesheets from '../../network/timesheets';
import { Spinner } from '../../ui/Spinner';

export function TimesheetsRoute() {
  const [isOpenDeclineModal, setIsOpenDeclineModal] = useState(false);
  const [showTimesheetsDetails, setShowTimesheetsDetails] = useState(false);
  const [timesheetStatus, setTimesheetStatus] = useState({
    approved: 0,
    overtime: 0,
    pending: 0,
    rejected: 0,
    total: 0,
  });
  const [ currentTimesheet, setCurrentTimesheet ] = useState({
    id: '',
    approval_status: '',
    breaktime: '',
    date: '',
    employee: '',
    hours_worked: '',
    notes: '',
    user: {
      email: '',
      name: ''
    },
    work_time: {
      from: '',
      to: '',
    }
  });
  const [currentTimesheetId, setCurrentTimesheetId] = useState('');
  const [reason,setReason] = useState('');

  const { toast } = useToast();

  const { data: timesheetData, isLoading, refetch: refetchTimesheet } = useGetTimesheets();

  useEffect(() => {
    const getTimesheetstats = async () => {
      try {
        const {data} = await timesheets.getTimesheetsStatus();
        setTimesheetStatus(data)
      } catch (error) {
        console.log(error) 
      }
    }
    getTimesheetstats();
  }, [])

  const handleDeclineModal = (timesheetId: string) => {
    setIsOpenDeclineModal(true);
    setCurrentTimesheetId(timesheetId)
  };

  const handleDecline = async () => {
    if(currentTimesheetId?.length > 0) {
      try {
        const timesheet = {
          id: currentTimesheetId,
          decision: 'decline',
          comment: reason,
        }
        await timesheets.makeDecision(timesheet)
        refetchTimesheet();
        setIsOpenDeclineModal(false)
        toast({
          variant: 'success',
          title: `Decline Timesheets.
          ${timesheetData?.details?.employee?.name} will receive a connfirmaation notification.`,
        });
      } catch (error) {
        toast({
          variant: 'error',
          title: 'Something went wrong. Please try again later.'})
      }
    }
  };

  const handleApprove = async (timesheetData: any) => {
    try {
      const timesheet = {
        id: timesheetData?.id,
        decision: 'approve',
      }
      await timesheets.makeDecision(timesheet)
      refetchTimesheet();
      toast({
        variant: 'success',
        title: `Approved Timesheets.
        ${timesheetData?.user?.name} will receive a connfirmaation notification.`,
      });
    } catch (error) {
      toast({
        variant: 'error',
        title: 'Something went wrong. Please try again later.'})
    }
  };

  const handleTimesheetsDetails = async (id: string) => {
    try {

      const  { data }  = await timesheets.getTimesheetById(id);
      setCurrentTimesheet(data);
      setShowTimesheetsDetails(true);
      setCurrentTimesheetId(data?.id)
    } catch (error) {
      console.log(error)
    }
  }

  if(isLoading){
    return (
      <div className='h-[75vh] flex flex-col gap-3 justify-center items-center'>
        <Spinner size={10}/>
        <p className='font-semibold'>
          Loading...
        </p>
      </div>
    )
  }

  return (
    <>
      <div className="flex items-center justify-between border-b-2 border-[#E4E4E7] pb-4">
        <h1 className="text-3xl font-bold  text-tremor-content-strong">
          Timesheets
        </h1>
      </div>
      <div className="my-4 flex flex-row  gap-2">
        <div className='flex min-h-[50px] w-[33%] flex-col gap-1 rounded-lg p-4 bg-[#DBEAFE]'>
          <p className="text-sm text-[#71717A]">Total hours</p>
          <p className="text-2xl font-medium">{timesheetStatus.total}h</p>
        </div>
        <div className='flex min-h-[50px] w-[33%] flex-col gap-1 rounded-lg p-4 bg-[#FCE7F3]'>
          <p className="text-sm text-[#71717A]">Overtime</p>
          <p className="text-2xl font-medium">{timesheetStatus.overtime}h</p>
        </div>
        <div className='flex min-h-[50px] w-[33%] flex-col gap-1 rounded-lg p-4 bg-[#FFEDD5]'>
          <p className="text-sm text-[#71717A]">Pending Timesheets</p>
          <p className="text-2xl font-medium">{timesheetStatus.pending}h</p>
        </div>
        <div className='flex min-h-[50px] w-[33%] flex-col gap-1 rounded-lg p-4 bg-[#DCFCE7]'>
          <p className="text-sm text-[#71717A]">Approved Timesheets</p>
          <p className="text-2xl font-medium">{timesheetStatus.approved}h</p>
        </div>
        <div className='flex min-h-[50px] w-[33%] flex-col gap-1 rounded-lg p-4 bg-[#FEE2E2]'>
          <p className="text-sm text-[#71717A]">Rejected Timesheets</p>
          <p className="text-2xl font-medium">{timesheetStatus.approved}h</p>
        </div>
      </div>
      <div className="mt-5">
        <TimesheetsTable
          timesheetData={timesheetData}
          handleDeclineModal={handleDeclineModal}
          handleApprove={handleApprove}
          handleTimesheetsDetails={handleTimesheetsDetails}
        />
      </div>
      <DeclineModal 
        open={isOpenDeclineModal} 
        setOpen={setIsOpenDeclineModal}
        setReason={setReason}
        handleDecline={handleDecline}
         />
      <TimesheetsDetails 
        setOpen={setShowTimesheetsDetails}
        isOpen={showTimesheetsDetails}
        currentTimesheet={currentTimesheet}
        handleApprove={handleApprove}
        handleDecline={handleDecline}/>

    </>
  );
}
