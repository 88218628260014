import { Dialog } from 'evergreen-ui';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { updateTeam } from '../../../network';
import { FormGroup } from '../../../ui/form/FormGroup';
import { Input } from '../../../ui/form/Input';
import { Select } from '../../../ui/form/Select';
import { useToast } from '../../../utils/useToast';
import * as yup from 'yup';
import { AxiosResponse } from 'axios';

type Props = {
  isOpen: boolean;
  onClose(): void;
  currentTypesInfo?: any;
  children: any;
  buttonTitle: string;
};

export const LeaveTypeDialog = ({
  isOpen,
  onClose,
  currentTypesInfo,
  children,
  buttonTitle,
}: Props) => {
  const { toast } = useToast();

  const { mutateAsync } = useMutation<
    AxiosResponse<any>,
    Error,
    { id: string; data: any }
  >(async ({ id, data }) => updateTeam(id, data), {
    onSuccess: () => {},
    onError: () => {
      toast({
        variant: 'warning',
        title: 'Failed to update the team.',
      });
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: currentTypesInfo?.title || '',
      leaveStatus: currentTypesInfo?.leaveStatus || '',
      workExperience: currentTypesInfo?.workExperience || '',
      quantityOfDays: currentTypesInfo?.quantityOfDays || '',
    },
    validationSchema: yup.object({
      title: yup.string().required(),
      leaveStatus: yup.string().required(),
      workExperience: yup.string().required(),
      quantityOfDays: yup.string().required(),
    }),
    onSubmit: async (values) => {
      mutateAsync({ id: currentTypesInfo.id, data: { ...values } });

      closeDialog();
    },
  });

  function closeDialog() {
    formik.resetForm();
    onClose();
  }

  function findError(fieldName: keyof typeof formik.initialValues) {
    return formik.touched[fieldName] && formik.errors[fieldName]
      ? String(formik.errors[fieldName])
      : undefined;
  }

  return (
    <>
      <Dialog
        isShown={isOpen}
        onCloseComplete={onClose}
        hasFooter={false}
        hasHeader={false}
      >
        <div className="flex flex-col">
          <div className="my-5 flex flex-col gap-1">
            <p className="text-lg font-semibold">{children} </p>
          </div>

          <form onSubmit={formik.handleSubmit} className="space-y-3">
            <FormGroup label="Title" htmlFor="title-input">
              <Input
                id="title-input"
                type="text"
                name="title"
                error={findError('title')}
                value={formik.values.title}
                onChange={formik.handleChange}
                placeholder="Enter leave type title"
              />
            </FormGroup>
            <FormGroup label="Leave Status" htmlFor="leaveStatus-input">
              <Select
                id="leaveStatus-input"
                name="leaveStatus"
                value={formik.values.leaveStatus}
                onChange={formik.handleChange}
                error={findError('leaveStatus')}
              >
                {['Paid', 'Unpaid'].map((param: string, index: number) => (
                  <option key={index} value={param}>
                    {param}
                  </option>
                ))}
              </Select>
            </FormGroup>
            <FormGroup label="Work Experience" htmlFor="workExperience-input">
              <Input
                id="workExperience-input"
                type="text"
                name="workExperience"
                error={findError('workExperience')}
                value={formik.values.workExperience}
                onChange={formik.handleChange}
                placeholder="Example: +1 year"
              />
            </FormGroup>
            <FormGroup label="Quantity of days" htmlFor="quantityOfDays-input">
              <Input
                id="quantityOfDays-input"
                type="text"
                name="quantityOfDays"
                error={findError('quantityOfDays')}
                value={formik.values.quantityOfDays}
                onChange={formik.handleChange}
                placeholder="Example: 24"
              />
            </FormGroup>
          </form>

          <div className="mb-5 mt-5 flex flex-row justify-end gap-3">
            <button
              className="rounded-xl border border-[#D0D5DD] bg-white px-[18px] py-[10px] text-base font-semibold text-[#344054] shadow-sm"
              onClick={closeDialog}
            >
              Cancel
            </button>
            <button
              className="rounded-xl bg-[#DC2626] px-[18px] py-[10px] text-base font-semibold text-white shadow-sm disabled:bg-[#47546789]"
              onClick={() => formik.handleSubmit()}
            >
              {buttonTitle}
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
