import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import { UserIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { Textarea } from '../../../ui/form/Textarea';
import timeawayApi from '../../../network/timeaway';

interface ApproveModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  user: {
    id: string;
    type: string;
    full_name: string;
    start: string;
    end: string;
    date_requested: string;
    date_approved: string | null;
    decision: string;
    decision_by: string | null;
    details: string;
  };
  timeawayId: string;
  onApproveSuccess?: () => void;
}

export default function ApproveModal({ open, setOpen, user, timeawayId, onApproveSuccess }: ApproveModalProps) {
  const [isApprovewithNote, setIsApproveWithNote] = useState(false);
  const [note, setNote] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleApprove = async (withNote: boolean) => {
    try {
      setIsSubmitting(true);
      await timeawayApi.approveTimeawayRequest(timeawayId, withNote ? note : undefined);
      setOpen(false);
      onApproveSuccess?.();
    } catch (error) {
      console.error('Failed to approve request:', error);
      // You may want to show an error toast here
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center ">
          <DialogPanel
            transition
            className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in "
          >
            <div className="bg-white px-6  pt-2">
              <div className="flex flex-col">
                <div className="mt-4 flex justify-between">
                  <DialogTitle as="h6" className="font-semibold text-gray-900">
                    Approve
                  </DialogTitle>
                  <button onClick={() => setOpen(false)}>
                    <XMarkIcon className="w-4" />
                  </button>
                </div>
                <div className="mt-3">
                  <p className="text-sm text-gray-600">
                    The employee will receive a notification about their
                    request being approved.
                  </p>
                </div>
                <div className="mt-2 flex flex-row gap-2 rounded-lg bg-[#FAFAFA] px-4 py-2">
                  <UserIcon className="w-4" />
                  {user.full_name}
                </div>
                {isApprovewithNote ? (
                  <div className="mt-3 flex flex-col gap-1">
                    <p className="font-semibold">Comment</p>
                    <Textarea 
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      placeholder="Ex: Leave request approved. Please ensure all pending tasks are delegated before your absence."
                    />
                  </div>
                ) : null}
              </div>
            </div>
            {isApprovewithNote ? (
              <div className="flex flex-row justify-end gap-3 px-6 py-5">
                <button
                  onClick={() => setIsApproveWithNote(false)}
                  className="px-4 py-1"
                  disabled={isSubmitting}
                >
                  Back
                </button>
                <button 
                  onClick={() => handleApprove(true)}
                  className="rounded-lg bg-[#15803D] px-4 py-1 text-white"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Approving...' : 'Approve'}
                </button>
              </div>
            ) : (
              <div className="flex flex-row gap-3 px-6 py-5">
                <button
                  onClick={() => setIsApproveWithNote(true)}
                  className="w-[50%] rounded-lg bg-[#15803D] py-1 text-white"
                  disabled={isSubmitting}
                >
                  Approve with a note
                </button>
                <button 
                  onClick={() => handleApprove(false)}
                  className="w-[50%] rounded-lg bg-[#15803D] py-1 text-white"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Approving...' : 'Approve without comments'}
                </button>
              </div>
            )}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
