import { useParams } from 'react-router-dom';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@tremor/react';
import ProfileHolidayRequest from './components/ProfileHolidayRequest';
import ProfileDescription from './components/ProfileDescription';
import { useProfileDetails } from '../../hooks/useProfileDetails';
import { useAuth } from '../../hooks/useAuth';

const profile = {
  coverImageUrl:
    'https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80',
};

export function ProfileRoute() {
  const { id } = useParams();
  const { user } = useAuth();
  const profileId = id || user?.id;
  const { data: profileData } = useProfileDetails(profileId);

  return (
    <div>
      <div className="bg-white">
        <div className="bg-white">
          <img
            className="h-32 w-full object-cover lg:h-48"
            src={profile?.coverImageUrl}
            alt=""
          />
        </div>
        <div className="mx-auto max-w-5xl bg-white px-4 sm:px-6 lg:px-8">
          <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
            <div className="flex">
              <img
                className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                src={profileData?.safe_profile_picture_url}
                alt=""
              />
            </div>
            <div className="mt-6 bg-white sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
              <div className="mt-6 min-w-0 flex-1 sm:hidden 2xl:block">
                <h1 className="truncate text-2xl font-bold text-gray-900">
                  {profileData?.full_name}
                </h1>
              </div>
            </div>
          </div>
          <div className="mt-6 hidden min-w-0 flex-1 bg-white sm:block 2xl:hidden">
            <h1 className="truncate text-2xl font-bold text-gray-900">
              {profileData?.full_name}
            </h1>
          </div>
        </div>
      </div>

      {/* Tabs */}
      <TabGroup>
        <div className="mb-5 bg-white">
          <div className="border-b border-gray-200">
            <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                <TabList className="mt-1">
                  <Tab>Profile</Tab>
                  <Tab>Holiday Requests</Tab>
                </TabList>
              </nav>
            </div>
          </div>
        </div>
        <TabPanels>
          <TabPanel>
            <ProfileDescription data={profileData} />
          </TabPanel>
          <TabPanel>
            <ProfileHolidayRequest />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  );
}
