import { Icon } from '@tremor/react';
import { EmptyStateIcon } from '../../../assets/EmptyState/EmptyStateIcon';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import AddDepartmentModal from './AddDepartmentModal';
import { DepartmentsTable } from './DepartmentsTable';
import DepartmentDetails from './DepartmentDetails';
import AssignedPeopleModal from './AssignedPeopleModal';
import PermissionsModal from './PermissionsModal';
import EditDepartmentModal from './EditDepartmentModal';
import DeleteModal from './DeleteModal';
import departmentsManagement from '../../../network/departmentsManagement';
import { useToast } from '../../../utils/useToast';
import usePagination from '../../../hooks/usePagination';
import { Spinner } from '../../../ui/Spinner';

export function Departments() {
  const [isOpenAddNewDepartment, setIsOpenAddNewDepartment] = useState(false);
  const [selectedDepartments, setSelectdDepartments] = useState<string[]>([]);
  const [currentDepartment, setCurrnetDepartment] = useState({
    id: '',
    name: '',
    description: '',
    manager: '',
    location: {
      id:'',
      name: ''
    },
    permissions: [],
    assigned_people: [],
  });
  const [isShownDepartmentDetails, setIsShownDepartmentDetails] =
    useState(false);
  const [isShownAsignModal, setIsShownAssignModal] = useState(false);
  const [isShownPermissionsModal, setIsShownPermissionsModal] = useState(false);
  const [isShownEditModal, setIsShownEditModal] = useState(false);
  const [isShownDeleteModal, setIsShownDeleteModal] = useState(false);
  const [limit, setLimit] = useState(10);

  const { toast } = useToast();

  const {
    data: departments = [],
    page,
    totalItems,
    setDirectPage,
    refetch: refetchDepartments,
    isLoading
  } = usePagination({
    key: 'department',
    fetcher: departmentsManagement.getDepartments,
    initialPage: 0,
    initialItemsPerPage: limit,
  });

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectdDepartments(
        departments.map((department: any) => department.id),
      );
    } else {
      setSelectdDepartments([]);
    }
  };

  const handleSelectRow = (departmentId: string, checked: boolean) => {
    if (checked) {
      setSelectdDepartments((prev: string[]) => [...prev, departmentId]);
    } else {
      setSelectdDepartments((prev: string[]) =>
        prev.filter((id) => id !== departmentId),
      );
    }
  };

  const handleDepartmentDetails = (department: any) => {
    setCurrnetDepartment(department);
    setIsShownDepartmentDetails(true);
  };

  const handleEditDepartment = (department: any) => {
    setCurrnetDepartment(department);
    setIsShownEditModal(true);
  };

  const handleDeleteDepartmentModal = (department: any) => {
    setCurrnetDepartment(department);
    setIsShownDeleteModal(true);
  };

  const deleteDepartment = async (id: string) => {
    try {
      await departmentsManagement.deleteDepartment(id);
      refetchDepartments();
      toast({
        variant: 'success',
        title: 'Departmennt successfully deleted.',
      });
      setCurrnetDepartment({
        id: '',
        name: '',
        description: '',
        manager: '',
        location: {
          id: '',
          name: ''
        },
        permissions: [],
        assigned_people: [],
      });
    } catch (error) {
      toast({
        variant: 'error',
        title: 'Something went wrong. Please try again later.',
      });
    }
  };

  if(isLoading){
    return (
      <div className='h-[75vh] flex flex-col gap-3 justify-center items-center'>
        <Spinner size={10}/>
        <p className='font-semibold'>
          Loading...
        </p>
      </div>
    )
  }

  return (
    <>
      {departments.length <= 0 ? (
        <>
          <div className="flex h-[72vh] w-full flex-col items-center justify-center gap-4">
            <EmptyStateIcon />
            <div>
              <p className="text-center font-semibold">
                No departments have been created
              </p>
              <p className=" text-center">
                Start organizing your team by creating a department. Assign
                roles, <br /> streamline tasks, and improve workflow management
              </p>
            </div>
            <button
              onClick={() => setIsOpenAddNewDepartment(true)}
              className="flex items-center gap-1 rounded-lg bg-[#DC2626] px-5 py-1 text-white"
            >
              <Icon icon={PlusIcon} size="sm" style={{ color: '#FFFFFF' }} />
              Add department
            </button>
          </div>
        </>
      ) : (
        <DepartmentsTable
          departments={departments}
          setIsOpenAddNewDepartment={setIsOpenAddNewDepartment}
          selectedDepartments={selectedDepartments}
          handleSelectAll={handleSelectAll}
          handleSelectRow={handleSelectRow}
          handleDepartmentDetails={handleDepartmentDetails}
          handleEditDepartment={handleEditDepartment}
          handleDeleteDepartment={handleDeleteDepartmentModal}
          page={page}
          totalItems={totalItems}
          setDirectPage={setDirectPage}
          limit={limit}
        />
      )}

      <AddDepartmentModal
        open={isOpenAddNewDepartment}
        setOpen={setIsOpenAddNewDepartment}
        refetchDepartments={refetchDepartments}
      />
      <DepartmentDetails
        setOpen={setIsShownDepartmentDetails}
        isOpen={isShownDepartmentDetails}
        currentDepartment={currentDepartment}
        setIsShownAssignModal={setIsShownAssignModal}
        setIsShownPermissionsModal={setIsShownPermissionsModal}
      />
      <AssignedPeopleModal
        open={isShownAsignModal}
        setOpen={setIsShownAssignModal}
        currentDepartment={currentDepartment}
      />
      <PermissionsModal
        open={isShownPermissionsModal}
        setOpen={setIsShownPermissionsModal}
        currentDepartment={currentDepartment}
      />
      <EditDepartmentModal
        open={isShownEditModal}
        setOpen={setIsShownEditModal}
        currentDepartment={currentDepartment}
      />
      <DeleteModal
        open={isShownDeleteModal}
        setOpen={setIsShownDeleteModal}
        id={currentDepartment.id}
        handleDelete={deleteDepartment}
        header="department"
        content="Deleting this department is a permanent action and cannot be undone"
      />
    </>
  );
}
