import { useState } from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';
import { CalendarHeader } from './CalendarHeader';
import { ModesManager } from './modes/Manager';
import { CALENDAR_MODE__MONTH } from '../../constants';
import axios from 'axios';
import ElevenLabsWidget from '../../components/ElevenLabsWidget';

const { REACT_APP_API_HOST } = process.env;

export function CalendarRoute() {
  const [mode, setMode] = useState(CALENDAR_MODE__MONTH);
  const [today, setToday] = useState(moment());
  const [person, setPerson] = useState<any>();
  const [office, setOffice] = useState<any>();
  const [isMyEvents, setIsMyEvents] = useState<boolean>(false);

  const { isFetched, data: response } = useQuery({
    queryKey: ['home-calendar', person?.id || 0, office?.id || 0, isMyEvents],
    queryFn: async () => {
      const { data } = await axios({
        method: 'post',
        url: `${REACT_APP_API_HOST}/v1/calendar`,
        params: {
          ...(person && person?.id ? { person: person.id } : {}),
          ...(office && office?.id ? { office: office.id } : {}),
          ...(isMyEvents ? { myEvents: isMyEvents } : {}),
        },
      });

      return data;
    },
    staleTime: 300000,
    cacheTime: 900000,
  });

  function handlePrevious() {
    setToday((prev) =>
      prev.clone().subtract(1, mode as moment.unitOfTime.DurationConstructor),
    );
  }

  function handleToday() {
    setToday(moment());
  }

  function handleNext() {
    setToday((prev) =>
      prev.clone().add(1, mode as moment.unitOfTime.DurationConstructor),
    );
  }

  return (
    <div className="flex overflow-hidden rounded-lg bg-white shadow lg:flex-1">
      <div className="flex-1 lg:flex lg:flex-col">
        <CalendarHeader
          today={today}
          mode={mode}
          setMode={setMode}
          onPrevious={handlePrevious}
          onToday={handleToday}
          onNext={handleNext}
          setPerson={setPerson}
          setOffice={setOffice}
          setIsMyEvents={setIsMyEvents}
          isMyEvents={isMyEvents}
        />
        {isFetched && (
          <ModesManager
            mode={mode}
            events={response?.time_away || []}
            today={today}
          />
        )}
      </div>
      <ElevenLabsWidget isVisible={true} />
    </div>
  );
}
