import axios from 'axios';

const API_HOST = process.env.REACT_APP_API_HOST;

async function getDepartments(page?: number, limit?: number ) {
  const { data } = await axios.get(`${API_HOST}/v1/department`, {
    params: { limit, page },
  });
  return data;
}

async function creteDepartment(value: any) {
  const response = await axios.post(`${API_HOST}/v1/department`, value);
  return response;
}

async function deleteDepartment(id: string) {
  const response = await axios.delete(`${API_HOST}/v1/department/${id}`);
  return response;
}

export default {
  getDepartments,
  creteDepartment,
  deleteDepartment,
};
