import { Dialog } from 'evergreen-ui';
import { useState } from 'react';
import { StarIcon } from '@heroicons/react/24/outline';
import { Textarea } from '../../../../../../ui/form/Textarea';
import onboardingProcess from '../../../../../../network/onboardingProcess';

type Props = {
  isOpen: boolean;
  onClose(): void;
};

export function WaitListModal({ isOpen, onClose }: Props) {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [comment, setComment] = useState('');

  const hanleSubmit = async () => {
    const body = {
      rating,
      comment,
    };
    try {
      await onboardingProcess.sendOnboardingWaitList(body);
      onClose();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Dialog
        isShown={isOpen}
        onCloseComplete={onClose}
        hasFooter={false}
        hasHeader={false}
      >
        <div className="flex flex-col  py-2">
          <h6 className="font-semibold">We’d love to hear your thoughts!</h6>
          <div className="flex gap-2 py-3">
            {[1, 2, 3, 4, 5].map((star) => (
              <StarIcon
                key={star}
                onClick={() => setRating(star)}
                onMouseEnter={() => setHover(star)}
                onMouseLeave={() => setHover(0)}
                className={`h-6 w-6 cursor-pointer ${
                  star <= (hover || rating)
                    ? 'text-yellow-500'
                    : 'text-gray-300'
                }`}
                fill="currentColor"
              />
            ))}
          </div>
          <label className="mb-2 block text-base font-medium">Comment</label>
          <Textarea
            name="comment"
            placeholder="Comment..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />

          <div className="mt-3 flex justify-end">
            <button
              onClick={hanleSubmit}
              className="rounded-lg bg-[#DC2626] px-4 py-2 text-white"
            >
              Submit
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
