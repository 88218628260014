import moment from 'moment';
import classnames from 'classnames';
import { DayCellProps } from '../../../types/RotaTypes';
import { Menu, Pane, Popover } from 'evergreen-ui';
import {
  ArrowRightIcon,
  ChevronUpIcon,
  EllipsisVerticalIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { MinusCircleIcon, UserIcon } from '@heroicons/react/24/solid';

export function DayCell({ today, day, rotas }: DayCellProps) {
  const dayRotas = rotas.filter((rota) =>
    moment(day).isBetween(rota.start_date, rota.end_date, 'day', '[]'),
  );

  const uniqueDayRotas = Array.from(
    new Set(dayRotas.map((rota) => rota.id)),
  ).map((id) => dayRotas.find((rota) => rota.id === id));

  return (
    <div
      className={classnames(
        today.isSame(day, 'month') ? 'bg-white' : 'bg-gray-50 text-gray-500',
        'relative py-2',
      )}
    >
      <div className="px-3">
        <time
          dateTime={day.toISOString()}
          className={classnames('font-medium', {
            'flex h-6 w-6 items-center justify-center rounded-full bg-red-600 font-semibold text-white':
              moment().isSame(day, 'day'),
          })}
        >
          {day.format('D')}
        </time>
        <div className="mt-2 flex max-h-[150px] flex-col gap-1 overflow-y-auto pr-1">
          {uniqueDayRotas?.map((rota: any) => (
            <div key={rota.id}>
              <Popover
                content={
                  <div className="flex min-w-[350px] flex-col gap-2 px-6 py-4">
                    <div className="flex flex-row items-center justify-between">
                      <p>{rota.name}</p>
                      <Popover
                        content={
                          <Menu>
                            <Menu.Group>
                              <Menu.Item>
                                <div className="flex flex-row gap-2">
                                  <PencilSquareIcon className="w-5" />
                                  Edit shift
                                </div>
                              </Menu.Item>
                              <Menu.Item>
                                <div className="flex flex-row gap-2">
                                  <MinusCircleIcon
                                    className="w-5"
                                    color="red"
                                  />
                                  Remove this shift
                                </div>
                              </Menu.Item>
                            </Menu.Group>
                          </Menu>
                        }
                      >
                        <button>
                          <EllipsisVerticalIcon className="w-5" />
                        </button>
                      </Popover>
                    </div>
                    <div className="flex flex-row gap-2 ">
                      <label className="flex-1  rounded-lg border px-3 py-1 shadow-sm">
                        {moment(rota.start_time, 'HH:mm').format('hh:mm A')}
                      </label>
                      <ArrowRightIcon className="w-5" />
                      <label className="flex-1  rounded-lg border px-3 py-1 shadow-sm">
                        {moment(rota.end_time, 'HH:mm').format('hh:mm A')}
                      </label>
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="flex flex-row justify-between rounded-lg bg-[#FAFAFA] px-3 py-1">
                        <p>Assigned employees</p>
                        <ChevronUpIcon className="w-4" />
                      </div>
                      <div className="flex flex-col gap-1">
                        {rota?.employees.length > 0 &&
                          rota?.employees.map((employee: any) => (
                            <div
                              key={employee.id}
                              className="flex flex-row items-center gap-2 border-b py-2"
                            >
                              <UserIcon className="w-7" />
                              <div className="flex flex-col">
                                <p>{employee.name}</p>
                                <p className="text-gray-500">
                                  {employee.email}
                                </p>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                }
              >
                <div
                  className="cursor-pointer rounded-md border-2 p-1 px-2 py-[2px] text-xs hover:text-red-600"
                  style={{ borderColor: rota.color }}
                >
                  <p className="font-semibold">{rota.name}</p>
                  <p>
                    {moment(rota.start_time, 'HH:mm').format('hh:mm A')} -{' '}
                    {moment(rota.end_time, 'HH:mm').format('hh:mm A')}
                  </p>
                </div>
              </Popover>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
