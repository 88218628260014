import { useEffect, useState } from 'react';
import {
  OnboardingStep,
  Steps,
} from '../../../../../ui/layouts/OnboardingLayout';
import { FooterSection } from '../../FooterSection';
import { Stepper } from '../../Stepper';
import { WelcomeSection } from '../../WelcomeSection';
import onboardingProcess from '../../../../../network/onboardingProcess';

type Props = {
  currentStep: Steps;
  handleNext: () => void;
  handlePrevious: () => void;
  handleSkipForNow: () => void;
};

enum Options {
  SingleApproval = 'single-level',
  MultiApproval = 'multi',
  CustomApproval = 'custom',
}

export function ThirdStep({
  currentStep,
  handleNext,
  handlePrevious,
  handleSkipForNow,
}: Props) {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [approvers, setApprovers] = useState<string[]>([]);
  const [rules, setRules] = useState('');

  const toggleApprover = (approver: string) => {
    setApprovers((prev) =>
      prev.includes(approver)
        ? prev.filter((item) => item !== approver)
        : [...prev, approver],
    );
  };

  useEffect(() => {
    async function getApprovalWorkflows() {
      const { data } = await onboardingProcess.getOnboardingApprovalWorkflows();
      setOptions(data.data);
    }
    getApprovalWorkflows();
  }, []);

  useEffect(() => {
    async function getInitialData() {
      const { data } = await onboardingProcess.getOnboardingData(
        OnboardingStep.ApprovalWorkflows,
      );
      setSelectedOption(
        data?.data?.single_level_approval ||
          data?.data?.multiple_level_approval ||
          data?.data?.custom_workflows ||
          '',
      );
    }
    getInitialData();
  }, []);

  const handleSubmit = async () => {
    let body = {};
    if (selectedOption.length > 0) {
      if (selectedOption.includes(Options.SingleApproval)) {
        body = {
          single_level_approval: Options.SingleApproval,
        };
      }
      if (selectedOption.includes(Options.MultiApproval)) {
        body = {
          multiple_level_approval: Options.MultiApproval,
        };
      }
      if (selectedOption.includes(Options.CustomApproval)) {
        body = {
          custom_workflows: {
            approvers: approvers,
            rules: rules,
          },
        };
      }
      try {
        await onboardingProcess.onboardingProcess(
          OnboardingStep.ApprovalWorkflows,
          body,
        );
        handleNext();
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <div className="bg-center bg-no-repeat md:w-[40%] md:bg-form-image3 " />
      <div className="m-2 flex w-screen flex-col justify-between rounded-lg bg-white px-10 pt-8 md:w-[60%]">
        <div>
          <WelcomeSection handlePrev={handlePrevious} />
          <Stepper currentStep={currentStep} />

          <h3 className="my-4 text-lg font-semibold">
            Approval Workflow Setup
          </h3>
          <div className="flex flex-col gap-2">
            {options?.map(
              (option: {
                type: string;
                title: string;
                description: string;
                status: boolean;
              }) => (
                <div
                  key={option.type}
                  onClick={() => {
                    option.status
                      ? setSelectedOption(option.type)
                      : setSelectedOption('');
                  }}
                  className={`${
                    selectedOption === option.type ? 'bg-[#DBEAFE]' : ''
                  } 
                  ${option.status ? '' : 'bg-gray-100'}
                  flex cursor-pointer flex-col rounded-lg border-[1px] border-[#DBEAFE] px-3 py-2 hover:bg-[#DBEAFE]`}
                >
                  <h4 className="font-semibold">{option.title}</h4>
                  <p className='text-gray-500"'>{option.description}</p>
                </div>
              ),
            )}
          </div>
        </div>

        <FooterSection
          handleSkipForNow={handleSkipForNow}
          nextHandle={handleSubmit}
        />
      </div>
    </>
  );
}
