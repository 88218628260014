import { Steps } from '../../../../../ui/layouts/OnboardingLayout';
import { WelcomeSection } from '../../WelcomeSection';
import { Stepper } from '../../Stepper';
import {
  ChatBubbleOvalLeftEllipsisIcon,
  DocumentIcon,
  EnvelopeIcon,
  PhoneIcon,
  PlayCircleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/solid';
import { VideoPlayerPhoto } from '../../../../../assets/VideoPlayerPh';

type Props = {
  setCurrentStep: (data: Steps) => void;
  currentStep: Steps;
  handleNext: () => void;
  handlePrevious: () => void;
  setIsFeedbackModalOpen: (value: boolean) => void;
};

export function SeventhStep({
  setCurrentStep,
  currentStep,
  handleNext,
  handlePrevious,
  setIsFeedbackModalOpen,
}: Props) {
  return (
    <>
      <div className="bg-center bg-no-repeat md:w-[40%] md:bg-form-image7 " />
      <div className="m-2 flex w-screen flex-col justify-between rounded-lg bg-white px-10 pt-8 md:w-[60%] ">
        <div>
          <WelcomeSection handlePrev={handlePrevious} />
          <Stepper currentStep={currentStep} />
          <p className="mt-2 pb-2 text-xl font-semibold">
            You're all set! Start using Restone HR to streamline your HR process
          </p>
          <div className="h-[70vh] overflow-y-auto">
            <div className="mr-2 flex flex-col">
              <div className="flex flex-col gap-4">
                <div className="hrounded-lg mt-1 flex flex-row items-center gap-4 bg-[#FAFAFA] px-3 py-2">
                  <div className="flex h-[80px] w-[144px] items-center justify-center rounded-lg bg-[#DBEAFE]">
                    <DocumentIcon color="blue" className="w-8" />
                  </div>
                  <div>
                    <a 
                      href="https://support.redstonehr.com" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="hover:text-blue-600"
                    >
                      <p className="font-semibold">Documentation</p>
                      <p className="text-gray-500">
                        All the boring stuff that you (hopefully won't) need.
                      </p>
                    </a>
                  </div>
                </div>

                <div className="hrounded-lg mt-1 flex flex-row items-center gap-4 bg-[#FAFAFA] px-3 py-2">
                  <div className="flex h-[80px] w-[144px] items-center justify-center rounded-lg bg-[#FEE2E2]">
                    <QuestionMarkCircleIcon color="red" className="w-8" />
                  </div>
                  <div>
                    <a 
                      href="https://support.redstonehr.com" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="hover:text-blue-600"
                    >
                      <p className="font-semibold">FAQ</p>
                      <p className="text-gray-500">
                        Everything you need to know about the product and billing.
                      </p>
                    </a>
                  </div>
                </div>

                {/* <div className="hrounded-lg mt-1 flex flex-row items-center gap-4 bg-[#FAFAFA] px-3 py-2">
                  <div className="flex items-center justify-center rounded-lg bg-[#DBEAFE]">
                    <VideoPlayerPhoto />
                  </div>
                  <div>
                    <p className="font-semibold">How to get started</p>
                    <p className="text-gray-500">
                      Jump right in — get an overview of the basics and get
                      started on building.
                    </p>
                    <button className=" mt-1 flex items-center gap-1 rounded-lg border px-3 shadow">
                      <PlayCircleIcon className="w-4" />
                      Watch video
                    </button>
                  </div>
                </div> */}
              </div>

              <label className="my-2 font-semibold">Support</label>
              <div className="flex flex-row gap-2">
                <div className="flex h-[120px] w-[33%] flex-col justify-between rounded-lg bg-[#FAFAFA] p-4">
                  <ChatBubbleOvalLeftEllipsisIcon className="w-8" />
                  <div>
                    <p>Chat to support</p>
                    <p className="font-semibold underline underline-offset-2">
                      support@redstonehr.com
                    </p>
                  </div>
                </div>
                <div className="flex h-[120px] w-[33%] flex-col justify-between rounded-lg bg-[#FAFAFA] p-4">
                  <EnvelopeIcon className="w-8" />
                  <div>
                    <p>Email</p>
                    <p className="font-semibold underline underline-offset-2">
                      sales@redstonehr.com
                    </p>
                  </div>
                </div>
                <div className="flex h-[120px] w-[33%] flex-col justify-between rounded-lg bg-[#FAFAFA] p-4">
                  <PhoneIcon className="w-8" />
                  <div>
                    <p>Call us</p>
                    <p className="underlineunderline font-semibold underline-offset-2">
                      +1 361 491 3357
                    </p>
                  </div>
                </div>
              </div>

              {/* <label className="my-2 font-semibold">Support</label>
              <div className="pb-4">
                <button className="rounded-lg bg-[#DC2626] px-4 py-2 text-white">
                  Schedule training
                </button>
              </div> */}
            </div>
          </div>
        </div>
        <div className="mb-4 mt-5 flex justify-end border-t border-tremor-border py-3">
          <button
            onClick={() => setIsFeedbackModalOpen(true)}
            className="rounded-lg bg-[#DC2626] px-4 py-2 text-white"
          >
            Finish Onboarding
          </button>
        </div>
      </div>
    </>
  );
}
