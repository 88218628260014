import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { PEOPLE_SEX } from '../../../constants';
import { FormGroup } from '../../../ui/form/FormGroup';
import { Select } from '../../../ui/form/Select';
import { Input } from '../../../ui/form/Input';
import { useRoles, useTeams, useOffices, usePeople } from '../hooks';
import { createUser } from '../../../network';
import { useState, useMemo } from 'react';
import { Dialog } from 'evergreen-ui';
import { AddUserIcon } from '../../../assets/AddUserIcon';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useToast } from '../../../utils/useToast';

type Props = {
  isOpen: boolean;
  onClose(): void;
  refetch(): void;
};

export function AddUserDialog({ isOpen, onClose, refetch }: Props) {
  const { mutateAsync } = useMutation(createUser);
  const { data: role_data } = useRoles();
  const { data: team_data } = useTeams();
  const { data: office_data } = useOffices();
  const { data: people_data } = usePeople();
  const [secondStep, setSecondStep] = useState<boolean>(false);
  const { toast } = useToast();

  // Decide on a default or first-in-list for each dropdown
  // If data is not loaded or empty, fall back to something safe (e.g. '')
  const defaultSex = PEOPLE_SEX[0]?.value || 'SEX_MALE';
  const defaultRole = useMemo(() => {
    // If you have role_data as an array, choose the first or your own default
    // Using 'SOFTWARE_ENGINEER' as an example fallback
    // Adjust for however your role_data is structured
    if (Array.isArray(role_data) && role_data.length > 0) {
      return role_data[0].value;
    }
    return '';
  }, [role_data]);

  const defaultTeam = useMemo(() => {
    if (Array.isArray(team_data) && team_data.length > 0) {
      return team_data[0].id;
    }
    return ''; // fallback
  }, [team_data]);

  const defaultOffice = useMemo(() => {
    if (Array.isArray(office_data) && office_data.length > 0) {
      return office_data[0].id;
    }
    return ''; // fallback
  }, [office_data]);

  const defaultManager = useMemo(() => {
    if (
      people_data?.data &&
      Array.isArray(people_data.data) &&
      people_data.data.length > 0
    ) {
      return people_data.data[0].id;
    }
    return ''; // fallback
  }, [people_data]);

  // Use enableReinitialize to reset initial form values
  // anytime your data arrays finish loading
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      sex: defaultSex,
      email: '',
      role: defaultRole,
      team: defaultTeam,
      office: defaultOffice,
      manager: defaultManager,
      phone: '',
      paidHoliday: 10,
      unpaidHoliday: 10,
      remoteWorkingPerWeek: 2,
      paidSickLeavePerYear: 30,
    },
    onSubmit: async ({ ...restValues }) => {
      await mutateAsync({ ...restValues });
      toast({
        variant: 'success',
        title: 'User successfully added.',
      });
      refetch();
      closeDialog();
    },
  });

  function closeDialog() {
    formik.resetForm();
    onClose();
    setSecondStep(false);
  }

  function findError(
    fieldName: keyof typeof formik.initialValues,
  ): string | undefined {
    const error = formik.touched[fieldName]
      ? formik.errors[fieldName]
      : undefined;
    return typeof error === 'string' ? error : undefined;
  }

  return (
    <>
      <Dialog
        isShown={isOpen}
        onCloseComplete={closeDialog}
        hasFooter={false}
        hasHeader={false}
        preventBodyScrolling
      >
        <form className="mb-4" onSubmit={formik.handleSubmit}>
          <div className="flex flex-col">
            <div className="mb-4 flex flex-row justify-between pt-6">
              <div className="rounded-[10px] bg-[#FEE9E9] p-3 text-[#DC2626] shadow-sm">
                <AddUserIcon />
              </div>
              <XMarkIcon className="h-6 w-6 cursor-pointer" onClick={onClose} />
            </div>

            <div className=" flex flex-col gap-1">
              <div className="text-lg font-semibold">Add User</div>
              <div className="text-sm font-normal text-[#475467]">
                Your new project has been created. Invite colleagues to
                collaborate on this project.
              </div>
            </div>

            <div className="my-6 flex flex-row items-center justify-center gap-3">
              {!secondStep ? (
                <div className="rounded-full bg-[#DB7D01] p-3 text-white">
                  <div className="h-5 w-5  text-center text-sm font-medium ">
                    1
                  </div>
                </div>
              ) : (
                <div className="rounded-full bg-[#FDF0DE] p-3">
                  <CheckIcon className="h-5 w-5 " color="#DB7D01" />
                </div>
              )}

              <div className="h-[1px] bg-gray-200 px-8"></div>

              {!secondStep ? (
                <div className="rounded-full bg-[#F9FAFB] p-3 text-[#555A61]">
                  <div className="h-5 w-5  text-center text-sm font-medium ">
                    2
                  </div>
                </div>
              ) : (
                <div className="rounded-full bg-[#DB7D01] p-3 text-white">
                  <div className="h-5 w-5  text-center text-sm font-medium ">
                    2
                  </div>
                </div>
              )}
            </div>

            <div className="space-y-3">
              {!secondStep ? (
                <>
                  <FormGroup label="Full Name" htmlFor="name-input">
                    <Input
                      id="name-input"
                      type="text"
                      name="name"
                      error={findError('name')}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      placeholder="Enter name"
                    />
                  </FormGroup>

                  <FormGroup label="Select gender" htmlFor="sex-input">
                    <Select
                      id="sex-input"
                      name="sex"
                      value={formik.values.sex}
                      onChange={formik.handleChange}
                    >
                      {PEOPLE_SEX.map((param, index) => (
                        <option key={index} value={param.value}>
                          {param.title}
                        </option>
                      ))}
                    </Select>
                  </FormGroup>

                  <FormGroup label="Email Address" htmlFor="email-input">
                    <Input
                      id="email-input"
                      type="text"
                      name="email"
                      error={findError('email')}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      placeholder="you@example.com"
                    />
                  </FormGroup>

                  <FormGroup
                    label="Role"
                    htmlFor="role-input"
                  >
                    <Select
                      id="role-input"
                      name="role"
                      value={formik.values.role}
                      onChange={formik.handleChange}
                    >
                      {/* Example roles. Adjust to match your actual role_data. */}
                      <option value="HR_ADMIN">HR Admin</option>
                      <option value="EMPLOYEE">Employee</option>
                    </Select>
                  </FormGroup>

                  <FormGroup label="Office" htmlFor="office-input">
                    <Select
                      id="office-input"
                      name="office"
                      value={formik.values.office}
                      onChange={formik.handleChange}
                    >
                      {office_data &&
                        !office_data?.status &&
                        office_data.map((office: any, index: number) => (
                          <option key={index} value={office.id}>
                            {office.name}
                          </option>
                        ))}
                    </Select>
                  </FormGroup>

                  <FormGroup label="Select team" htmlFor="team-input">
                    <Select
                      id="team-input"
                      name="team"
                      value={formik.values.team}
                      onChange={formik.handleChange}
                    >
                      {team_data &&
                        !team_data?.status &&
                        team_data.map((team: any, index: number) => (
                          <option key={index} value={team.id}>
                            {team.name}
                          </option>
                        ))}
                    </Select>
                  </FormGroup>
                </>
              ) : (
                <>
                  <FormGroup label="Direct Manager" htmlFor="manager-input">
                    <Select
                      id="manager-input"
                      name="manager"
                      value={formik.values.manager}
                      onChange={formik.handleChange}
                    >
                      {people_data?.data?.map((person: any, index: number) => (
                        <option key={index} value={person.id}>
                          {person.full_name}
                        </option>
                      ))}
                    </Select>
                  </FormGroup>

                  <FormGroup label="Phone number" htmlFor="phone-input">
                    <Input
                      id="phone-input"
                      type="text"
                      name="phone"
                      error={findError('phone')}
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      placeholder="+11 222 333 4444"
                    />
                  </FormGroup>

                  <FormGroup label="Paid Holiday" htmlFor="paid-holiday">
                    <Input
                      id="paid-holiday"
                      name="paidHoliday"
                      value={formik.values.paidHoliday}
                      onChange={formik.handleChange}
                      type="number"
                      min={0}
                      max={365}
                      placeholder="Enter days"
                    />
                  </FormGroup>

                  <FormGroup label="Unpaid Holiday" htmlFor="unpaid-holiday">
                    <Input
                      id="unpaid-holiday"
                      name="unpaidHoliday"
                      value={formik.values.unpaidHoliday}
                      onChange={formik.handleChange}
                      type="number"
                      min={0}
                      max={365}
                      placeholder="Enter days"
                    />
                  </FormGroup>

                  <FormGroup
                    label="Remote Working Per Week"
                    htmlFor="remote-working-per-week"
                  >
                    <Input
                      id="remote-working-per-week"
                      name="remoteWorkingPerWeek"
                      value={formik.values.remoteWorkingPerWeek}
                      onChange={formik.handleChange}
                      type="number"
                      min={0}
                      max={365}
                      placeholder="Enter days"
                    />
                  </FormGroup>

                  <FormGroup
                    label="Paid Sick Leave Per Year"
                    htmlFor="paid-sick-leave-per-year"
                  >
                    <Input
                      id="paid-sick-leave-per-year"
                      name="paidSickLeavePerYear"
                      value={formik.values.paidSickLeavePerYear}
                      onChange={formik.handleChange}
                      type="number"
                      min={0}
                      max={365}
                      placeholder="Enter days"
                    />
                  </FormGroup>
                </>
              )}
            </div>

            <div className="mb-6 mt-8 flex flex-row justify-between gap-3">
              <button
                type="button"
                className="w-full rounded-lg border border-[#D0D5DD] bg-white px-[18px] py-[10px] text-base font-semibold text-[#344054] shadow-sm"
                onClick={onClose}
              >
                Cancel
              </button>
              {secondStep ? (
                <button
                  type="button"
                  onClick={() => formik.handleSubmit()}
                  className="w-full rounded-lg bg-[#DC2626] px-[18px] py-[10px] text-base font-semibold text-white shadow-sm"
                >
                  Add
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => setSecondStep(true)}
                  className="w-full rounded-lg bg-[#DC2626] px-[18px] py-[10px] text-base font-semibold text-white shadow-sm"
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
}
