import { useFormik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import {
  Divider,
  NumberInput,
  SelectItem,
  TextInput,
  Select,
  Textarea,
} from '@tremor/react';
import { Slot } from '@radix-ui/react-slot';
import clsx, { type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

import {
  COMPANY_SYZES,
  CURRENCY_TYPE,
  JOB_VACANCY_TYPES,
  SALARY_TIME,
  SKILLS,
  WORK_LEVEL,
  WORKING_OPTIONS,
  WORKPLACE_TYPES,
} from '../../../../constants';
import { MultiSelect, MultiSelectItem } from '@tremor/react';
import { useToast } from '../../../../utils/useToast';
import { useMutation } from 'react-query';
import { createJob } from '../../../../network';
import React, { useState, useEffect } from 'react';
import Notification from '../../../../ui/component/notification';

interface CardProps extends React.ComponentPropsWithoutRef<'div'> {
  asChild?: boolean;
}

export function cx(...args: ClassValue[]) {
  return twMerge(clsx(...args));
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ className, asChild, ...props }, forwardedRef) => {
    const Component = asChild ? Slot : 'div';
    return (
      <Component
        ref={forwardedRef}
        className={cx(
          // base
          'relative w-full rounded-lg border p-6 text-left shadow-sm',
          // background color
          'bg-white ',
          // border color
          'border-gray-200 dark:border-gray-900',
          className,
        )}
        tremor-id="tremor-raw"
        {...props}
      />
    );
  },
);

const CreateNewVacancy = () => {
  const { mutateAsync } = useMutation(createJob);
  const { toast } = useToast();

  const formik = useFormik({
    initialValues: {
      jobTitle: '',
      jobLocation: '',
      minimumSalary: '',
      maximumSalary: '',
      companyName: '',
      department: '',
      description: '',
      visibility: 'private',
      salaryDescription: '',
      jobType: 'Full-time',
      workplaceType: 'On-site',
      workLevel: 'Middle',
      salaryTime: 'Per month',
      currency: 'USD',
      skills: [],
    },
    validationSchema: yup.object({
      jobTitle: yup.string().required('Job title is required'),
      minimumSalary: yup.string().required('Minimum salary is required'),
      maximumSalary: yup.string().required('Maximum salary is required'),
      description: yup.string().required('Description is required'),
      jobLocation: yup.string().required('Job location is required'),
      department: yup.string().required('Department is required'),
      visibility: yup.string().required('Visibility is required'),
      salaryDescription: yup
        .string()
        .required('Salary description is required'),
      jobType: yup.string().required('Job type is required'),
      workplaceType: yup.string().required('Workplace type is required'),
      workLevel: yup.string().required('Workplace type is required'),
      salaryTime: yup.string().required('Workplace type is required'),
      currency: yup.string().required('Currency type is required'),
      skills: yup
        .array()
        .min(1, 'Skills are required')
        .required('Skills are required'),
    }),
    onSubmit: async (values) => {
      try {
        await mutateAsync(values);
        toast({
          variant: 'success',
          title: 'Job successfully posted.',
        });
        formik.resetForm();
      } catch (error) {
        toast({
          variant: 'error',
          title: 'Something went wrong. Please try again later.',
        });
      }
    },
    enableReinitialize: true,
    validateOnChange: true,
  });

  const handleSelect = (property: string, selectValue: any) => {
    formik.setFieldValue(property, selectValue);
  };

  return (
    <>
      <Card>
        <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-1 gap-10 md:grid-cols-3">
            <div>
              <h2 className="dark:text-dark-tremor-content-strong font-semibold text-tremor-content-strong">
                Job information
              </h2>
              <p className="dark:text-dark-tremor-content mt-1 text-tremor-default leading-6 text-tremor-content">
                General information about the vacancy.
              </p>
            </div>
            <div className="sm:max-w-3xl md:col-span-2">
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-6">
                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="job-title"
                    className="dark:text-dark-tremor-content-strong text-tremor-default font-medium text-tremor-content-strong"
                  >
                    Job title
                  </label>
                  <TextInput
                    type="text"
                    id="job-title"
                    name="jobTitle"
                    autoComplete="given-name"
                    placeholder="Software Engineer"
                    className="mb-1 mt-2"
                    value={formik.values.jobTitle}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.jobTitle && formik.errors.jobTitle ? (
                    <Notification
                      title="Unable to submit Vacancy"
                      message={formik.errors.jobTitle}
                      open={true}
                      isError={true}
                    />
                  ) : null}
                </div>

                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="job-type"
                    className="dark:text-dark-tremor-content-strong text-tremor-default font-medium text-tremor-content-strong"
                  >
                    Job type
                  </label>
                  <Select
                    id="job-type"
                    name="jobType"
                    defaultValue="Full-time"
                    className="mt-2"
                    enableClear={false}
                    value={formik.values.jobType}
                    onChange={(value) => handleSelect('jobType', value)}
                    onBlur={formik.handleBlur}
                  >
                    <SelectItem value="Full-time">Full-time</SelectItem>
                    <SelectItem value="Part-time">Part-time</SelectItem>
                    <SelectItem value="Contract">Contract</SelectItem>
                    <SelectItem value="Temporary">Temporary</SelectItem>
                    <SelectItem value="Volunteer">Volunteer</SelectItem>
                    <SelectItem value="Internship">Internship</SelectItem>
                  </Select>
                  {formik.touched.jobType && formik.errors.jobType ? (
                    <Notification
                      title="Unable to submit Vacancy"
                      message={formik.errors.jobType}
                      open={true}
                      isError={true}
                    />
                  ) : null}
                </div>
                <div className="col-span-full">
                  <label
                    htmlFor="description"
                    className="dark:text-dark-tremor-content-strong text-tremor-default font-medium text-tremor-content-strong"
                  >
                    Description
                  </label>
                  <Textarea
                    id="description"
                    name="description"
                    className="mt-2"
                    rows={4}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <p className="dark:text-dark-tremor-content mt-2 text-tremor-label text-tremor-content">
                    Note: description provided will be displayed externally.
                  </p>
                  {formik.touched.description && formik.errors.description ? (
                    <Notification
                      title="Unable to submit Vacancy"
                      message={formik.errors.description}
                      open={true}
                      isError={true}
                    />
                  ) : null}
                </div>
                <div className="col-span-full">
                  <label
                    htmlFor="job-location"
                    className="dark:text-dark-tremor-content-strong text-tremor-default font-medium text-tremor-content-strong"
                  >
                    Job location
                  </label>
                  <TextInput
                    type="text"
                    id="job-location"
                    name="jobLocation"
                    autoComplete="email"
                    placeholder="New York, NY"
                    className="mt-2"
                    value={formik.values.jobLocation}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.jobLocation && formik.errors.jobLocation ? (
                    <Notification
                      title="Unable to submit Vacancy"
                      message={formik.errors.jobLocation}
                      open={true}
                      isError={true}
                    />
                  ) : null}
                </div>
                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="workplace-type"
                    className="dark:text-dark-tremor-content-strong text-tremor-default font-medium text-tremor-content-strong"
                  >
                    Workplace type
                  </label>
                  <Select
                    id="workplace-type"
                    name="workplaceType"
                    defaultValue="On-site"
                    className="mt-2"
                    enableClear={false}
                    value={formik.values.workplaceType}
                    onChange={(value) => handleSelect('workplaceType', value)}
                    onBlur={formik.handleBlur}
                  >
                    <SelectItem value="On-site">On-site</SelectItem>
                    <SelectItem value="Remote">Remote</SelectItem>
                    <SelectItem value="Hybrid">Hybrid</SelectItem>
                  </Select>
                  {formik.touched.workplaceType &&
                  formik.errors.workplaceType ? (
                    <Notification
                      title="Unable to submit Vacancy"
                      message={formik.errors.workplaceType}
                      open={true}
                      isError={true}
                    />
                  ) : null}
                </div>
                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="work-level"
                    className="dark:text-dark-tremor-content-strong text-tremor-default font-medium text-tremor-content-strong"
                  >
                    Work seniority
                  </label>
                  <Select
                    id="work-level"
                    name="workLevel"
                    defaultValue="Middle"
                    className="mt-2"
                    enableClear={false}
                    value={formik.values.workLevel}
                    onChange={(value) => handleSelect('workLevel', value)}
                    onBlur={formik.handleBlur}
                  >
                    <SelectItem value="Trainee">Trainee</SelectItem>
                    <SelectItem value="Junior">Junior</SelectItem>
                    <SelectItem value="Middle">Middle</SelectItem>
                    <SelectItem value="Senior">Senior</SelectItem>
                    <SelectItem value="Lead">Lead</SelectItem>
                    <SelectItem value="Director">Director</SelectItem>
                  </Select>
                  {formik.touched.workLevel && formik.errors.workLevel ? (
                    <Notification
                      title="Unable to submit Vacancy"
                      message={formik.errors.workLevel}
                      open={true}
                      isError={true}
                    />
                  ) : null}
                </div>
                <div className="col-span-full">
                  <label
                    htmlFor="skills"
                    className="dark:text-dark-tremor-content-strong text-tremor-default font-medium text-tremor-content-strong"
                  >
                    Skills
                  </label>
                  <MultiSelect
                    id="skills"
                    name="skills"
                    className="mt-2"
                    value={formik.values.skills}
                    onChange={(value) => handleSelect('skills', value)}
                  >
                    {SKILLS.map((skill) => (
                      <MultiSelectItem key={skill.value} value={skill.value}>
                        {skill.title}
                      </MultiSelectItem>
                    ))}
                  </MultiSelect>
                  {formik.touched.skills && formik.errors.skills ? (
                    <Notification
                      title="Unable to submit Vacancy"
                      message={formik.errors.skills as string}
                      open={true}
                      isError={true}
                    />
                  ) : null}
                </div>
                <div className="col-span-full">
                  <label
                    htmlFor="job-location"
                    className="dark:text-dark-tremor-content-strong text-tremor-default font-medium text-tremor-content-strong"
                  >
                    Department
                  </label>
                  <TextInput
                    type="text"
                    id="department"
                    name="department"
                    placeholder="Research & Development"
                    className="mt-2"
                    value={formik.values.department}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.department && formik.errors.department ? (
                    <Notification
                      title="Unable to submit Vacancy"
                      message={formik.errors.department}
                      open={true}
                      isError={true}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <Divider className="my-14" />
          <div className="grid grid-cols-1 gap-10 md:grid-cols-3">
            <div>
              <h2 className="dark:text-dark-tremor-content-strong font-semibold text-tremor-content-strong">
                Salary information
              </h2>
              <p className="dark:text-dark-tremor-content mt-1 text-tremor-default leading-6 text-tremor-content">
                Salary information about the vacancy.
              </p>
            </div>
            <div className="sm:max-w-3xl md:col-span-2">
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-6">
                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="minimum-salary"
                    className="dark:text-dark-tremor-content-strong text-tremor-default font-medium text-tremor-content-strong"
                  >
                    Minimum salary
                  </label>
                  <TextInput
                    type="text"
                    id="minimum-salary"
                    name="minimumSalary"
                    placeholder="5000"
                    className="mt-2"
                    value={formik.values.minimumSalary}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.minimumSalary &&
                  formik.errors.minimumSalary ? (
                    <Notification
                      title="Unable to submit Vacancy"
                      message={formik.errors.minimumSalary}
                      open={true}
                      isError={true}
                    />
                  ) : null}
                  <label
                    htmlFor="maximum-salary"
                    className="dark:text-dark-tremor-content-strong text-tremor-default font-medium text-tremor-content-strong"
                  >
                    Maximum salary
                  </label>
                  <TextInput
                    type="text"
                    id="maximum-salary"
                    name="maximumSalary"
                    placeholder="10000"
                    className="mt-2"
                    value={formik.values.maximumSalary}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.maximumSalary &&
                  formik.errors.maximumSalary ? (
                    <Notification
                      title="Unable to submit Vacancy"
                      message={formik.errors.maximumSalary}
                      open={true}
                      isError={true}
                    />
                  ) : null}
                </div>
                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="visibility"
                    className="dark:text-dark-tremor-content-strong text-tremor-default font-medium text-tremor-content-strong"
                  >
                    Visibility
                  </label>
                  <Select
                    id="visibility"
                    name="visibility"
                    className="mt-2"
                    enableClear={false}
                    value={formik.values.visibility}
                    onChange={(value) => handleSelect('visibility', value)}
                    onBlur={formik.handleBlur}
                  >
                    <SelectItem value="Public">Public</SelectItem>
                    <SelectItem value="Private">Private</SelectItem>
                  </Select>
                  {formik.touched.visibility && formik.errors.visibility ? (
                    <Notification
                      title="Unable to submit Vacancy"
                      message={formik.errors.visibility}
                      open={true}
                      isError={true}
                    />
                  ) : null}
                </div>
                <div className="col-span-full">
                  <label
                    htmlFor="salary-description"
                    className="dark:text-dark-tremor-content-strong text-tremor-default font-medium text-tremor-content-strong"
                  >
                    Salary description
                  </label>
                  <Textarea
                    id="salary-description"
                    name="salaryDescription"
                    className="mt-2"
                    rows={4}
                    value={formik.values.salaryDescription}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <p className="dark:text-dark-tremor-content mt-2 text-tremor-label text-tremor-content">
                    Note: description provided will not be displayed externally.
                  </p>
                  {formik.touched.salaryDescription &&
                  formik.errors.salaryDescription ? (
                    <Notification
                      title="Unable to submit Vacancy"
                      message={formik.errors.salaryDescription}
                      open={true}
                      isError={true}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <Divider className="my-14" />
          <div className="flex items-center justify-end space-x-4">
            <button
              type="button"
              className="dark:text-dark-tremor-content-strong whitespace-nowrap rounded-tremor-small px-4 py-2.5 text-tremor-default font-medium text-tremor-content-strong"
            >
              Go back
            </button>
            <button
              type="submit"
              className="dark:bg-dark-tremor-brand dark:text-dark-tremor-brand-inverted dark:shadow-dark-tremor-input dark:hover:bg-dark-tremor-brand-emphasis whitespace-nowrap rounded-tremor-default bg-red-500 px-4 py-2.5 text-tremor-default font-medium text-tremor-brand-inverted shadow-tremor-input hover:bg-tremor-brand-emphasis"
            >
              Save settings
            </button>
          </div>
        </form>
      </Card>
    </>
  );
};

export default CreateNewVacancy;
