import axios from 'axios';

const API_HOST = process.env.REACT_APP_API_HOST;

async function createRota(schedule: any){
  const response = await axios.post(`${API_HOST}/v1/rota/schedule`, schedule);
  return response;
}

async function getRotaSchedule(date: string | Date){
  const { data } = await axios.get(`${API_HOST}/v1/rota/schedule/${date}`);
  return data;
}

export default {
  createRota,
  getRotaSchedule
}