import { TextInput } from '@tremor/react';
import { RiSearchLine } from '@remixicon/react';
import { Icon } from '@tremor/react';
import {
  EllipsisHorizontalIcon,
  EyeIcon,
  PencilSquareIcon,
  PlusIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { Checkbox } from '../../../ui/component/checkbox';
import { Menu, Popover, Position } from 'evergreen-ui';
import { Pagination } from '../../../ui/component/pagination';
import { TrashIcon } from '@heroicons/react/24/solid';

export function DepartmentsTable({
  departments,
  setIsOpenAddNewDepartment,
  selectedDepartments,
  handleSelectAll,
  handleSelectRow,
  handleDepartmentDetails,
  handleEditDepartment,
  handleDeleteDepartment,
  page,
  totalItems,
  setDirectPage,
  limit
}: any) {
  const isAllSelected =
    departments.length > 0 && selectedDepartments.length === departments.length;

  return (
    <div className="mt-5 rounded-xl border-[1px] border-gray-100 px-4 pb-1 pt-4 shadow">
      <div className="mb-2 flex items-center justify-between">
        <h6 className="font-semibold">Departments</h6>
        <div className="flex flex-row gap-4">
          <TextInput
            // value={searchDocument}
            // onChange={(event) => setSearchDocument(event.target.value)}
            icon={RiSearchLine}
            placeholder="Search by department name or location"
            className="w-auto lg:w-[350px]"
          />
          <button
            onClick={() => setIsOpenAddNewDepartment(true)}
            className="flex items-center gap-1 rounded-lg bg-[#DC2626] px-5 py-1 text-white"
          >
            <Icon icon={PlusIcon} size="sm" style={{ color: '#FFFFFF' }} />
            Add department
          </button>
        </div>
      </div>
      <div className="overflow-hidden">
        <div className="mt-1 flow-root w-full">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <table className="w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className=" flex items-center gap-3 py-3.5 pl-4 text-left text-sm font-medium text-gray-500 sm:pl-0"
                    >
                      <Checkbox
                        className="rounded-md border-[1px]"
                        color="rose"
                        checked={isAllSelected}
                        onChange={handleSelectAll}
                      />
                      Department name
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-3.5 text-left text-sm font-semibold text-gray-500"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="self-center px-2 py-3.5 text-left text-sm font-semibold text-gray-500"
                    >
                      Manager
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-3.5 text-left text-sm font-semibold text-gray-500"
                    >
                      Office Location
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="sr-only">View</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 ">
                  {departments?.map((department: any) => (
                    <tr key={department.id}>
                      <td className="whitespace-nowrap py-2 pl-4 text-sm font-medium text-gray-900 sm:pl-0">
                        <div className="flex items-center gap-2">
                          <Checkbox
                            className="rounded-md border-[1px]"
                            color="rose"
                            checked={selectedDepartments.includes(
                              department.id,
                            )}
                            onChange={(checked) =>
                              handleSelectRow(department.id, checked)
                            }
                          />
                          {department.name}
                        </div>
                      </td>
                      <td className=" max-w-[450px] px-2 py-2 text-sm text-gray-500">
                        {department.description}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm ">
                        <div className="flex flex-row items-center gap-1">
                          <UserIcon className="w-5" />
                          {department.manager.first_name}{' '}
                          {department.manager.last_name}
                        </div>
                      </td>
                      <td className=" px-2 py-2 text-sm ">
                        {department.location.name}
                      </td>
                      <td className="whitespace-nowrap py-2 text-sm font-medium sm:pr-0">
                        <Popover
                          position={Position.BOTTOM_RIGHT}
                          content={
                            <Menu>
                              <Menu.Group>
                                <Menu.Item>
                                  <div
                                    onClick={() =>
                                      handleEditDepartment(department)
                                    }
                                    className="flex flex-row flex-nowrap gap-2 text-gray-600"
                                  >
                                    <PencilSquareIcon
                                      className="w-4"
                                      color="gray"
                                    />
                                    Edit
                                  </div>
                                </Menu.Item>
                                <Menu.Item>
                                  <div
                                    onClick={() =>
                                      handleDepartmentDetails(department)
                                    }
                                    className="flex flex-row flex-nowrap gap-2 text-gray-600"
                                  >
                                    <EyeIcon className="w-4" color="gray" />
                                    Details
                                  </div>
                                </Menu.Item>
                                <Menu.Item>
                                  <div
                                    onClick={() =>
                                      handleDeleteDepartment(department)
                                    }
                                    className="flex flex-row flex-nowrap gap-2 text-[#DC2626] "
                                  >
                                    <TrashIcon className="w-4" color="red" />
                                    Delete
                                  </div>
                                </Menu.Item>
                              </Menu.Group>
                            </Menu>
                          }
                        >
                          <EllipsisHorizontalIcon className="h-5 w-5 cursor-pointer" />
                        </Popover>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                limit={limit}
                count={totalItems}
                page={page}
                onPageChange={setDirectPage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
