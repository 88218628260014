import { useEffect, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import timeaway from '../../../network/timeaway';

export default () => {
  const [allocatedTimeaway, setAllocatedTimeaway] = useState<any>({});

  useEffect(() => {
    timeaway
      .getTimeAway()
      .then((response) => {
        setAllocatedTimeaway(response?.data);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <Formik
      initialValues={{
        toggle: false,
      }}
      onSubmit={async (values) => {
        await timeaway.bookRecurringTimeAway({
          workingDays: [],
          period: {
            value: 1,
            type: 'weeks',
            start: new Date(),
          },
        });
      }}
    >
      <Form>
        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Book recurring time away
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Book time away on a recurring basis
                </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form action="#" method="POST">
                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span 12 sm:col-span-12">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Weekly remote working allowance
                        </label>
                        <h3 className="mt-2">
                          {
                            allocatedTimeaway?.weekly_working_remotely?.allowed
                              ?.value
                          }{' '}
                          days
                        </h3>
                      </div>

                      <div className="col-span-12 sm:col-span-12">
                        <label
                          htmlFor="last-name"
                          className="mb-4 block text-sm font-medium text-gray-700"
                        >
                          Select your recurring remote working days
                        </label>
                        <fieldset>
                          <legend className="sr-only">Notifications</legend>
                          <div className="space-y-5">
                            <div className="relative flex items-start">
                              <div className="flex h-6 items-center">
                                <Field
                                  id="monday"
                                  name="workingdays.monday"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                              </div>
                              <div className="ml-3 text-sm leading-6">
                                <label
                                  htmlFor="monday"
                                  className="font-medium text-gray-900"
                                >
                                  Monday
                                </label>
                              </div>
                            </div>
                            <div className="relative flex items-start">
                              <div className="flex h-6 items-center">
                                <Field
                                  id="tuesday"
                                  name="workingdays.tuesday"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                              </div>
                              <div className="ml-3 text-sm leading-6">
                                <label
                                  htmlFor="tuesday"
                                  className="font-medium text-gray-900"
                                >
                                  Tuesday
                                </label>
                              </div>
                            </div>
                            <div className="relative flex items-start">
                              <div className="flex h-6 items-center">
                                <Field
                                  id="wednesday"
                                  name="workingdays.wednesday"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                              </div>
                              <div className="ml-3 text-sm leading-6">
                                <label
                                  htmlFor="wednesday"
                                  className="font-medium text-gray-900"
                                >
                                  Wednesday
                                </label>
                              </div>
                            </div>
                            <div className="relative flex items-start">
                              <div className="flex h-6 items-center">
                                <Field
                                  id="thursday"
                                  name="workingdays.thursday"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                              </div>
                              <div className="ml-3 text-sm leading-6">
                                <label
                                  htmlFor="wednesday"
                                  className="font-medium text-gray-900"
                                >
                                  Thursday
                                </label>
                              </div>
                            </div>
                            <div className="relative flex items-start">
                              <div className="flex h-6 items-center">
                                <Field
                                  id="friday"
                                  name="workingdays.friday"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                              </div>
                              <div className="ml-3 text-sm leading-6">
                                <label
                                  htmlFor="wednesday"
                                  className="font-medium text-gray-900"
                                >
                                  Friday
                                </label>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Period
                        </label>
                        <div className="grid grid-cols-12 gap-12">
                          <div className="col-span-3 sm:col-span-3">
                            <input
                              type="number"
                              id="period"
                              name="period"
                              value={1}
                              className="mt-1 block w-full rounded-md border-gray-300"
                            ></input>
                          </div>
                          <div className="col-span-3 sm:col-span-3">
                            <select
                              id="country"
                              name="country"
                              autoComplete="country-name"
                              className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                            >
                              <option>weeks</option>
                              <option>months</option>
                            </select>
                          </div>

                          <div className="col-span-6 sm:col-span-6">
                            <input
                              type="date"
                              id="period"
                              name="period"
                              value={1}
                              className="mt-1 block w-full rounded-md border-gray-300"
                            ></input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    >
                      Send Request
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
