import { useEffect, useState } from 'react';
import { Calendar } from './components/Calendar';
import CreateNewRotaModal from './components/CreateNewRotaModal';
import moment, { Moment } from 'moment';
import { useGetRotas } from './hooks';
import { CALENDAR_MODE__MONTH, CALENDAR_MODE__WEEK } from '../../constants';
import { Spinner } from '../../ui/Spinner';

export function RotaManagementRoute() {
  const [isOpenNewRotaModal, setIsOpenNewRotaModal] = useState(false);
  const [today, setToday] = useState(moment());
  const [mode, setMode] = useState(CALENDAR_MODE__MONTH);

  const startDay =
    mode === CALENDAR_MODE__MONTH
      ? today.clone().startOf('month').startOf('week')
      : today.clone().startOf('week');

  const daysCount = mode === CALENDAR_MODE__MONTH ? 42 : 7;

  const daysMap: Moment[] = [...Array(daysCount)].map((_, index) =>
    startDay.clone().add(index, 'day'),
  );

  const { data: rotasResponse = [], refetch: refetchRotas, isLoading } =
    useGetRotas(daysMap);
  const rotas = rotasResponse.flatMap((response) => response?.data || []);

  function handlePrevious() {
    setToday((prev) =>
      prev
        .clone()
        .subtract(1, mode === CALENDAR_MODE__MONTH ? 'month' : 'week'),
    );
  }

  function handleToday() {
    setToday(moment());
  }

  function handleNext() {
    setToday((prev) =>
      prev.clone().add(1, mode === CALENDAR_MODE__MONTH ? 'month' : 'week'),
    );
  }

  useEffect(() => {
    refetchRotas();
  }, [today, mode, refetchRotas]);

  if(isLoading){
    return (
      <div className='h-screen flex flex-col gap-3 justify-center items-center'>
        <Spinner size={10}/>
        <p className='font-semibold'>
          Loading...
        </p>
      </div>
    )
  }

  return (
    <>
      <div className="flex w-full flex-col">
        <div className="flex flex-row items-center justify-between border-b border-gray-200 pb-3">
          <h1 className="text-xl font-semibold text-gray-900">Calendar</h1>
        </div>
        <div className="mt-4 flex justify-end">
          <button className="rounded-lg bg-[#DC2626] px-3 py-1 text-white">
            Publish shifts
          </button>
        </div>
        <div className="mt-4">
          <Calendar
            today={today}
            daysMap={daysMap}
            rotas={rotas}
            setIsOpenNewRotaModal={setIsOpenNewRotaModal}
            handlePrevious={handlePrevious}
            handleToday={handleToday}
            handleNext={handleNext}
            setMode={setMode}
            mode={mode}
          />
        </div>
      </div>
      <CreateNewRotaModal
        open={isOpenNewRotaModal}
        setOpen={setIsOpenNewRotaModal}
        refetchRotas={refetchRotas}
      />
    </>
  );
}
