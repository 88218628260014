import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export function CustomTextArea({ message, setMessage }: any) {
  const modules = {
    toolbar: [
      ['underline', 'strike', 'bold', 'italic', { color: [] }],
      [{ align: [] }, { list: 'ordered' }, { list: 'bullet' }],
      ['link'],
    ],
  };

  return (
    <div>
      <label className="mb-2 block text-base font-medium">Message</label>
      <ReactQuill
        value={message}
        onChange={setMessage}
        modules={modules}
        placeholder="Write your message here..."
        className="rounded-lg bg-white text-gray-800"
      />
    </div>
  );
}
