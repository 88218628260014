export function FooterSection({
  nextHandle,
  handleSkipForNow,
}: {
  nextHandle: (value: any) => void;
  handleSkipForNow: () => void;
}) {
  return (
    <div className="mb-4 mt-5 flex flex-row justify-end gap-3 border-t border-tremor-border py-3">
      <button
        onClick={handleSkipForNow}
        className="rounded-lg border px-3 py-1 font-normal shadow"
      >
        Skip for Now
      </button>
      <button
        type="submit"
        onClick={nextHandle}
        className="rounded-lg bg-[#DC2626] px-3 py-1 font-normal text-white shadow"
      >
        Save and continue
      </button>
    </div>
  );
}
