import { Transition } from '@headlessui/react';
import {
  ArrowUpIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { UserIcon } from '@heroicons/react/24/solid';
import { Fragment, useState } from 'react';
import ChatbotHistory from './ChatbotHistory';

export default function ChatbotModal({ isOpen, setOpen }: any) {
  const [isOpenChatbotHistory, setIsOpenChatbotHistory] = useState(false);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <div className="fixed bottom-8 right-8 z-50">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-x-4"
          enterTo="opacity-100 translate-x-0"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-4"
        >
          <div className="flex max-h-[550px] min-h-[500px] min-w-[500px] max-w-[550px] flex-col justify-between  overflow-y-auto overflow-x-hidden rounded-xl border bg-white shadow-lg">
            <div className="flex items-center justify-between px-4 py-3">
              <button
                onClick={() => setIsOpenChatbotHistory((prev) => !prev)}
                className="rounded-lg border p-2 shadow"
              >
                {isOpenChatbotHistory ? (
                  <ChevronDoubleLeftIcon className="w-4" />
                ) : (
                  <ChevronDoubleRightIcon className="w-4" />
                )}
              </button>
              <button
                onClick={() => setOpen(false)}
                className="text-gray-600 hover:text-gray-900"
              >
                <XMarkIcon className="w-4" />
              </button>
            </div>
            <ChatbotHistory
              isOpen={isOpenChatbotHistory}
              setOpen={setIsOpenChatbotHistory}
            />
            <div className='overflow-y-auto'>
              
            </div>
            <div className="flex justify-center px-4 py-2">
              <div className="flex w-full flex-row items-center gap-2 rounded-xl border border-[#E4E4E7] bg-white px-4 py-1 shadow-sm">
                <textarea
                  className="flex-1 resize-none border-0 bg-transparent placeholder-gray-400 focus:outline-none focus:ring-0"
                  wrap="soft"
                  placeholder="Message"
                  rows={1}
                />
                <button className="rounded-lg border border-[#E4E4E7] p-2 shadow">
                  <ArrowUpIcon className="w-4" />
                </button>
              </div>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition>
  );
}
