import { ArrowLeftIcon } from '@heroicons/react/24/solid';

export function WelcomeSection({ handlePrev }: { handlePrev: () => void }) {
  return (
    <div className="flex border-b-2 border-tremor-border pb-3">
      <button onClick={handlePrev} className="flex items-center">
        <ArrowLeftIcon className="ml-2 mt-3 w-5" />
      </button>
      <div className="flex w-screen justify-center">
        <h1 className="text-2xl font-semibold">Welcome to Redstone HR</h1>
      </div>
    </div>
  );
}
